import { IconLocal } from '@/Dashboard';
import CommuneMenuOverviewVue from '@/communes/components/CommuneMenuOverview.vue';
import MenuLabelRender from '@/core/components/MenuLabelRender.vue';
import { MenuGroupOption, MenuMixedOption, MenuOption } from 'naive-ui/lib/menu/src/interface';
import { h, VNodeChild } from 'vue';
import { RouterLink } from 'vue-router';

export interface MenuItem {
  label: (option: MenuOption | MenuGroupOption) => VNodeChild;
  key: string;
  component?: string;
  icon?: () => VNodeChild;
  disabled?: boolean;
  children?: [
    {
      type: string;
      label: string;
      key: string;
      children?: [MenuItem];
    },
  ];
  cols: number;
  offset?: number;
  height: string;
}

// Todo: Message should be Report

const PublicMenuEntries: MenuMixedOption[] = [
  {
    label: () => h(MenuLabelRender, { label: 'report' }),
    key: 'ReportsComponent',
    component: 'ReportsComponent',
    disabled: false,
    icon: () => h(IconLocal, { type: 'Bell', size: 24 }),
    cols: 2,
    height: '700px',
  },
  {
    label: () => h(MenuLabelRender, { label: 'newReport' }),
    key: 'NewReportComponentPublic',
    component: 'NewReportComponentPublic',
    disabled: false,
    icon: () => h(IconLocal, { type: 'BellNotification', size: 24 }),
    cols: 2,
    height: '500px',
  },
  {
    label: () => h(CommuneMenuOverviewVue),
    key: 'CommuneOverview',
    component: '',
    disabled: true,
    icon: () => null,
    cols: 2,
    height: '0px',
  },
  {
    label: () => h(MenuLabelRender, { label: 'contact' }),
    key: 'ContactComponent',
    component: 'ContactComponent',
    disabled: false,
    icon: () => h(IconLocal, { type: 'ContactFlyIcon', size: 24 }),
    cols: 2,
    offset: 2,
    height: '425px',
  },
];

export const ShareMenuEntries: MenuMixedOption[] = [
  {
    label: () => h(MenuLabelRender, { label: 'report' }),
    key: 'ReportsComponent',
    component: 'ReportsComponent',
    disabled: false,
    icon: () => h(IconLocal, { type: 'Bell', size: 24 }),
    cols: 2,
    height: '700px',
  },
  {
    label: () => h(MenuLabelRender, { label: 'newReport' }),
    key: 'ShareCommuneDetailsMapOverlay',
    component: 'ShareCommuneDetailsMapOverlay',
    disabled: false,
    icon: () => h(IconLocal, { type: 'BellNotification', size: 24 }),
    cols: 3,
    height: 'auto',
  },
  {
    label: () => h(MenuLabelRender, { label: 'contact' }),
    key: 'ContactComponent',
    component: 'ShareCommuneDetailsMapOverlay',
    disabled: false,
    icon: () => h(IconLocal, { type: 'ContactFlyIcon', size: 24 }),
    cols: 4,
    offset: 0,
    height: 'auto',
  },
];

const LeftMenu: MenuMixedOption[] = [
  {
    label: () => h(MenuLabelRender, { label: 'report' }),
    key: 'ReportsComponent',
    component: 'ReportsComponent',
    disabled: false,
    icon: () => h(IconLocal, { type: 'Bell', size: 24 }),
    cols: 2,
    height: '700px',
  },
  {
    label: () => h(MenuLabelRender, { label: 'ownReport' }),
    key: 'MyReportsComponent',
    component: 'ReportsComponent',
    icon: () => h(IconLocal, { type: 'BellMyReports', size: 24 }),
    disabled: false,
    cols: 2,
    height: '700px',
  },
  {
    label: () => h(MenuLabelRender, { label: 'newReport' }),
    key: 'NewMessagesComponent',
    component: 'CreateOrEditReportMapper',
    disabled: false,
    icon: () => h(IconLocal, { type: 'BellNotification', size: 24 }),
    cols: 2,
    height: '700px',
  },
  {
    label: () => h(CommuneMenuOverviewVue),
    key: 'CommuneOverview',
    component: '',
    disabled: true,
    icon: () => null,
    cols: 2,
    height: '0px',
  },
];
const coordinatorMenu: MenuItem[] = [
  {
    label: () =>
      h(
        RouterLink,
        { to: '/coordinator', class: 'rd-text-white rd-text-center' },
        { default: () => h(MenuLabelRender, { label: 'dashboard' }) },
      ),
    key: 'dashboard',
    component: '',
    disabled: false,
    icon: () => h(IconLocal, { type: 'dashboard', class: 'rd-text-[19px] lg:rd-text-[24px]' }),
    cols: 1,
    offset: 3,
    height: '425px',
  },
];

const RightMenu: MenuItem[] = [
  {
    label: () => h(MenuLabelRender, { label: 'contact' }),
    key: 'ContactComponent',
    component: 'ContactComponent',
    disabled: false,
    icon: () => h(IconLocal, { type: 'ContactFlyIcon', size: 24 }),
    cols: 2,
    offset: 2,
    height: '425px',
  },
];

const MobileReporterMenu: MenuItem[] = [
  {
    label: () => h(MenuLabelRender, { label: 'mobile.profile' }),
    key: 'MobileProfile',
    component: 'MobileProfile',
    disabled: false,
    icon: () => h(IconLocal, { type: 'SingleUserIcon', size: 24 }),
    cols: 4,
    offset: 0,
    height: '425px',
  },
];

export const MobileMenu: MenuMixedOption[] = [
  ...(LeftMenu as unknown as MenuOption[]),
  ...(MobileReporterMenu as unknown as MenuOption[]),
  ...(RightMenu as unknown as MenuOption[]),
];

let FullMenu: MenuMixedOption[] = [
  ...(LeftMenu as unknown as MenuOption[]),
  // ...(AdminMenu as unknown as MenuOption[]),
  ...(RightMenu as unknown as MenuOption[]),
];

export const useReporterMenu = () => {
  FullMenu = [...(LeftMenu as unknown as MenuOption[]), ...(RightMenu as unknown as MenuOption[])];
};
export const useCoordinatorMenu = () => {
  FullMenu = [
    ...(LeftMenu as unknown as MenuOption[]),
    ...(coordinatorMenu as unknown as MenuOption[]),
    ...(RightMenu as unknown as MenuOption[]),
  ];
};

export { LeftMenu, RightMenu, PublicMenuEntries, FullMenu };
export default FullMenu;
