<script lang="ts" setup>
/**
 *
 * Fist time login overlay for users that where created by the admin.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { computed, ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const { coordinatorUserModalTermAndConditions, userDetails } = storeToRefs(userStore);

const userTermAndConditions = ref({
  acceptLegalTermsAndConditions: false,
  acceptTermsAndConditions: false,
  acceptConfidential: false,
  acceptResponsibility: false,
});

const HandleUpdateCoordinatorUserTermAndConditions = () => {
  const userProfileData = userDetails.value.UserProfileUpdate();
  userProfileData.termAndConditions = true;
  userStore.ACTION_UPDATE_PROFILE(userProfileData);
};
const disabled = computed((): boolean => {
  return (
    !userTermAndConditions.value.acceptLegalTermsAndConditions ||
    !userTermAndConditions.value.acceptTermsAndConditions ||
    !userTermAndConditions.value.acceptConfidential ||
    !userTermAndConditions.value.acceptResponsibility
  );
});

const commune = computed((): string => {
  return userDetails.value.getCoordinatedCommuneName;
});
</script>

<template>
  <n-modal :show="coordinatorUserModalTermAndConditions" :block-scroll="true" :z-index="2003">
    <div
      class="rd-absolute rd-inset-0 rd-bg-base-970 rd-text-base-100 rd-text-center rd-z-[1000] rd-bg-opacity-60 rd-flex rd-items-center rd-justify-center rd-flex-col"
    >
      <h2 class="rd-text-3.5xl rd-mb-5 rd-font-bold">{{ $t('coordinator.fistTimeLogin.title') }}</h2>
      <p class="rd-text-small-xs">{{ $t('coordinator.fistTimeLogin.subtitle') }}</p>
      <div class="rd-flex rd-flex-col rd-mt-10 rd-text-small-xs rd-max-w-3xl rd-text-left">
        <n-checkbox v-model:checked="userTermAndConditions.acceptLegalTermsAndConditions" required class="checkbox">
          <span v-html="$t('coordinator.fistTimeLogin.form.checkbox1')"></span>
        </n-checkbox>
        <n-checkbox v-model:checked="userTermAndConditions.acceptConfidential" required class="checkbox">
          <span v-html="$t('coordinator.fistTimeLogin.form.checkbox3')"></span>
        </n-checkbox>
        <n-checkbox v-model:checked="userTermAndConditions.acceptResponsibility" required class="checkbox">
          <span v-html="$t('coordinator.fistTimeLogin.form.checkbox4')"></span>
        </n-checkbox>
        <n-checkbox v-model:checked="userTermAndConditions.acceptTermsAndConditions" required class="checkbox">
          <span v-html="$t('commune.form.create.confirmation.context', { commune: commune })"></span>
        </n-checkbox>
      </div>
      <div class="rd-mt-10">
        <n-button type="success" :disabled="disabled" @click="HandleUpdateCoordinatorUserTermAndConditions">
          <span class="rd-px-8">
            {{ $t('global.actions.save') }}
          </span>
        </n-button>
      </div>
    </div>
  </n-modal>
</template>
<style scoped>
.checkbox {
  @apply rd-flex-row rd-mb-[1px] rd-rounded-none rd-px-0;
}
</style>
