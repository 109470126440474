<script lang="ts" setup>
/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useLoadingBar } from 'naive-ui';
import MaintenanceModeView from '@/views/MaintenanceModeView.vue';
import SuspenseWrapper from '@/core/components/SuspenseWrapper.vue';
import { useGlobalStore } from '@/stores/GlobalStore';

const globalStore = useGlobalStore();

const { systemSettings } = storeToRefs(globalStore);

const loadingBar = useLoadingBar();
const route = useRoute();

router.beforeEach(() => loadingBar?.start());
router.afterEach(() => loadingBar?.finish());

const layoutName = ref<string>('DefaultLayout');
if (route.meta.layout) {
  layoutName.value = route.meta.layout as string;
} else {
  layoutName.value = 'DefaultLayout';
}
watch(
  route,
  () => {
    if (route.meta.layout) {
      layoutName.value = route.meta.layout as string;
    }
  },
  {
    immediate: true,
  },
);
globalStore.ACTION_GET_MAINTENANCE_MODE();
</script>
<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'TheViewer',
});
</script>

<template>
  <SuspenseWrapper>
    <MaintenanceModeView v-if="systemSettings.maintenanceMode" />
    <component :is="layoutName" v-else>
      <router-view />
    </component>
  </SuspenseWrapper>
</template>
<style scoped></style>
