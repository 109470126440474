import { useOidcStore } from 'vue3-oidc';
import axios, { AxiosResponse } from 'axios';
import { KEYCLOAK_ENV } from '@/config/KeycloakConfiguration';
import { AuthTokenInterface } from '@/stores/UserStore';
export interface AuthUserDetail {
  id: string;
  username: string;
  email: string;
}
export abstract class AuthUserService {
  private static ROAD_REPORT_INSTANCE = axios.create({
    baseURL: `${import.meta.env.VITE_ROAD_AUTH}`,
  });

  /*----------  Export Handlers  ----------*/
  /**
   *
   * @param { CommuneUuid  } 'commune uuid '
   * @returns Users list
   */

  static async FetchUserInformation(): Promise<AxiosResponse<AuthUserDetail>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/v1/user/profile`);
  }
  static async manualRefreshToken({
    refreshToken,
  }: {
    refreshToken: string;
  }): Promise<AxiosResponse<AuthUserDetail & { access_token: string; refresh_token: string }>> {
    return await this.ROAD_REPORT_INSTANCE.post(
      `/oauth/access_token`,
      {
        grant_type: 'refresh_token',
        client_id: KEYCLOAK_ENV.clientId,
        client_secret: KEYCLOAK_ENV.clientSecret,
        refresh_token: refreshToken,
      },
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
    );
  }

  static async getUserProfile({ token }: { token: string }): Promise<AxiosResponse<AuthTokenInterface>> {
    return await this.ROAD_REPORT_INSTANCE.get(`/v1/user/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private static tokenInterceptor() {
    const { state } = useOidcStore();
    // console.log(state.value.user?.access_token);

    this.ROAD_REPORT_INSTANCE.interceptors.request.use(
      (config) => {
        if (state.value.token) {
          config.headers.Authorization = `Bearer ${state.value.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}
