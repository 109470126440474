<script lang="ts" setup>
/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { NIcon } from 'naive-ui';
import {
  CaretDown as DownIconCollapse,
  Eye,
  EyeOff,
  RefreshOutline as refresh,
  CreateOutline as posts,
  NewspaperOutline as pages,
  ImagesOutline as media,
  PeopleOutline as users,
  ChatbubbleEllipsesOutline as comments,
  ColorPaletteOutline as themes,
  HardwareChipOutline as plugins,
  BuildOutline as tools,
  SettingsOutline as settings,
  FlaskOutline as flask,
  AddOutline as add,
  HelpCircleOutline as help,
  NotificationsOutline as notifications,
  LogoGithub as github,
  MapOutline as MapIcon,
  InformationCircleOutline as Info,
  OpenOutline as OpenIcon,
  LogoFacebook as Facebook,
  LogoTwitter as Twitter,
  LogoInstagram as Instagram,
  LogoYoutube as Youtube,
  LogoTumblr as Tumblr,
  ImageOutline as Image,
  CalendarClearOutline as CalendarIcon,
  SunnyOutline as SunIcon,
  PhonePortraitOutline as PhoneIcon,
  DesktopOutline as DesktopIcon,
  EyeOutline as OpenEyeIcon,
  EyeOffOutline as CloseEyeIcon,
  BanSharp as NotVerifiedIcon,
  CheckmarkCircleOutline as VerifiedIcon,
  Reload as ReloadIcon,
} from '@vicons/ionicons5';

import {
  IosArrowDown as DownIcon,
  IosArrowRoundBack as LongArrowLeft,
  IosArrowRoundForward as LongArrowRight,
  MdArrowDropdown as DropDownIcon,
  IosGlobe as WordIcon,
  IosArrowBack as ArrowBack,
  IosArrowForward as ArrowForward,
  IosCheckboxOutline as SwitchIconActive,
} from '@vicons/ionicons4';
import {
  Edit,
  Cyclist as ReportsIcon,
  Folders as AllIcon,
  UserProfileAlt as MyReportsIcon,
  Download,
  User as UserIcon,
  StatusPartialFail as ErrorIcon,
  SettingsAdjust,
  Settings as GearIcon,
  User as SingleUserIcon,
} from '@vicons/carbon';

import {
  MarkerIconNew,
  MarkerIconNeutral,
  MarkerIconSuccess,
  MarkerIconWarning,
  MarkerIconUp,
  MapIcon as MapCustomIcon,
  BellIcon,
  XCloseIcon,
  EditIcon,
  CirclePlus,
  BellStartIcon,
  MailOpen,
  licenseIcon,
  LikeIcon,
  CommentIcon,
  MoonIconTheme,
  MarkerOutline,
  UnMerge,
  UnMergeSimple,
  SwitchIcon,
  AttachmentIcons,
  EditReportIcon,
  StartIcon,
  CopyIcon,
  AllMarkerIcon,
  FilterIcon,
  FallBack,
  SortIcon,
  DeleteIcon,
  EmailIcon,
  DownIconLines,
  InfoFill,
  FlyIcon,
  BellUserIcon,
} from '@/assets/icons/Icons';
import {
  DashboardIcon,
  DashboardReportsMain,
  DashboardReportsMine,
  DashboardReportsConfiguration,
  DashboardReportsUsers,
  DashboardReportsCommunes,
  DashboardReportsExport,
  DashboardReportsLicense,
  DoneIcon,
} from '@/assets/icons';

const icons = {
  refresh,
  posts,
  pages,
  media,
  users,
  comments,
  themes,
  plugins,
  tools,
  settings,
  flask,
  add,
  help,
  notifications,
  github,
  // fallback,
  Edit,
  MapIcon,
  Info,
  Eye,
  EyeOff,
  SunIcon,
  MarkerAll: h('i', { innerHTML: MapCustomIcon() }, {}),
  MarkerNew: h('i', { innerHTML: MarkerIconNew() }, {}),
  MarkerProgress: h('i', { innerHTML: MarkerIconWarning() }, {}),
  MarkerDone: h('i', { innerHTML: MarkerIconSuccess() }, {}),
  MarkerPositive: h('i', { innerHTML: MarkerIconUp() }, {}),
  MarkerNeutral: h('i', { innerHTML: MarkerIconNeutral() }, {}),
  CloseIcon: h('i', { innerHTML: XCloseIcon }, {}),
  EditIcon: h('i', { innerHTML: EditIcon }, {}),
  CirclePlus: h('i', { innerHTML: CirclePlus }, {}),
  Bell: h('i', { innerHTML: BellIcon() }, {}),
  BellNotification: h('i', { innerHTML: BellStartIcon() }, {}),
  BellNew: h('i', { innerHTML: BellIcon() }, {}),
  BellMyReports: h('i', { innerHTML: BellUserIcon() }, {}),
  ContactFlyIcon: h('i', { innerHTML: FlyIcon() }, {}),
  MailIconOpen: h('i', { innerHTML: MailOpen }, {}),
  LicenseIcon: h('i', { innerHTML: licenseIcon }, {}),
  Like: h('i', { innerHTML: LikeIcon }, {}),
  Comment: h('i', { innerHTML: CommentIcon }, {}),
  MoonIcon: h('i', { innerHTML: MoonIconTheme }, {}),
  MarkerOutline: h('i', { innerHTML: MarkerOutline }, {}),
  UnMerge: h('i', { innerHTML: UnMerge }, {}),
  UnMergeSimple: h('i', { innerHTML: UnMergeSimple }, {}),
  SwitchIcon: h('i', { innerHTML: SwitchIcon }, {}),
  AttachmentIcons: h('i', { innerHTML: AttachmentIcons }, {}),
  OpenIcon,
  ReportsIcon,
  AllIcon,
  MyReportsIcon,
  DownIcon,
  Download,
  LongArrowLeft,
  LongArrowRight,
  DropDownIcon,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Tumblr,
  UserIcon,
  WordIcon,
  Image,
  ArrowBack,
  ArrowForward,
  ErrorIcon,
  SettingsAdjust,
  CalendarIcon,
  DownIconCollapse,
  GearIcon,
  SwitchIconActive,
  SingleUserIcon,
  PhoneIcon,
  DesktopIcon,
  // Dashboard Icons
  dashboard: h('i', { innerHTML: DashboardIcon }, {}),
  RadarIconDashboard: h('i', { innerHTML: DashboardIcon }, {}),
  RadarIconReports: h('i', { innerHTML: DashboardReportsMain }, {}),
  RadarIconMineReports: h('i', { innerHTML: DashboardReportsMine }, {}),
  RadarIconConfiguration: h('i', { innerHTML: DashboardReportsConfiguration }, {}),
  RadarIconUser: h('i', { innerHTML: DashboardReportsUsers }, {}),
  RadarIconCommune: h('i', { innerHTML: DashboardReportsCommunes }, {}),
  RadarIconExport: h('i', { innerHTML: DashboardReportsExport }, {}),
  RadarIconLicense: h('i', { innerHTML: DashboardReportsLicense }, {}),
  EditReportIcon: h('i', { innerHTML: EditReportIcon }, {}),
  StartIcon: h('i', { innerHTML: StartIcon }, {}),
  CopyIcon: h('i', { innerHTML: CopyIcon }, {}),
  AllMarkerIcon: h('i', { innerHTML: AllMarkerIcon }, {}),
  FilterIcon: h('i', { innerHTML: FilterIcon }, {}),
  fallback: h('i', { innerHTML: FallBack }, {}),
  Sort: h('i', { innerHTML: SortIcon }, {}),
  DeleteIcon: h('i', { innerHTML: DeleteIcon }, {}),
  EmailIcon: h('i', { innerHTML: EmailIcon }, {}),
  DownIconLines: h('i', { innerHTML: DownIconLines }, {}),
  InfoFill: h('i', { innerHTML: InfoFill }, {}),
  DoneIcon: h('i', { innerHTML: DoneIcon }, {}),
  FlyIcon: h('i', { innerHTML: DoneIcon }, {}),
  OpenEyeIcon,
  CloseEyeIcon,
  NotVerifiedIcon,
  VerifiedIcon,
  ReloadIcon,
};

type IconTypes = keyof typeof icons;

const props = defineProps({
  ...NIcon.props,
  type: {
    type: String,
    default: 'fallback',
  },
});

const icon = icons[props.type as IconTypes];
</script>

<script lang="ts">
import { defineComponent, h } from 'vue';

export default defineComponent({
  name: 'IconLocal',
});
</script>
<template>
  <n-icon v-bind="props">
    <icon />
  </n-icon>
</template>
<style scoped></style>
