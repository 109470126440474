<script lang="ts" setup>
/**
 *
 * Component Description:User date picker
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { IconLocal } from '@/Dashboard';
import { FormKitFrameworkContext } from '@formkit/core';
import { watch, ref, onBeforeUnmount } from 'vue';

interface Props {
  context: FormKitFrameworkContext;
}

const props = defineProps<Props>();
const passwordInput = ref();
const defaultValue = ref(props.context._value);

watch(passwordInput, (newValue) => {
  props.context.node.input(newValue);
});

onBeforeUnmount(() => {
  passwordInput.value = '';
});
</script>

<template>
  <n-form-item
    class="rd-w-full phone_number"
    :show-feedback="props.context ? props.context.attrs.feedback : false"
    :show-label="false"
    :show-require-mark="false"
  >
    <n-input
      v-model:value="passwordInput"
      class="rd-w-full"
      :label-field="props.context.attrs.labelField"
      :value-field="props.context.attrs.valueField"
      :placeholder="props.context.attrs.placeHolder"
      :default-value="defaultValue"
      :disabled="props.context.attrs.selectDisabled"
      type="password"
      show-password-on="click"
      :status="props.context.state.valid ? 'success' : 'error'"
      :theme-overrides="{
        borderError: '1px solid transparent',
        border: '1px solid transparent',
        borderHover: '1px solid transparent',
      }"
    >
    </n-input>
  </n-form-item>
</template>
<style scoped></style>
