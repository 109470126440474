import { ModuleNamespace } from 'vite/types/hot';
import { App } from 'vue';

export function registerLayout(vueApp: App<Element>) {
  const components = import.meta.glob<ModuleNamespace>('./*.vue', { eager: true });

  Object.entries(components).forEach(([path, definition]) => {
    const componentName = path.replace(/^.+\/([^/]+)\.vue$/, '$1');
    // Register component on this Vue instance
    vueApp.component(componentName, definition.default);
  });
}
