import { REPORT_STATUS } from './../typings/api/ReportStatus';
import { readonly, ref, VNodeChild } from 'vue';
import { RenderIcon } from '@/core/composables/Helpers';
import {
  MarkerIconMine,
  MarkerIconNeutral,
  MarkerIconNew,
  MarkerIconSuccess,
  MarkerIconUp,
  MarkerIconWarning,
} from '@/assets/icons/Icons';

const iconSize = 24;
const currentMarkerComponentIcon = ref<{ icon: () => VNodeChild; label: string; class: string }>();

export const MarkerIconComponents: Record<REPORT_STATUS | 'MEINE', () => VNodeChild> = {
  NEW: RenderIcon(MarkerIconNew(), iconSize),
  IN_PROGRESS: RenderIcon(MarkerIconWarning(), iconSize),
  DONE: RenderIcon(MarkerIconSuccess(), iconSize),
  NEUTRAL: RenderIcon(MarkerIconNeutral(), iconSize),
  POSITIVE: RenderIcon(MarkerIconUp(), iconSize),
  MEINE: RenderIcon(MarkerIconMine(), iconSize),
};
export const ReportStatusSorting: Record<REPORT_STATUS, number> = {
  NEW: 1,
  IN_PROGRESS: 2,
  DONE: 3,
  NEUTRAL: 4,
  POSITIVE: 5,
};

export const MarkerIconLabels: Record<string, REPORT_STATUS | 'MEINE'> = {
  1: REPORT_STATUS.NEW,
  2: REPORT_STATUS.IN_PROGRESS,
  3: REPORT_STATUS.DONE,
  4: REPORT_STATUS.NEUTRAL,
  5: REPORT_STATUS.POSITIVE,
  6: 'MEINE',
};

export const MarkerStatusColor: Record<REPORT_STATUS | 'MEINE', string> = {
  NEW: 'danger',
  IN_PROGRESS: 'warning',
  DONE: 'success',
  NEUTRAL: 'secondary',
  POSITIVE: 'info',
  MEINE: 'dark',
};

const HelperIconsMenu: Array<{ label: string; icon: () => VNodeChild }> = Object.keys(MarkerIconLabels).map((item) => {
  return {
    label: MarkerIconLabels[item],
    icon: MarkerIconComponents[MarkerIconLabels[item]],
  };
});

export const SetCurrentMarkerIcon = (MarkerStatus: string | number, statusKey: REPORT_STATUS): void => {
  currentMarkerComponentIcon.value = {
    icon: MarkerIconComponents[statusKey],
    label: statusKey,
    class: MarkerStatusColor[statusKey],
  };
};
export const UseCurrentMarkerIcon = () => {
  return readonly(currentMarkerComponentIcon);
};

export default HelperIconsMenu;
