import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/UserStore';
import { VueKeycloakInstance, VueKeycloakOptions, type KeycloakProfile, Keycloak } from '@dsb-norge/vue-keycloak-js/dist/types';
import router from '@/router';

const KEYCLOAK_ENV = {
  url: import.meta.env.VITE_KEYCLOAK_URL,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
  clientSecret: import.meta.env.VITE_KEYCLOAK_CLIENT_SECRET,
  scope: import.meta.env.VITE_KEYCLOAK_SCOPE,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
};

const KEYCLOAK_CONFIG: VueKeycloakOptions = {
  init: {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  },
  config: {
    url: KEYCLOAK_ENV.url,
    clientId: KEYCLOAK_ENV.clientId,
    realm: KEYCLOAK_ENV.realm,
  },
  async onReady(keycloak: Keycloak, VueKeycloak?: VueKeycloakInstance) {
    if (VueKeycloak?.authenticated) {
      const userStore = useUserStore();
      const { GET_CURRENT_KEYCLOAK_USER } = storeToRefs(userStore);
      try {
        userStore.SET_CURRENT_ACTIVE_KEYCLOAK_USER({
          id: VueKeycloak?.subject || '',
          fullName: VueKeycloak?.fullName || '',
          username: VueKeycloak?.userName || '',
          roles: {
            'radar-administrator': keycloak.hasRealmRole('radar-administrator'),
            'radar-coordinator': keycloak.hasRealmRole('radar-coordinator'),
            'radar-reporter': keycloak.hasRealmRole('radar-reporter'),
          },
        });
        const userDetails: KeycloakProfile = await keycloak.loadUserInfo();
        userStore.SET_CURRENT_ACTIVE_USER(userDetails);

        GET_CURRENT_KEYCLOAK_USER.value.isCoordinator ? router.push('/coordinator') : null;
      } catch (error) {
        console.error(error);
      }
    }
  },
  onInitError() {
    router.push('/');
  },
  onInitSuccess(authenticated) {
    console.log(authenticated);

    if (authenticated) {
      router.beforeResolve((to) => {
        if (to.path === '/public-map') {
          router.replace('/');
        }
      });
    }
  },
};

export { KEYCLOAK_CONFIG, KEYCLOAK_ENV };
