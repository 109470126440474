import type { VueOidcSettings } from 'vue3-oidc';
import { createOidc, useOidcStore } from 'vue3-oidc';
import { unref } from 'vue';
import { WebStorageStateStore } from 'oidc-client-ts';
import { KEYCLOAK_ENV } from './config/KeycloakConfiguration';
import { useUserStore } from '@/stores/UserStore';
import { CookieStorage } from 'cookie-storage';
import { CookieOptions } from 'cookie-storage/lib/cookie-options';
const { state } = useOidcStore();
const domain = import.meta.env.VITE_DOMAIN;

const expirationTime = 30 * 24 * 60 * 60 * 1000;
const expirationDate = new Date(Date.now() + expirationTime);
console.log('Expiration Date:', expirationDate);

const cookieSettings: CookieOptions & { discard?: boolean } = {
  path: '/',
  // sameSite: 'None',
  secure: true,
  expires: expirationDate,
};
if (domain) {
  cookieSettings.domain = domain;
  cookieSettings.discard = false;
}
const cookieStorage = new CookieStorage(cookieSettings);

export const oidcSettings: VueOidcSettings = {
  authority: KEYCLOAK_ENV.url,
  metadataUrl: `${KEYCLOAK_ENV.url}/.well-known/jwks.json`,
  metadata: {
    token_endpoint: `${KEYCLOAK_ENV.url}/oauth/access_token`,
  },
  scope: KEYCLOAK_ENV.scope,
  client_id: KEYCLOAK_ENV.clientId,
  client_secret: KEYCLOAK_ENV.clientSecret,
  redirect_uri: origin + '/oidc-callback',
  automaticSilentRenew: true,
  monitorSession: true,
  revokeTokensOnSignout: true,
  // accessTokenExpiringNotificationTimeInSeconds: 3500,
  onSigninRedirectCallback(user) {
    location.href = unref(state).redirect_uri || '/';
  },
  userStore: new WebStorageStateStore({ store: cookieStorage }),
  stateStore: new WebStorageStateStore({ store: cookieStorage }),
};

createOidc({
  oidcSettings: oidcSettings, //your oidc settings
  auth: false, //if auth is true,will auto authenticate
  events: {
    addAccessTokenExpiring: () => {
      console.log('addAccessTokenExpiring');
    },
    addAccessTokenExpired: () => {
      const userStore = useUserStore();
      userStore.ACTION_REFRESH_TOKEN();
    },
    removeUserSignedIn: () => {
      console.log('addUserSignedOut');
    },
    addUserLoaded: () => {
      console.log('User login');
    },
  }, //your oidc customization callback events
});
