import { CommuneRegistrationDto } from './../../typings/api/index';
import { useDialog } from 'naive-ui';
import { UseConfirmationModal } from '@/core/composables';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const modalStatus = ref(false);

export const useUpdateForm = () => {
  return { modalStatus };
};
export const useCommuneConfirmationModal = () => {
  const { confirmActionDialog, successDialog } = UseConfirmationModal();
  const isLoading = ref(false);
  const { t } = useI18n();
  const c = useDialog();
  const successDialogView = (leaveCallBack?: () => void) => {
    successDialog({
      c,
      t,
      content: t('admin.commune.update.feedback.success'),
      textClass: 'rd-text-center rd-text-xl',
      onLeave: () => {
        leaveCallBack?.();
        c.destroyAll();
      },
    });
  };

  const errorDialogView = (content = t('admin.commune.update.feedback.update.error')) => {
    successDialog({
      c,
      t,
      content,
      cssClass: 'rd-text-center rd-text-3.5xl rd-text-red-500',
      iconClass: 'rd-text-red-500',
    });
  };
  const updateActiveCommune = (
    r: Required<CommuneRegistrationDto>,
    actionCallback: () => Promise<void>,
    leaveCallBack?: () => void,
    showSuccess = true,
  ) => {
    confirmActionDialog({
      c,
      t,
      onPositive: async () => {
        try {
          isLoading.value = true;
          await actionCallback().then(() => {
            showSuccess
              ? successDialog({
                  c,
                  t,
                  content: t('admin.commune.update.feedback.success'),
                  textClass: 'rd-text-center rd-text-xl',
                  onLeave: () => {
                    leaveCallBack?.();
                    c.destroyAll();
                  },
                })
              : null;
          });
        } catch (error) {
          errorDialogView();
        } finally {
          isLoading.value = false;
        }
      },
      content: t('admin.commune.update.feedback.content', {
        communeName: r.manualName,
      }),
    });
  };

  return { isLoading, updateActiveCommune, successDialogView, errorDialogView };
};
