import SuccessView from '@/core/components/SuccessView.vue';
import { IconLocal } from '@/Dashboard';
import { DialogOptions } from 'naive-ui';
import { DialogApiInjection } from 'naive-ui/lib/dialog/src/DialogProvider';
import { h, VNodeChild } from 'vue';

export const confirmActionDialog = ({
  c,
  t,
  onPositive,
  content = t('configuration.map.feedback.confirmation'),
  type = 'success',
  positiveText,
  positiveButtonProps = {
    size: 'large',
    type: 'success',
  },
  title = t('settings.view.commune.reasons.action.title'),
  cssClass = '',
  cssHeaderClass = '',
  onNegative,
  ...props
}: DialogOptions & {
  c: DialogApiInjection;
  t: (s: string) => string;
  onPositive: () => void;
  onNegative?: () => void;
  content: string | ((() => VNodeChild) | string);
  cssClass?: string;
  cssHeaderClass?: string;
}) => {
  const dialog = c.create({
    type,
    class: `rd-p-16 rd-py-10 rd-text-center ${cssClass}`,
    title: () => h('h4', { class: cssHeaderClass }, title),
    icon: () => h(IconLocal, { class: 'rd-text-red-600' }),
    content: content,
    positiveText: positiveText ? positiveText : t('global.actions.continue'),
    negativeText: t('filtering.confirmation.no'),
    positiveButtonProps,
    negativeButtonProps: {
      size: 'large',
    },
    onPositiveClick: async () => {
      dialog.loading = true;
      onPositive();
    },
    onNegativeClick: async () => {
      dialog.loading = true;
      onNegative?.();
      return;
    },
    ...props,
  });
};

export const successDialog = ({
  c,
  t,
  onLeave,
  content = t('configuration.map.feedback.confirmation'),
  cssClass = 'dark:rd-text-base-bg rd-h-[250px]',
  iconClass,
  textClass = 'rd-text-center rd-text-xl rd-px-14',
  ...props
}: DialogOptions & {
  c: DialogApiInjection;
  t: (s: string) => string;
  onLeave?: () => void;
  content: string;
  cssClass?: string;
  iconClass?: string;
  textClass?: string;
}) => {
  c.create({
    ...props,
    showIcon: false,
    action: () => h('span'),
    content: () => h(SuccessView, { cssClass: cssClass, iconClass }, { default: () => h('h4', { class: textClass }, content) }),
    onAfterLeave: () => onLeave?.(),
  });
};

export const UseConfirmationModal = () => {
  return { confirmActionDialog, successDialog };
};
