import { storeToRefs } from 'pinia';
import { AuthTokenInterface, useUserStore } from '@/stores/UserStore';
import { useOidcStore } from 'vue3-oidc';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { PrivateRoutes } from '@/router/PrivateRoutes';
import { PublicRoutes } from '@/router/PublicRoutes';
import { useJwt } from '@vueuse/integrations/useJwt';

const routes: RouteRecordRaw[] = [...PrivateRoutes, ...PublicRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
const { state } = useOidcStore();

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const { token, keycloakUserDetails } = storeToRefs(userStore);
  if (to.matched.some((record) => record.meta.requiresAuth || record.meta.COORDINATOR_ONLY || record.meta.COORDINATOR_ONLY)) {
    if (state.value.token) {
      if (!token.value) {
        const { payload } = useJwt(state.value.token);
        token.value = { payload: payload as unknown as AuthTokenInterface };
      }
      if (token.value && !keycloakUserDetails.value.id) {
        userStore.SET_CURRENT_ACTIVE_KEYCLOAK_USER({
          id: token.value.payload.sub || '',
          fullName: token.value.payload.username || '',
          username: token.value.payload.username || '',
          roles: {
            'radar-administrator': token.value.payload.scopes.includes('radar-administrator'),
            'radar-coordinator': token.value.payload.scopes.includes('radar-coordinator'),
            'radar-reporter': token.value.payload.scopes.includes('radar-reporter'),
          },
        });
        await userStore.ACTION_FETCH_PROFILE();
      }

      if (to.matched.some((record) => record.meta.COORDINATOR_ONLY)) {
        if (keycloakUserDetails.value.isAdmin || keycloakUserDetails.value.isCoordinator) {
          next();
        } else {
          next({ path: '/' });
        }
      } else if (to.matched.some((record) => record.meta.ADMIN_ONLY)) {
        if (keycloakUserDetails.value.isAdmin) {
          next();
        } else {
          next({ path: '/' });
        }
      } else {
        next();
      }
    } else {
      next({ path: '/public-map' });
    }
  } else if (state.value.token && to.path === '/public-map') {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
