import { RouteRecordRaw } from 'vue-router';

const PublicRoutes: RouteRecordRaw[] = [
  {
    path: '/public-map',
    name: 'public-map',
    component: () => import('@/views/public/PublicMap.vue'),
  },
  {
    path: '/login',
    name: 'loginView',
    component: () => import('@/views/public/UserLoginView.vue'),
  },
  {
    path: '/register',
    name: 'UserRegistrationView',
    component: () => import('@/views/public/UserRegistrationView.vue'),
  },
  {
    path: '/report/:reportId',
    name: 'byReportId',
    component: () => import('@/views/public/ByReportId.vue'),
  },
  {
    path: '/report-id/:displayId',
    name: 'ReportByDisplayId',
    component: () => import('@/views/public/ReportByDisplayId.vue'),
  },
  {
    path: '/about',
    name: 'about-us',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/signup/commune',
    name: 'communeRegistration',
    component: () => import('@/views/Commune/CommuneRegistrationView.vue'),
  },
  {
    path: '/registration/verification',
    name: 'emailVerification',
    component: () => import('@/views/User/EmailVerificationView.vue'),
  },
  {
    path: '/reset/password',
    name: 'resetPassword',
    component: () => import('@/views/User/ResetPasswordView.vue'),
  },
  {
    path: '/karte/:communeUrl',
    name: 'shareCommune',
    component: () => import('@/views/public/PreSelectedCommune.vue'),
  },
  {
    path: '/karte',
    name: 'shareCommuneMain',
    component: () => import('@/views/public/PreSelectedCommune.vue'),
  },
  {
    path: '/share/:communeUrl',
    name: 'embedCommune',
    component: () => import('@/views/public/SingleCommuneDetails.vue'),
    meta: {
      layout: 'CommuneShareLayout',
    },
  },
  {
    path: '/sr/:communeUrl',
    name: 'embedSrCommune',
    component: () => import('@/views/public/SingleSrCommuneDetails.vue'),
    meta: {
      layout: 'CommuneShareLayout',
    },
  },
  {
    path: '/mobile-app',
    name: 'mobileApp',
    component: () => import('@/views/public/MobileAppMapView.vue'),
    meta: {
      layout: 'CommuneShareLayout',
    },
  },
  {
    path: '/sr-app/new',
    name: 'srAppNewReport',
    component: () => import('@/views/SrApp/SrApplicationNewReport.vue'),
    meta: {
      layout: 'CommuneShareLayout',
    },
  },
  {
    path: '/sr-app/my-reports',
    name: 'srAppMyReports',
    component: () => import('@/views/SrApp/SrApplicationMyReports.vue'),
    meta: {
      layout: 'CommuneShareLayout',
    },
  },
  {
    path: '/data/pdf',
    name: 'dataPdf',
    component: () => import('@/views/public/SingleCommunePDF.vue'),
    meta: {
      layout: 'shareCommune',
    },
  },
];

export { PublicRoutes };
