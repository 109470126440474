<script lang="ts" setup>
import { IconLocal } from '@/Dashboard';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCommuneStore } from '@/stores/CommuneStore';
import { useUserStore } from '@/stores/UserStore';

/**
 *
 * Call to action for the coordinator to
 * update the license for the commune
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
const userStore = useUserStore();
const communeStore = useCommuneStore();
const { selectedCommune, dropdownSelectedCommune } = storeToRefs(communeStore);
const { keycloakUserDetails } = storeToRefs(userStore);

const isReportingPeriodInactive = computed((): boolean => {
  return selectedCommune.value?.status.reportingPeriodValid || false;
});
const isUserOnly = computed((): boolean => {
  return keycloakUserDetails.value?.roles['radar-reporter'] || false;
});
const showOverlay = computed((): boolean => {
  return !isReportingPeriodInactive.value && isUserOnly.value && !!dropdownSelectedCommune.value.uuid;
});
</script>

<template>
  <div v-if="showOverlay" class="rd-py-4 rd-text-base-100 rd-bg-red-700">
    <div class="rd-container rd-mx-auto rd-max-w-7xl rd-flex rd-items-center">
      <IconLocal class="rd-mr-2" size="30" />
      <span>{{ $t('global.commune.inactive.feedback') }} </span>
    </div>
  </div>
  <div
    v-if="keycloakUserDetails.isAdmin && dropdownSelectedCommune.uuid && !isReportingPeriodInactive"
    class="rd-py-4 rd-text-base-100 rd-bg-red-700"
  >
    <div class="rd-container rd-mx-auto rd-max-w-7xl rd-flex rd-items-center">
      <IconLocal class="rd-mr-2" size="30" />
      <span>{{ $t('global.commune.inactive.feedback-admin') }} </span>
    </div>
  </div>
</template>
<style scoped></style>
