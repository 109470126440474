import { createInput, defaultConfig } from '@formkit/vue';
// @ts-expect-error no types
import { generateClasses } from '@formkit/themes';
import { de, en } from '@formkit/i18n';

import DatePicker from '@/core/components/DatePicker.vue';
import SelectComponent from '@/core/components/SelectComponent.vue';
import InformationComponent from '@/core/components/InformationComponent.vue';
import PasswordComponent from '@/core/components/PasswordComponent.vue';
import PhoneComponent from '@/core/components/PhoneComponent.vue';
import ToggleComponent from '@/core/components/ToggleComponent.vue';

const textClassBase = {
  label: 'rd-block rd-mb-1 rd-font-bold rd-text-small-base',
  inner: 'rd-max-w-md  rd-mb-1 rd-overflow-hidden ',
  input:
    'rd-w-full rd-h-8 rd-pr-3  rd-text-small-base dark:rd-text-white dark:rd-bg-transparent disabled:rd-opacity-25 disabled:rd-cursor-not-allowed rd-border-b-[1px] dark:rd-border-b-base-100 rd-border-base-950 dark:rd-border-opacity-20 focus-within:rd-border-blue-500',
};

const customConfig = defaultConfig({
  config: {
    classes: generateClasses({
      global: {
        outer: 'rd-mb-3 rd-font-sans',
        help: 'rd-text-small-base rd-text-gray-500',
        messages: 'rd-list-none rd-p-0 rd-mt-1 rd-mb-0',
        message: 'rd-text-red-500 rd-mb-1 rd-text-small-base',
        label: 'rd-text-small-base',
      },
      text: { ...textClassBase },
      tel: { ...textClassBase },
      password: { ...textClassBase },
      email: { ...textClassBase },
      textarea: {
        ...textClassBase,
        label: 'rd-block rd-mb-1 rd-font-bold rd-text-small-base',
        inner:
          'rd-border-b-[1px] rd-border-b-navy-800   rd-mb-1 rd-overflow-hidden focus-within:rd-border-blue-500 rd-rounded-lg',
        input: 'rd-block rd-w-full rd-h-96   rd-px-3  rd-text-base dark:rd-text-white dark:rd-bg-base-bg',
      },
      DatePicker: {
        ...textClassBase,
        label: 'rd-block rd-mb-1 rd-font-bold rd-text-small-base',
        inner: 'rd-border-b-[1px] rd-border-b-navy-800   rd-mb-1 rd-overflow-hidden focus-within:rd-border-blue-500',
        input: 'rd-block rd-w-full rd-h-96   rd-px-3  rd-text-base dark:rd-text-white dark:rd-bg-base-990',
      },
      checkbox: {
        wrapper: 'rd-flex',
        label: 'rd-block rd-mb-1 rd-font-bold rd-text-small-base',
        inner: '',
        input: 'rd-h-4  rd-w-4  rd-mr-3 dark:rd-text-white dark:rd-bg-base-990',
      },
      select: {
        ...textClassBase,
      },
    }),
  },
  inputs: {
    DatePicker: createInput(DatePicker),
    CustomSelect: createInput(SelectComponent),
    Information: createInput(InformationComponent),
    PasswordInput: createInput(PasswordComponent),
    PhoneInput: createInput(PhoneComponent),
    CustomToggle: createInput(ToggleComponent),
  },
  locales: { de, en },
  locale: 'de',
});

export { customConfig };
