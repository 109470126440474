<script lang="ts" setup>
import { IconLocal } from '@/Dashboard';
import { inject, ref } from 'vue';

/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
const { isSet } = inject('IS_FILTER_SET', { isSet: false });
</script>

<template>
  <!-- Todo find a way to change the button when a filter is set make it blue outline only not fill  -->
  <n-button text size="tiny" class="rd-px-1 rd-rounded">
    <div
      class="rd-p-1 rd-border rd-rounded"
      :class="{
        'rd-text-base-100 rd-bg-base-920 rd-border-base-920': isSet,
        'rd-bg-base-bg  dark:rd-bg-base-100 ': !isSet,
      }"
    >
      <IconLocal
        type="FilterIcon"
        size="16"
        class=""
        :class="{ 'rd-text-base-100': isSet, 'dark:rd-text-base-bg rd-text-base-100': !isSet }"
      />
    </div>
  </n-button>
</template>
<style scoped></style>
