import { useUserStore } from './UserStore';
import RoadReport from '@/services/ReportService';
import {
  ReportLocationDto,
  ReportDto,
  V1ReportParameters,
  V1LocationParameters,
  V1CommunestatisticsParameters,
  CommuneStatisticsDto,
} from '@/typings/api';
import { Page } from '@/typings/general/Page';
import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { DeepRequired } from './GlobalStore';

export interface CommuneMapEntry {
  location: {
    latitude: number;
    longitude: number;
  };
  uuid: string;
}
interface ReportsAllStoreInterface {
  baseReports: ReportLocationDto[];
  currentUserLocation: V1LocationParameters | null;
  closePopUpOnClick: boolean;
  baseReportsFromUser: boolean;
  communesList: CommuneMapEntry[];
  internalPagination: {
    current: number;
    total: number;
  };

  communeStatistics: DeepRequired<CommuneStatisticsDto>;

  hideOverlayOnMobile: boolean;
}

export const useReportsAllStore = defineStore({
  id: 'ReportsAll',
  state: (): ReportsAllStoreInterface => ({
    baseReports: [],
    currentUserLocation: null,
    closePopUpOnClick: false,
    baseReportsFromUser: false,
    communesList: [],
    internalPagination: {
      current: 1,
      total: 1,
    },
    communeStatistics: { statusCount: {}, total: 0, totalCount: 0 },
    hideOverlayOnMobile: false,
  }),
  getters: {
    GET_ALL_REPORTS: (state): ReportLocationDto[] => {
      return state.baseReports;
    },
    GET_ALL_COMMUNES: (state): CommuneMapEntry[] => {
      return state.communesList;
    },
    GET_CLOSE_POP_UP_STATE: (state) => {
      return state.closePopUpOnClick;
    },
    GET_CURRENT_USER_LOCATION: (state) => {
      return state.currentUserLocation;
    },
    GET_IS_REPORT_FROM_USER: (state) => {
      return state.baseReportsFromUser;
    },
  },
  actions: {
    async ACTION_GET_ALL_REPORTS(location: V1LocationParameters) {
      this.currentUserLocation = location;
      const res = await RoadReport.GetAllLocations(location);
      if (res.status === 200) {
        this.baseReports = res.data.content;
        this.baseReportsFromUser = false;
      }
      return res;
    },
    async ACTION_GET_ALL_REPORTS_BY_COMMUNE({ p, locations }: { p: V1LocationParameters; locations?: V1LocationParameters }) {
      locations ? (this.currentUserLocation = locations) : null;
      const { status, data } = await RoadReport.GetReportsByLocationAndCommuneUuid(p);
      if (status === 200) {
        this.baseReports = data.content;

        this.internalPagination = {
          current: data.pageable.pageNumber || 1,
          total: data.totalPages,
        };
        for (let index = 1; index < data.totalPages; index++) {
          const { status, data } = await RoadReport.GetReportsByLocationAndCommuneUuid({ ...p, page: index });
          status === 200 ? this.baseReports.push(...data.content) : null;
        }

        this.baseReportsFromUser = false;
      }
      return { status, data };
    },
    async ACTION_REFRESH_REPORTS(communeUuid?: string) {
      if (this.currentUserLocation) {
        const res = await RoadReport.GetAllLocations({ ...this.currentUserLocation, communeUuid });
        if (res.status === 200) {
          this.baseReportsFromUser = false;
          this.baseReports = res.data.content;
        }
        return res;
      }
    },
    async ACTION_QUERY_REPORTS_WITH_PARAMS(p: V1ReportParameters) {
      try {
        const res = await RoadReport.QueryReports(p);
        if (res.status === 200) {
          const data = res.data;
          this.baseReports = data.content;
          this.baseReportsFromUser = true;
          // Set the pagination here
          const userStore = useUserStore();
          userStore.ACTION_SET_PAGINATION({
            itemCount: data.totalElements,
            page: data.number,
            pageCount: data.totalPages,
            pageSize: data.size,
            endIndex: 0,
            startIndex: 0,
          });
        }

        return res;
      } catch (error) {
        return error;
      }
    },
    async ACTION_QUERY_REPORTS_WITH_PARAMS_NO_SAVE(p: V1ReportParameters): Promise<AxiosResponse<Page<ReportDto>, unknown>> {
      try {
        const res = await RoadReport.QueryReports(p);
        return res;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return error as unknown;
      }
    },
    /*----------  Commune stats  ----------*/
    async ACTION_GET_COMMUNE_STATS(p: V1CommunestatisticsParameters) {
      try {
        const res = await RoadReport.GetCommuneStats(p);
        if (res.status === 200) {
          this.communeStatistics = res.data;
        }
        return res;
      } catch (error) {
        return error as unknown;
      }
    },
    ACTION_TOGGLE_POP_UP_STATE(status: boolean) {
      this.closePopUpOnClick = status;
    },
    ACTION_SET_BASE_REPORTS(upcomingReports: ReportDto[], isReportFromUser = false) {
      this.baseReports = [];

      this.baseReports = upcomingReports;
      this.baseReportsFromUser = isReportFromUser;
    },
    ACTION_SET_BASE_REPORTS_PUSH(data: ReportDto[]) {
      this.baseReports.push(...data);
    },
  },
});
