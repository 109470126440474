import { useLocalStorage } from '@vueuse/core';
import { createI18n } from 'vue-i18n';
const DateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    'medium-lg': {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  de: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
    },
    'medium-lg': {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
};

const messages = Object.fromEntries(
  Object.entries(import.meta.glob('../locale/*.y(a)?ml', { eager: true })).map(([key, value]) => {
    const yaml = key.endsWith('.yaml');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [key.slice(10, yaml ? -5 : -4), (value as any).default];
  }),
);

const loc = useLocalStorage('language', 'de');

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: loc.value === null ? 'de' : loc.value,
  messages,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datetimeFormats: DateTimeFormats as any,
  warnHtmlInMessage: 'off',
  strictMessage: false,
});

export { i18n };
