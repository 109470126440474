export const DashboardIcon = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.1 26.1">
    <defs>
        <style>.cls-1{fill:currentColor;}</style>
    </defs>
    <path class="cls-1"
        d="m11.19,9.22H.96c-.32,0-.58-.26-.58-.58V.96C.38.64.64.38.96.38h10.23c.32,0,.58.26.58.58v7.68c0,.32-.26.58-.58.58Zm-9.65-1.17h9.07V1.54H1.54v6.51Z" />
    <path class="cls-1"
        d="m25.14,25.73h-10.23c-.32,0-.58-.26-.58-.58v-7.68c0-.32.26-.58.58-.58h10.23c.32,0,.58.26.58.58v7.68c0,.32-.26.58-.58.58Zm-9.65-1.17h9.07v-6.51h-9.07v6.51Z" />
    <path class="cls-1"
        d="m11.19,25.73H.96c-.32,0-.58-.26-.58-.58v-12.79c0-.32.26-.58.58-.58h10.23c.32,0,.58.26.58.58v12.79c0,.32-.26.58-.58.58Zm-9.65-1.17h9.07v-11.63H1.54v11.63Z" />
    <path class="cls-1"
        d="m25.14,14.34h-10.23c-.32,0-.58-.26-.58-.58V.96c0-.32.26-.58.58-.58h10.23c.32,0,.58.26.58.58v12.79c0,.32-.26.58-.58.58Zm-9.65-1.17h9.07V1.54h-9.07v11.63Z" />
</svg>
`;
export const DashboardReportsMain = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29">
    <defs>
        <style>.cls-1{fill:currentColor;}</style>
    </defs>
    <g id="noun-report-3239663">
        <path id="Path_281" class="cls-1"
            d="m20.78,28.96c1.28,0,2.31-1.04,2.31-2.31V3.75c0-1.28-1.03-2.31-2.31-2.31h-3.97c-1.99-1.86-5.08-1.86-7.07,0h-3.97c-1.28,0-2.31,1.04-2.31,2.31v22.89c0,1.28,1.04,2.31,2.31,2.31h15.01Zm-3.51-24.3h-8c.32-2.21,2.37-3.74,4.58-3.42,1.77.26,3.16,1.65,3.42,3.42Zm-9.18.25c-.01.11-.02.22-.02.33,0,.32.26.58.58.58h9.25c.32,0,.58-.26.58-.58h0s0-.08-.01-.12c0-.07,0-.14-.01-.21-.03-.29-.14-.87-.21-1.16-.1-.41-.29-.79-.51-1.16h3.04c.64,0,1.15.52,1.15,1.16v22.89c0,.64-.51,1.15-1.15,1.16H5.77c-.64,0-1.16-.52-1.16-1.16V3.75c0-.64.52-1.16,1.16-1.16h3.03c-.22.36-.41.74-.51,1.16-.07.29-.17.86-.2,1.16Z" />
        <path id="Path_282" class="cls-1"
            d="m7.36,13.29h11.9c.32.01.59-.23.6-.55s-.23-.59-.55-.6c-.02,0-.03,0-.05,0H7.36c-.32.01-.57.28-.55.6.01.3.25.54.55.55Z" />
        <path id="Path_283" class="cls-1"
            d="m19.26,15.64H7.36c-.32.01-.57.28-.55.6.01.3.25.54.55.55h11.9c.32-.01.57-.28.55-.6-.01-.3-.25-.54-.55-.55h0Z" />
        <path id="Path_284" class="cls-1"
            d="m19.26,19.13H7.36c-.32-.01-.59.24-.6.55s.24.59.55.6c.02,0,.03,0,.05,0h11.9c.32-.01.57-.28.55-.6-.01-.3-.25-.54-.55-.55h0Z" />
        <path id="Path_285" class="cls-1"
            d="m14.68,22.63h-7.32c-.32-.01-.59.24-.6.55s.24.59.55.6c.02,0,.03,0,.05,0h7.32c.32.01.59-.24.6-.55s-.24-.59-.55-.6c-.02,0-.03,0-.05,0Z" />
        <path id="Path_286" class="cls-1"
            d="m12.32,9.8c.32-.01.57-.28.55-.6-.01-.3-.25-.54-.55-.55h-4.97c-.32,0-.58.26-.58.58h0c0,1.02,1.42.39,5.55.58Z" />
    </g>
</svg>
`;
export const DashboardReportsMine = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 30">
  <defs>
    <style>.cls-1{fill:currentColor;}</style>
  </defs>
  <path class="cls-1"
    d="m4.12,18.61h.05c.2,0,.39-.09.53-.23l2.7-2.66c.21-.23.2-.59-.03-.8-.22-.2-.55-.2-.77,0l-2.42,2.39-.56-.66c-.2-.23-.58-.25-.81-.05-.23.2-.26.55-.06.79l.8.94c.14.17.35.27.57.28Z" />
  <path class="cls-1"
    d="m3.24,11.01h0c-.15,0-.3.05-.4.14-.23.2-.26.55-.06.79l.79.94c.14.17.35.27.57.28h.03c.21,0,.4-.08.55-.23l2.7-2.66c.21-.23.2-.59-.03-.8-.21-.2-.54-.2-.77,0l-2.42,2.39-.56-.66c-.1-.11-.24-.18-.4-.19Z" />
  <path class="cls-1"
    d="m17.09,16.88h-7.62c-.15,0-.29.06-.4.17-.11.11-.17.25-.16.4,0,.31.26.57.57.57h7.62c.31,0,.57-.26.57-.57,0-.31-.26-.57-.57-.57Z" />
  <path class="cls-1"
    d="m7.15,20.9l-2.42,2.39-.56-.66c-.2-.22-.58-.25-.81-.05-.23.2-.26.55-.06.79l.8.94c.14.17.35.27.57.28h.03c.21,0,.4-.08.55-.23l2.7-2.66c.21-.23.2-.59-.03-.81-.21-.19-.53-.2-.77,0Z" />
  <path class="cls-1"
    d="m9.46,12.57h7.62c.31,0,.57-.26.57-.57,0-.31-.26-.57-.57-.57h-7.62c-.31,0-.57.26-.57.57,0,.31.26.57.57.57Z" />
  <path class="cls-1"
    d="m10,2.45c-.46,0-.84.38-.84.84,0,.15.04.29.11.42.03.06.07.1.11.14.15.17.35.27.57.28.02,0,.03,0,.05,0,.2,0,.4-.07.55-.21.03-.02.05-.05.07-.07.05-.05.08-.09.11-.14.07-.13.11-.28.11-.42,0-.23-.09-.44-.24-.6-.16-.16-.37-.25-.6-.25Z" />
  <path class="cls-1"
    d="m19.83,17.97V5.47c0-.91-.75-1.66-1.66-1.66h-2.15v-.52c0-.61-.5-1.11-1.11-1.11h-1.44c-.38-.79-1.02-1.43-1.82-1.81-1.91-.9-4.19-.09-5.1,1.81h-1.45c-.61,0-1.11.5-1.11,1.11v.52H1.84c-.91,0-1.66.75-1.66,1.66v21.78c0,.91.75,1.66,1.66,1.66h14.51c1.03.73,2.24,1.09,3.45,1.09s2.43-.37,3.46-1.1c.3-.21.59-.46.85-.73,1.1-1.13,1.7-2.62,1.7-4.19v-.11c-.06-3.24-2.75-5.89-5.99-5.9Zm-2.66,9.84s.08-.07.12-.11c.75-.67,1.72-1.02,2.73-.96,1,.06,1.92.49,2.59,1.23-.82.58-1.79.89-2.78.89h-.03c-.34,0-.69-.04-1.03-.12-.63-.14-1.23-.4-1.75-.78.04-.06.1-.11.16-.17ZM5.13,5.45v-2.13h2.22l.02-.08c.08-.37.25-.72.48-1.02.15-.2.33-.39.54-.54,1.19-.89,2.88-.65,3.78.54.23.3.39.65.47,1.02l.02.08h2.23v2.13H5.13Zm9.93,17.46c.42-1.81,1.86-3.26,3.67-3.67.35-.08.72-.12,1.08-.12,2.68,0,4.87,2.18,4.88,4.86v.21c-.06,1.12-.49,2.19-1.23,3.02-.93-1.03-2.26-1.62-3.65-1.62-.37,0-.74.04-1.09.13-.99.22-1.88.74-2.56,1.49-.79-.89-1.22-2.03-1.22-3.22,0-.36.04-.73.12-1.08ZM5.11,6.58h9.81c.61,0,1.1-.5,1.11-1.11v-.52h2.16c.14,0,.27.05.37.15s.15.23.15.37v12.6c-2.42.46-4.34,2.38-4.8,4.8h-4.43c-.31,0-.57.26-.57.57s.26.57.57.57h4.33c0,1.37.48,2.7,1.34,3.76H1.84c-.29,0-.52-.23-.52-.52V5.47c0-.29.23-.52.52-.52h2.15v.52c0,.61.5,1.11,1.11,1.11Z" />
  <path class="cls-1"
    d="m19.8,25.1c1.22,0,2.2-.99,2.2-2.21,0-.59-.23-1.14-.65-1.56-.41-.41-.98-.64-1.56-.64-1.22,0-2.21.99-2.2,2.21,0,1.22.99,2.2,2.21,2.2Zm-1.06-2.21c0-.59.48-1.06,1.06-1.06s1.06.48,1.06,1.06-.48,1.06-1.06,1.06-1.06-.48-1.06-1.06Z" />
</svg>
`;
export const DashboardReportsConfiguration = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 23">
    <defs>
        <style>.cls-1{fill:currentColor;}</style>
    </defs>
    <path class="cls-1"
        d="m2.87,3.86h1.79l.03.13c.2.78.7,1.43,1.4,1.84.7.41,1.51.52,2.29.31,1.03-.27,1.86-1.08,2.14-2.11l.04-.13h.13l13.77-.04c.18,0,.34-.07.46-.19.12-.12.19-.29.19-.46s-.07-.34-.19-.46c-.12-.12-.29-.19-.46-.19l-13.89.04-.03-.13c-.34-1.34-1.55-2.28-2.93-2.29-1.36,0-2.56.93-2.91,2.25l-.04.13h-1.79c-.36,0-.66.29-.66.66s.29.66.66.66Zm3.51-1.87c.28-.29.66-.46,1.06-.5h0s.05,0,.05,0h.12c.95,0,1.72.77,1.72,1.72s-.77,1.72-1.72,1.72-1.72-.77-1.72-1.72c0-.46.17-.89.49-1.21Z" />
    <path class="cls-1"
        d="m24.46,19.09h-11.44l-.04-.13c-.35-1.33-1.57-2.27-2.95-2.29h-.01c-1.39,0-2.61.95-2.96,2.29l-.04.13H2.87c-.36,0-.66.29-.66.66,0,.36.29.66.66.66h4.17l.03.13c.21.79.71,1.46,1.41,1.87.7.41,1.52.53,2.32.32,1.07-.28,1.91-1.12,2.19-2.19l.03-.13h11.44c.18,0,.34-.07.46-.19s.19-.29.19-.46c0-.36-.29-.66-.66-.66Zm-14.43,2.42c-.97,0-1.76-.79-1.76-1.77,0-.47.18-.91.51-1.24.32-.33.75-.51,1.21-.52h0s.04,0,.04,0c.97,0,1.77.79,1.77,1.76s-.79,1.77-1.77,1.77Z" />
    <path class="cls-1"
        d="m24.46,10.62h-4.98l-.04-.13c-.36-1.34-1.58-2.28-2.96-2.29h-.05c-1.36.03-2.56.97-2.91,2.28l-.04.13H2.87c-.36,0-.66.29-.66.66s.29.66.66.66h10.63l.03.13c.21.79.71,1.45,1.41,1.87.71.41,1.53.53,2.32.32,1.07-.28,1.91-1.12,2.19-2.19l.03-.13h4.98c.17,0,.34-.07.46-.19.12-.12.19-.29.19-.46,0-.36-.29-.65-.66-.66Zm-7.98,2.42c-.47,0-.92-.18-1.25-.52-.33-.33-.52-.78-.51-1.25,0-.47.18-.91.5-1.24.33-.33.76-.51,1.22-.52h0s.04,0,.04,0c.47,0,.92.18,1.25.52.33.33.52.78.51,1.25,0,.97-.79,1.76-1.76,1.76Z" />
</svg>
`;

export const DashboardReportsUsers = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 25">
  <defs>
    <style>.cls-1{fill:currentColor;}</style>
  </defs>
  <path class="cls-1"
    d="m21.03,24.09v-3.31c0-3.06-2.49-5.55-5.55-5.55h-1.53s.44-.43.44-.43c1.06-1.03,1.65-2.42,1.67-3.9.02-1.48-.54-2.88-1.58-3.94-1.03-1.06-2.42-1.65-3.9-1.67h-.07c-1.46,0-2.83.56-3.87,1.57-1.06,1.03-1.65,2.42-1.67,3.9-.02,1.48.54,2.88,1.58,3.94l.52.52h-1.52c-3.06,0-5.55,2.49-5.55,5.55v3.31c0,.32.26.58.58.58h19.87c.16,0,.3-.06.41-.17.11-.11.17-.26.17-.41ZM6.13,10.84c0-2.42,1.97-4.39,4.39-4.39s4.39,1.97,4.39,4.39-1.97,4.39-4.39,4.39c-2.42,0-4.38-1.97-4.39-4.39Zm13.74,12.67H1.16v-2.73c0-2.41,1.97-4.38,4.39-4.39h9.94c2.41,0,4.38,1.97,4.39,4.39v2.73Z" />
  <path class="cls-1"
    d="m26,15.71c-.05-3-2.54-5.44-5.55-5.45-.32,0-.58.26-.58.58s.26.58.58.58c2.41,0,4.38,1.97,4.39,4.39v3.31c0,.32.26.58.58.58s.58-.26.58-.58v-3.41Z" />
  <path class="cls-1"
    d="m19.87,9.27c.76-.97,1.17-2.17,1.16-3.39,0-3.06-2.49-5.55-5.55-5.55h-.02c-1.21,0-2.41.41-3.37,1.16-.13.1-.2.24-.22.39-.02.15.02.3.12.42v.02c.12.12.24.19.39.21.15.02.31-.02.43-.12.76-.59,1.7-.92,2.66-.92,2.43,0,4.4,1.97,4.4,4.39,0,.96-.32,1.92-.92,2.68l-.03.04c-.09.12-.12.27-.1.42.02.15.1.29.23.38.26.19.62.13.81-.13Z" />
</svg>
`;
export const DashboardReportsCommunes = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 29">
    <defs>
        <style>.cls-1{fill:currentColor;}</style>
    </defs>
    <path class="cls-1"
        d="m18.33,8.57c1.64,0,2.98-1.34,2.98-2.98s-1.37-2.89-2.98-2.89-2.98,1.32-2.98,2.89c0,1.64,1.34,2.98,2.98,2.98Zm0-4.74c.97,0,1.75.79,1.75,1.75s-.8,1.85-1.75,1.85-1.75-.85-1.75-1.85.79-1.75,1.75-1.75Z" />
    <path class="cls-1"
        d="m23.42,2.98h-.01s0-.02,0-.02C22.41,1.17,20.47.05,18.33.05c-1.98,0-3.82.94-4.79,2.44v.02H4.5C2.06,2.51,0,4.57,0,7.01v17.05C0,26.49,2.02,28.46,4.5,28.46h17.05c2.43,0,4.4-1.97,4.4-4.4V7.01c0-1.72-.99-3.3-2.53-4.03Zm-9.69,2.61c0-2.43,2.06-4.41,4.6-4.41s4.6,1.98,4.6,4.41c0,.77-.47,2.03-1.33,3.53-1.16,2.2-2.76,4.19-3.23,4.74l-.04.05-.04-.05c-1.75-2.16-4.51-6.28-4.56-8.22h0v-.05ZM1.7,25.88c-.28-.56-.57-1.15-.57-1.82V7.01c0-1.8,1.51-3.27,3.37-3.27h8.41l-.03.07c-.18.45-.28,1.09-.28,1.78,0,1.74,1.58,4.59,2.93,6.51l.02.04-.03.03L1.74,25.95l-.03-.06Zm21.67.87c-.59.39-1.17.58-1.83.58H4.5c-.7,0-1.37-.21-1.83-.58l-.04-.04.04-.04,10.36-10.36,10.4,10.4-.05.03Zm1.44-2.69c0,.66-.18,1.24-.58,1.83l-.03.05-10.4-10.4,2.45-2.45.03.05c.52.77,1.03,1.38,1.48,1.92l.13.15c.26.26.61.26.87,0,1.05-1.26,2.71-3.52,3.88-5.48.8-1.5,1.51-2.96,1.42-4.42v-.02s.08-.08.08-.08l-.03-.04h.21l-.06.06c.38.58.55,1.15.55,1.79v17.05Z" />
</svg>
`;
export const DashboardReportsExport = `
<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 22">
<defs>
  <style>
    .cls-1{fill:currentColor;}
  </style>
</defs>
<path class="cls-1" d="m19.66,20.69h-11.62c-.36,0-.65.29-.65.65s.29.65.65.65h11.62c.36,0,.65-.29.65-.65s-.29-.65-.65-.65Z"/><path class="cls-1" d="m22.01,8.97c-.24-.26-.65-.28-.92-.04l-6.59,6.01V.72c0-.36-.29-.65-.65-.65s-.65.29-.65.65v14.21l-6.59-6.01c-.26-.24-.67-.22-.92.04-.24.27-.22.68.04.92l7.68,7c.12.11.28.17.44.17s.31-.06.44-.17l7.68-7c.26-.24.28-.65.04-.92Z"/>
</svg>
`;
export const DashboardReportsLicense = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 30">
    <defs>
        <style>.cls-1{fill:currentColor;}</style>
    </defs>
    <g id="noun-license-3104962">
        <path id="Path_287" class="cls-1"
            d="m6.5,22.16c-.29-.11-.6.04-.71.32l-1.18,3.13c-.19-.24-.37-.49-.52-.75-.17-.28-.49-.41-.81-.33-.35.09-.71.16-1.07.2l1.1-2.91h0c.11-.29-.04-.61-.32-.71-.29-.11-.61.04-.71.32l-1.68,4.45h.79c.64,0,1.28-.07,1.9-.21.32.51.72.98,1.17,1.38l.6.52,1.77-4.68c.11-.29-.04-.6-.32-.71h0Z" />
        <path id="Path_288" class="cls-1"
            d="m10.18,21.01c-.29.11-.43.43-.32.71l1.38,3.65c-.36-.04-.72-.11-1.07-.2-.31-.08-.64.06-.81.34-.15.26-.33.52-.52.75l-.66-1.75h0c-.11-.29-.43-.43-.71-.32-.29.11-.43.43-.32.71l1.25,3.31.6-.52c.45-.4.85-.87,1.17-1.38.62.14,1.26.22,1.9.22h.79s-1.96-5.2-1.96-5.2c-.11-.29-.43-.43-.71-.32h0Z" />
        <path id="Path_289" class="cls-1"
            d="m.66,17.5c.12.3.35.53.65.65.44.17.73.58.75,1.05.03.63.56,1.13,1.2,1.1.03,0,.06,0,.09,0,.46-.06.92.17,1.16.57.32.55,1.03.73,1.58.4.03-.02.05-.03.08-.05.31-.28.78-.28,1.09,0,.26.19.59.26.91.19.31-.07.58-.26.75-.54.09-.16.22-.29.38-.39.23-.15.51-.21.78-.18.63.08,1.21-.37,1.28-1,0-.03,0-.06,0-.09.02-.47.32-.88.75-1.05.59-.23.89-.9.65-1.49-.01-.03-.02-.05-.04-.08-.08-.16-.12-.35-.12-.53,0-.28.1-.54.28-.75.41-.48.36-1.21-.13-1.62-.02-.02-.05-.04-.07-.06-.38-.28-.56-.75-.46-1.21.14-.62-.26-1.23-.88-1.37-.03,0-.06-.01-.09-.02-.47-.06-.85-.4-.98-.85-.17-.61-.8-.97-1.41-.79-.03,0-.06.02-.09.03-.17.06-.36.09-.54.07-.28-.03-.53-.16-.73-.36-.43-.45-1.14-.47-1.59-.03-.01.01-.02.02-.03.03-.19.2-.45.32-.72.35-.18.02-.37,0-.54-.07-.59-.22-1.26.08-1.48.67-.01.03-.02.06-.03.09-.13.45-.51.79-.98.85-.63.09-1.07.67-.98,1.3,0,.03,0,.06.02.09.1.46-.08.93-.46,1.21-.51.37-.63,1.09-.26,1.6.02.03.04.05.06.07.31.36.37.86.17,1.29-.14.29-.15.63-.03.93h.01Zm.71-2.99c.29-.21.53-.48.69-.8.24-.47.32-1.02.2-1.54,0-.02,0-.04.03-.05h0c.35-.05.69-.18.99-.38.07-.05.15-.11.21-.17.27-.24.48-.54.62-.87.02-.05.04-.1.06-.16,0-.03.01-.05.02-.08.02-.04.08,0,.11,0,.05.02.1.03.15.05h0c.21.06.42.08.64.08.07,0,.13,0,.2-.01.54-.06,1.04-.31,1.43-.69.63.66,1.59.88,2.45.56.04-.02.06.01.06.03.23.83.91,1.45,1.76,1.62h.08s.11.01.1.07h0c-.2.89.15,1.81.89,2.34v.06c-.34.41-.54.92-.55,1.46,0,.36.07.71.23,1.03,0,.02,0,.04-.02.06-.5.19-.92.56-1.18,1.02-.15.26-.24.56-.27.86,0,.05-.02.2-.02.2h-.15c-.5-.05-1,.08-1.42.35-.3.19-.56.45-.74.76l-.02.03-.05-.02h0c-.34-.24-.73-.38-1.14-.41h-.08c-.41.03-.81.17-1.14.41-.02.02-.04.02-.06,0,0,0,0,0,0,0-.18-.31-.43-.56-.73-.75-.45-.29-.99-.41-1.52-.35-.03,0-.05,0-.05-.04-.01-.36-.11-.71-.28-1.02-.04-.08-.09-.15-.14-.23-.21-.29-.49-.53-.8-.69-.04-.02-.09-.04-.13-.06l-.07-.03s-.05-.01-.05-.03h0s0-.02,0-.03c0-.01.01-.02.02-.03.01-.02.02-.05.03-.07.02-.05.04-.1.06-.14h0c.07-.19.11-.4.12-.6,0-.06,0-.13,0-.19h0c0-.54-.2-1.05-.55-1.46-.01-.02,0-.04,0-.06,0,0,0,0,0,0h0Z" />
        <path id="Path_290" class="cls-1"
            d="m20.66,12.15h-6.58c-.31,0-.55.25-.55.55,0,.3.25.55.55.55h6.58c.31,0,.55-.25.55-.55s-.25-.55-.55-.55h0Z" />
        <path id="Path_291" class="cls-1"
            d="m20.66,16.7h-5.99c-.31,0-.55.25-.55.55,0,.31.25.55.55.55h5.99c.31,0,.55-.25.55-.55,0-.31-.25-.55-.55-.55h0Z" />
        <path id="Path_292" class="cls-1"
            d="m24.99,6.57c-.03-.12-.1-.22-.2-.3-.04-.05-.07-.09-.11-.14l-4.61-5.05c-.4-.44-.97-.7-1.57-.7h-10.01c-1.56,0-2.83,1.27-2.83,2.83v4.21c0,.31.25.55.55.55.31,0,.55-.25.55-.55h0V3.21c0-.95.77-1.72,1.73-1.73h10.01c.29,0,.56.12.75.33l3.95,4.33h-2.04c-.95,0-1.72-.77-1.72-1.72v-1.1c0-.31-.25-.55-.55-.55-.3,0-.55.25-.55.55v1.1c0,1.56,1.27,2.83,2.83,2.83h2.91c.03.1.05.2.05.31v13.42c0,.95-.77,1.72-1.72,1.72h-9.14c-.31,0-.55.25-.55.55s.25.55.55.55h9.14c1.56,0,2.82-1.26,2.82-2.82V7.56c0-.35-.09-.69-.25-.99h0Z" />
    </g>
</svg>
`;
