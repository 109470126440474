import {
  FormItemProps,
  SelectProps,
  DatePickerProps,
  TabsProps,
  AutoCompleteProps,
  ButtonProps,
  ModalProps,
  InputProps,
  MenuProps,
  DataTableProps,
  PopselectProps,
} from 'naive-ui';

type FormItemThemeOverrides = NonNullable<FormItemProps['themeOverrides']>;
type DatePickerThemeOverrides = NonNullable<DatePickerProps['themeOverrides']>;
type SelectThemeOverrides = NonNullable<SelectProps['themeOverrides']>;
type TabsThemeOverrides = NonNullable<TabsProps['themeOverrides']>;
type AutoCompleteThemeOverrides = NonNullable<AutoCompleteProps['themeOverrides']>;
type ButtonThemeOverrides = NonNullable<ButtonProps['themeOverrides']>;
type ModalThemeOverrides = NonNullable<ModalProps['themeOverrides']>;
type InputThemeOverrides = NonNullable<InputProps['themeOverrides']>;
type TableThemeOverride = NonNullable<DataTableProps['themeOverrides']>;
type MenuThemeOverride = NonNullable<MenuProps['themeOverrides']>;
type PopselectThemeOverride = NonNullable<PopselectProps['themeOverrides']>;

const FormItemStyleOverride: FormItemThemeOverrides = {
  labelTextColor: 'var(--radar-base-colors-white-105)',
};
const DatePickerStyleOverride: DatePickerThemeOverrides = {
  panelColor: 'var(--radar-base-colors-blue-150)',
  panelTextColor: 'var(--radar-base-colors-white-100)',
  calendarTitleTextColor: 'var(--radar-base-colors-white-100)',
  calendarDividerColor: 'var(--radar-base-colors-blue-200)',
  arrowColor: 'var(--radar-base-colors-white-100)',
  iconColor: 'var(--radar-base-colors-white-100)',
  itemTextColor: 'var(--radar-base-colors-white-100)',
  itemColorIncluded: 'rgba(0, 134, 197, .2)',
  peers: {
    Input: {
      placeholderColor: 'var(--radar-base-colors-white-105)',
      paddingMedium: '0',
    },
  },
};

const SelectStyleOverride: SelectThemeOverrides = {
  menuBoxShadow: 'unset',
  peers: {
    InternalSelection: {
      color: 'var(--radar-base-colors-blue-150)',
      textColor: 'var(--radar-base-colors-white-105)',
      border: 'unset',
      placeholderColor: 'var(--radar-base-colors-white-105)',
      colorActive: 'transparent',
      borderActive: 'unset',
    },
    InternalSelectMenu: {
      color: 'var(--radar-base-colors-blue-150)',
      borderRadius: 'unset',
      optionCheckColor: 'transparent',
      optionColorPending: 'var(--radar-base-colors-blue-150)',
      optionColorActivePending: 'var(--radar-base-colors-blue-300)',
      optionColorActive: 'var(--radar-base-colors-blue-300)',
      optionTextColor: 'var(--radar-base-colors-white-100)',
      optionTextColorActive: 'var(--radar-base-colors-white-100)',
      optionTextColorPressed: 'transparent',
    },
  },
};
const SelectStyleOverrideDark: SelectThemeOverrides = {
  menuBoxShadow: 'unset',
  peers: {
    InternalSelection: {
      color: 'var(--radar-base-colors-black-300)',
      textColor: 'var(--radar-base-colors-white-105)',
      border: 'unset',
      placeholderColor: 'var(--radar-base-colors-white-105)',
      colorActive: 'transparent',
      borderActive: 'unset',
      borderFocus: 'unset',
    },
    InternalSelectMenu: {
      color: 'var(--radar-base-colors-black-300)',
      borderRadius: ' 0 0 10px 10px ',
      optionCheckColor: 'var(--radar-base-colors-white-400)',
      optionColorPending: 'var(--radar-base-colors-black-300)',
      optionColorActivePending: 'var(--radar-base-colors-black-400)',
      optionColorActive: 'var(--radar-base-colors-black-400)',
      optionTextColor: 'var(--radar-base-colors-white-100)',
      optionTextColorActive: 'var(--radar-base-colors-white-100)',
    },
  },
};

const TabsStyleOverride: TabsThemeOverrides = {
  barColor: 'transparent',
  tabGapMediumLine: '0',
  tabBorderRadius: 'unset',
  tabPaddingMediumLine: '10px',
  tabTextColorActiveLine: 'var(--radar-base-colors-blue-50)',
};
const AutoCompleteOverride: AutoCompleteThemeOverrides = {
  peers: {
    Input: {
      textColor: 'var(--radar-base-colors-white-100)',
    },
    InternalSelectMenu: {
      ...SelectStyleOverrideDark.peers?.InternalSelectMenu,
    },
  },
};

const ButtonOverride: ButtonThemeOverrides = {
  textColorError: 'var(--radar-base-colors-white-100)',
  textColorFocusError: 'var(--radar-base-colors-white-100)',
  textColorDisabledError: 'var(--radar-base-colors-white-100)',
  textColorPressedError: 'var(--radar-base-colors-white-100)',
  textColorHoverError: 'var(--radar-base-colors-white-100)',
};
const ModalStyleOverride: ModalThemeOverrides = {
  peers: {
    Dialog: {
      closeColorHover: 'var(--radar-base-colors-dark-100)',
    },
  },
};

const InputStylesOverride: InputThemeOverrides = {
  border: ' unset unset 1px red unset',
  placeholderColor: 'var(--radar-base-colors-white-105)',
};
const TableStylesOverride: TableThemeOverride = {
  thColor: 'var(--radar-base-colors-black-400)',
  thColorHover: 'var(--radar-base-colors-black-400)',
  tdColorHover: 'var(--radar-base-colors-white-500)',
  tdColor: 'var(--radar-base-colors-white-200)',
  tdTextColor: 'var(--radar-base-colors-black-100)',
  thTextColor: 'var(--radar-base-colors-white-100)',
  borderColor: 'var(--radar-base-colors-blue-250)',
  peers: {
    Empty: {
      textColor: 'var(--radar-base-colors-white-105)',
    },
    Pagination: {
      buttonBorder: 'unset',
      buttonColor: 'transparent',
      buttonIconColor: 'var(--radar-base-colors-white-100)',
      buttonIconColorHover: 'var(--radar-base-colors-white-100)',
      buttonIconColorPressed: 'var(--radar-base-colors-white-100)',
      itemBorder: 'unset',
      itemBorderRadius: '5px',

      itemTextColorDisabled: 'var(--radar-base-colors-white-105)',
      itemBorderDisabled: '1px solid transparent',
      itemColor: 'var(--white-hsl)',
      itemColorActive: 'var(--radar-base-colors-white-105)',
      itemColorActiveHover: 'var(--radar-base-colors-white-105)',
      itemTextColor: 'var(--radar-base-colors-white-100)',
      itemTextColorActive: 'var(--radar-base-colors-white-100)',
      itemBorderActive: '1px solid transparent',
      peers: {
        Input: {
          // borderRadius: 'unset',
        },
      },
    },
  },
};
const TableStylesDarkOverride: TableThemeOverride = {
  thColor: 'transparent',
  thColorHover: 'transparent',
  tdColorHover: 'var(--modal-mask)',
  tdColor: 'transparent',
  tdTextColor: 'var(--radar-base-colors-white-105)',
  thTextColor: 'var(--radar-base-colors-white-105)',
  borderColor: 'var(--radar-base-colors-blue-250)',
  peers: {
    Empty: {
      textColor: 'var(--radar-base-colors-white-105)',
    },
    Pagination: {
      buttonBorder: 'unset',
      buttonColor: 'transparent',
      buttonIconColor: 'var(--radar-base-colors-white-100)',
      buttonIconColorHover: 'var(--radar-base-colors-white-100)',
      buttonIconColorPressed: 'var(--radar-base-colors-white-100)',
      itemBorder: 'unset',
      itemBorderRadius: '5px',

      itemTextColorDisabled: 'var(--radar-base-colors-white-105)',
      itemBorderDisabled: '1px solid transparent',
      itemColor: 'var(--white-hsl)',
      itemColorActive: 'var(--radar-base-colors-white-105)',
      itemColorActiveHover: 'var(--radar-base-colors-white-105)',
      itemTextColor: 'var(--radar-base-colors-white-100)',
      itemTextColorActive: 'var(--radar-base-colors-white-100)',
      itemBorderActive: '1px solid transparent',
      peers: {
        Input: {
          // borderRadius: 'unset',
        },
      },
    },
  },
};

const MenuStylesOverride: MenuThemeOverride = {
  color: 'var(--radar-base-colors-blue-250)',
  itemColorActive: 'transparent',
  itemColorActiveCollapsed: 'transparent',
  itemColorActiveHover: 'transparent',
  itemColorHover: 'transparent',
  itemTextColorHover: 'var(--radar-base-colors-white-100)',
  itemTextColorActive: 'var(--radar-base-colors-green-500)',
  peers: {
    Dropdown: {
      // color: 'var(--radar-base-colors-blue-300)',
      optionColorActive: 'var(--radar-base-colors-blue-400)',
      optionTextColorActive: 'var(--radar-base-colors-white-100)',
      optionColorHover: 'var(--radar-base-colors-blue-400)',
      borderRadius: 'unset',
    },
  },
};
const MenuLightStylesOverride: MenuThemeOverride = {
  color: 'var(--radar-base-colors-white-105)',
  itemColorActive: 'transparent',
  itemColorActiveCollapsed: 'transparent',
  itemColorActiveHover: 'transparent',
  itemColorHover: 'transparent',

  itemIconColor: 'var(--radar-base-colors-blue-50)',
  itemTextColor: 'var(--radar-base-colors-blue-50)',
  itemTextColorHover: 'var(--radar-base-colors-green-500)',
  itemTextColorChildActive: 'var(--radar-base-colors-green-500)',
  itemTextColorChildActiveHover: 'var(--radar-base-colors-green-500)',
  itemIconColorChildActive: 'var(--radar-base-colors-green-500)',
  itemTextColorActive: 'var(--radar-base-colors-green-500)',
  itemTextColorActiveHover: 'var(--radar-base-colors-green-500)',
};

const PopSelectStylesOverride: PopselectThemeOverride = {
  menuBoxShadow: '1px 1px 0px 0px #9BB0B8 inset, -1px -1px 0px 0px #9BB0B8 inset',
  peers: {
    Popover: {
      color: 'var(--radar-base-colors-blue-200)',
    },
    InternalSelectMenu: {
      optionCheckColor: '#9BB0B8',
      optionColorActive: 'transparent',
      optionTextColorActive: 'var(--radar-base-colors-white-500)',
      optionColorActivePending: 'transparent',
    },
  },
};
export {
  FormItemStyleOverride,
  SelectStyleOverride,
  DatePickerStyleOverride,
  TabsStyleOverride,
  SelectStyleOverrideDark,
  AutoCompleteOverride,
  ButtonOverride,
  ModalStyleOverride,
  InputStylesOverride,
  TableStylesOverride,
  TableStylesDarkOverride,
  MenuStylesOverride,
  MenuLightStylesOverride,
  PopSelectStylesOverride,
};
