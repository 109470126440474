<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { FormKitFrameworkContext } from '@formkit/core';

/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface Props {
  value: string;
  context?: FormKitFrameworkContext;
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  context: undefined,
});

const defaultValue = ref(props.context?._value);

const $emit = defineEmits(['input']);
const internalPhone = ref({
  prefix: '',
  mid: '',
  suffix: '',
});

watch(
  internalPhone,
  (newValue) => {
    const v = `${newValue.prefix} ${newValue.mid} ${newValue.suffix}`.trim();
    $emit('input', v);
    props.context?.node.input(v);
  },
  { deep: true },
);

onMounted(() => {
  const value = props.value.split(' ');
  internalPhone.value.prefix = value[0] || '';
  internalPhone.value.mid = value[1] || '';
  internalPhone.value.suffix = value[2] || '';
  if (props.context) {
    const value = defaultValue.value?.split(' ') || ['', '', ''];
    internalPhone.value.prefix = value[0] || '';
    internalPhone.value.mid = value[1] || '';
    internalPhone.value.suffix = value[2] || '';
  }
});

const inputThemeOverride = { paddingMedium: '0' };

const AllowOnlyNumbersAndDashes = (value: string): boolean => {
  // Check if the value contain digits , dashes or plus sign const
  const valid = /^[\d+-]+$/.test(value);
  return !value || valid;
};
</script>

<template>
  <n-form-item
    class="rd-col-span-3 phone_number"
    :show-feedback="props.context ? props.context.attrs.feedback : false"
    :show-label="false"
    :show-require-mark="false"
  >
    <div class="rd-w-2/6 rd-mr-2">
      <n-input
        v-model:value="internalPhone.prefix"
        :placeholder="$t('registration.commune.user.telephone.areaPlaceHolder')"
        :theme-overrides="inputThemeOverride"
        :allow-input="AllowOnlyNumbersAndDashes"
        maxlength="3"
        data-scroll="prefix"
      />
    </div>
    <div class="rd-w-3/6 rd-mr-2">
      <n-input
        v-model:value="internalPhone.mid"
        :placeholder="$t('registration.commune.user.telephone.midPlaceHolder')"
        :theme-overrides="inputThemeOverride"
        :allow-input="AllowOnlyNumbersAndDashes"
        maxlength="4"
        data-scroll="mid"
      />
    </div>
    <n-input
      v-model:value="internalPhone.suffix"
      :placeholder="$t('registration.commune.user.telephone.PlaceHolder')"
      :theme-overrides="inputThemeOverride"
      :input-props="{ type: 'tel' }"
      :allow-input="AllowOnlyNumbersAndDashes"
      data-scroll="suffix"
    />
    <template #feedback> </template>
  </n-form-item>
</template>
<style scoped></style>
