<script lang="ts" setup>
/**
 *
 * Component Description:User date picker
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { IconLocal } from '@/Dashboard';
import { FormKitFrameworkContext } from '@formkit/core';
import { watch, ref } from 'vue';

interface Props {
  context: FormKitFrameworkContext;
}

const props = defineProps<Props>();
const defaultValue = ref(props.context._value);
const internalValue = ref();

watch(internalValue, (newValue) => {
  props.context.node.input(newValue);
});
</script>

<template>
  <n-switch
    v-model:value="internalValue"
    :size="props.context.attrs.size ? props.context.attrs.size : 'small'"
    :default-value="defaultValue"
  >
    <template #checked-icon>
      <IconLocal type="VerifiedIcon" class="rd-text-base-400" />
    </template>
    <template #unchecked-icon>
      <IconLocal type="NotVerifiedIcon" class="rd-text-red-400" />
    </template>
  </n-switch>
</template>
<style scoped></style>
