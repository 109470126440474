<script lang="ts" setup>
/**
 *Simple helper component to render the label for the menus
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [✔] Integration test.
 * @todo [✔] Update the typescript.
 */

interface prop {
  label: string;
}

const props = defineProps<prop>();
</script>

<template>
  <span>{{ $t(`menu.${props.label}`) }}</span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
