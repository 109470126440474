actions:
  cancel: Abbrechen
  comment:
    create:
      success:
        message: Dein Kommentar wurde versendet. Vielen Dank!
  filter:
    cancel: Abbrechen
    helper: Sie können nur Start- oder Enddatum wählen, aber nicht beide.
    save: Übernehmen
  report:
    create:
      submit: Meldung abgeben
      success:
        message: Deine Meldung wurde erstellt. Vielen Dank!
        report_created: Meldung erfolgreich erstellt
    unMerge:
      success:
        SingleReport: Meldung erfolgreich bearbeitet
        message: >-
          Die zusammengeführten Meldungen wurden getrennt und werden wieder
          einzeln angezeigt. Vielen Dank!
    update:
      actions:
        close: Erledigt
        update: Aktualisieren
      attachment:
        formTitle: Meldungsanhang
      attachmentActive: Meldungsanhang (optional)
      confirmation:
        cancel: Abbrechen
        content: Sind Sie sicher, dass Sie den Anhang entfernen wollen?
        submit: Weiter
        title: Bitte bestätigen Sie die nachfolgende Aktion
      error:
        empty: Bitte wählen Sie einen neuen Standort aus
      locationLabel: Bitte wählen Sie einen Ort auf der Karte
      submit: Änderungen speichern
    visibility:
      singleSuccess: Sichtbarkeit des Berichts erfolgreich aktualisiert
      success: Sichtbarkeit von Berichten erfolgreich aktualisiert
  save: Speichern
admin:
  commune:
    email:
      send:
        success: Email wurde geschickt. Vielen Dank!
        tooltip:
          send: E-Mail senden
          sended: E-Mail wurde bereits gesendet
    pending:
      feedback:
        approval:
          success: >-
            Die Daten der Buchung dieser Kommune wurde übernommen. Alle
            Koordinator*innen wurden benachrichtigt. Vielen Dank!
        content: >-
          Möchten Sie die Buchung dieser Kommune bestätigen? Vorhandene Daten
          werden überschrieben!
    registration:
      feedback:
        confirmation:
          error: >-
            Bitte aktualisieren Sie den Benutzernamen und alle erforderlichen
            Felder!
        delete:
          success: Anhängige Gemeinde erfolgreich gelöscht
        update:
          content: Möchten Sie Ihre Änderungen an der Kommune übernehmen?
          success: Ihre Änderungen an der Kommune wurden übernommen. Vielen Dank!
    set:
      active:
        tooltip:
          body: Ansicht als Koordinator dieser Kommune
    update:
      feedback:
        content: Möchten Sie Ihre Änderungen an der Kommune übernehmen?
        success: Ihre Änderungen an der Kommune wurden übernommen. Vielen Dank!
        update:
          error: Bitte überprüfen Sie, ob alle Angaben für die Gemeinde korrekt sind
  users:
    create:
      birthday: 'Geburtsjahr:'
      birthdayHelp: >-
        Wir brauchen deine Zustimmung zu unseren Datenschutzinformationen. Dafür
        musst du mindestens 16 Jahre alt sein.
      checkbox1: >-
        Ich habe die <a target='_blank'
        href='https://www.radar-online.net/datenschutz'
        class='rd-text-yellow-300'>Datenschutzinformationen</a> zur Kenntnis
        genommen und willige ein, dass meine Angaben und Daten für die darin
        aufgeführten Zwecke erhoben und gespeichert werden. Mir ist bewusst,
        dass ich meine Einwilligung jederzeit für die Zukunft per E-Mail an <a
        href="mailto:datenschutz&#64;radar-online.net"
        class='rd-text-yellow-300' target='_blank'
        >datenschutz&#64;radar-online.net</a> widerrufen kann.
      checkbox2: >-
        Hiermit bestätige ich, dass ich mindestens 16 Jahre alt bin oder die
        Zustimmung einer erziehungsberechtigten Person zur Nutzung der
        Meldeplattform RADar! habe.
      checkbox3: >-
        Die Abgabe von Meldungen ist freiwillig. Der Rechtsweg ist
        ausgeschlossen.
      commune: 'Kommune (optional):'
      coordinatorIn: 'Koordinator*in in den folgenden Gemeinden:'
      email: 'E-Mail:'
      emailConfirmation: 'E-Mail wiederholen:'
      emailInformation: >-
        Diese E-Mail-Adresse ist bereits registriert. Bitte vergib einen
        Benutzernamen, wenn du mehrere Accounts mit derselben E-Mail-Adresse
        registrieren möchtest.
      favoriteCommune: Heimatkommune
      feedback:
        error: >-
          Benutzername oder E-Mail-Adresse nicht verfügbar ist, ändern Sie bitte
          den Benutzernamen oder die E-Mail-Adresse.
      firstName: 'Vorname:'
      firstNamePlaceholder: example
      greeting: 'Anrede:'
      lastName: 'Nachname:'
      password: 'Passwort:'
      passwordRepeat: 'Passwort wiederholen:'
      reporterIn: 'Kommune:'
      role: Nutzerrolle
      success:
        message: >-
          Der Prozess zum Erstellen des neuen Benutzers wurde erfolgreich
          abgeschlossen.
      telephone: 'Telefonnummer:'
      title: 'Anrede:'
      userTitle: 'Titel:'
      userTitlePlaceholder: (Kein)
      username: 'Benutzername (frei wählbar):'
      usernamePlaceholder: example
      verification: 'Benutzerstatus:'
    delete:
      confirmation:
        message: >-
          Möchten Sie die Benutzerdaten löschen? Meldungen bleiben anonymisiert
          erhalten.
      success:
        message: Benutzer*innen wurden gelöscht. Vielen Dank!
    feedback:
      telephone: Die Telefonnummer muss das Format +x (xxx) xxx-xxxx haben.
    form:
      delete:
        label: Benutzer*in löschen
      description: Alle konfigurierbaren Felder sind Pflichtfelder.
    roles:
      radar-administrator: Admin
      radar-coordinator: Koordination
      radar-reporter: Meldung
    title:
      create: Benutzer*in anlegen
      update: Profil bearbeiten
    update:
      success:
        message: Die Informationen des Benutzers wurden erfolgreich aktualisiert.
      title: Benutzerinformationen aktualisieren
administrator:
  commune:
    actions:
      delete:
        content: Möchten Sie Kommune {communeName} löschen?
auth:
  errorView:
    back: Close
    subtitle: >-
      Wenn Sie bereits angemeldet oder registriert sind, schließen Sie bitte
      dieses Modal und versuchen Sie es erneut.
    title: Bitte melden Sie sich an oder registrieren Sie sich
bottom:
  content:
    description: >-
      Kommunen können RADar! optional ihren Radelnden anbieten, die meisten tun
      dies im Zusammenhang mit der Kampagne
                STADTRADELN. <br />
                Viele Kommunen haben mit solch einem Planungs- und Bürgerbeteiligungsinstrument Neuland betreten. Sollte es z. B. mit
                den Bearbeitungen der Meldungen aus Ihrer Sicht zu langsam vorangehen, so bitten wir Sie - und sicherlich auch im Namen
                Ihrer Kommune - um Nachsicht und Geduld.
    title: Zu guter Letzt
comment:
  new:
    attachmentButton: Speichern
    createButton: Kommentar erstellen
    header: Kommentar schreiben
    photo:
      headerLabel: Foto hochladen
      label: Foto hochladen
    placeholder: Kommentar …
    successClose: Kommentar ändern
  update:
    feedback:
      attachmentRemoveSuccess: Kommentar Attachment erfolgreich gelöscht
      attachmentSuccess: Kommentar-Anhang erfolgreich aktualisiert
      success: Kommentar erfolgreich aktualisiert
    header: Kommentar ändern
    photo:
      headerLabel: Anhänge ändern
      label: Kommentaranhang
    updateButton: Kommentar ändern
commune:
  admin:
    create:
      content: Möchten Sie eine neue Kommune erstellen?
      success: Die neue Kommune wurde angemeldet. Vielen Dank!
    table:
      helpers:
        status:
          active: Die Kommune befindet sich im Meldezeitraum
          inactive: Der Meldezeitraum ist zu Ende, der Verwaltungszeitraum noch nicht
          noValidLicesense: Der Verwaltungszeitraum ist zu Ende
    update:
      footer: >-
        Bitte beachten: Die Anmeldung wurde erst dann erfolgreich abgeschlossen,
        wenn Sie auf der Website einen entsprechenden Hinweis sehen. Zudem
        erhalten Sie im Anschluss eine automatische E-Mail-Bestätigung.
  contract-update:
    url:
      invalid: URL nicht gültig oder nicht einzigartig
  coordinator:
    dialog:
      setActive:
        confirm: Bestätigen
        content: Möchten Sie das Dasboard als Koordinator dieser Kommune sehen?
        title: Sind Sie sicher?
    users:
      table:
        commune: Kommune
        email: E-mail
        firstname: Vorname
        lastname: Nachname
        role: Rolle
        username: Benutzname
  create:
    errors:
      invalid:
        json: GEOJSON nicht valide
    failed: Die Kommune ist immer noch aktiv
  current:
    commune: 'Kommune:'
  feedback:
    coordinator:
      required: Mindestens ein koordinierender Benutzer ist erforderlich
    coordinators:
      validation: >-
        Bitte stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt
        sind. Leere Pflichtfelder sind rot markiert.
    error:
      generalError: Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
      invalidFields: Bitte kreuzen Sie alle erforderlichen Felder an
      reporting: |-
        Bitte prüfen Sie den Berichtszeitraum:
        {reportEnd} muss vor {adminEnd} liegen
    field:
      mandatory: Pflichtangabe
    registration:
      content: Sind Sie sicher, dass Sie diese Lizenz erwerben möchten?
      success: >-
        Ihre Buchung ist eingegangen und wird i.d.R. innerhalb von 72 Stunden
        freigeschaltet. "Alle Koordinator*innen werden über die erfolgte
        Freischaltung benachrichtigt"
    user:
      email:
        required: Pflichtangabe
      institution:
        required: Pflichtangabe
      telephone:
        required: Pflichtangabe
  filter:
    byDate:
      endPlaceholder: Enddatum
      label: 'Nach Zeitpunkt filtern:'
      startPlaceholder: Startdatum
    byYear:
      label: 'Nach Jahr filtern:'
    byuuid:
      label: 'Kommune: '
      placeholder: Kommune suchen
    export:
      endPlaceholder: Enddatum
      startPlaceholder: Startdatum
      years: 'Nach Jahr filtern:'
  form:
    approve:
      child:
        header:
          helper: >-
            Wählen Sie eine oder mehrere Gemeinden aus, die den Kindergemeinden
            zugeordnet werden sollen.
          label: 'Zugeordnete Kommunene (z.B bei Landkreisen):'
      geojson:
        feedback:
          invalid: GEOJSON nicht valide
        header:
          helper: >-
            Eingabe ist nur erforderlich, wenn noch keine Gemarkung hinterlegt
            oder die hinterlegte Gemarkung falsch ist.
          label: GEOJSON
    child:
      search:
        label: 'Kommune Kind-Beziehungen einsehen:'
    create:
      STADTRADELN:
        license:
          label: 'STADTRADELN-Teilnahme (kostenreduzierend):'
          option:
            radar: >-
              Die Kommune wird während der Lizenzlaufzeit nicht an <a
              href='https://www.stadtradeln.de' target="_blank"
              class='rd-text-yellow-300 rd-z-10 rd-relative'>STADTRADELN</a>
              teilnehmen.
            sr: >-
              Ich versichere, dass die Kommune in allen Jahren der
              Lizenzlaufzeit an <a href='https://www.stadtradeln.de'
              target="_blank" class='rd-text-yellow-300 rd-z-10
              rd-relative'>STADTRADELN</a> teilnehmen wird.
      adminTimeRange:
        label: 'Verwaltungszeitraum:'
      article:
        label: 'Artikel:'
      billing:
        contact:
          label: Abweichend von anmeldender Person
      confirmation:
        confidentially: >-
          Wir versichern hiermit, dass wir die Kontaktinformationen von
          Radelnden innerhalb unserer Kommune vertraulich behandeln, diese
          ausschließlich im Zusammenhang mit der Kampagne STADTRADELN verwenden
          und die Daten nicht an Dritte weitergeben.*
        context: >-
          Wir sind uns bewusst, dass zwischen der teilnehmenden kommunalen
          Gebietskörperschaft und dem Klima-Bündnis eine gemeinsame
          Verantwortlichkeit nach Art.26 DSGVO bzgl. der Verarbeitung von
          personenbezogenen Daten im Rahmen der Durchführung der Kampagne
          STADTRADELN besteht. Wir stimmen <a href='/data/pdf?commune={commune}'
          target="_blank" class='rd-text-yellow-300'>dieser Vereinbarung </a>
          hiermit zu.*
        feesCheck: >-
          Wir akzeptieren die unter <a
          href='https://www.radar-online.net/lizenzen'  target='_blank'
          class='rd-text-yellow-300'> Lizenzgebühren </a> aufgeführten Preise.*
        municipalityCheck: >-
          Wir erwerben als Kommune (Stadt, Gemeinde oder Landkreis/Region eine
          Lizenz für die Meldeplattform RADar! und melden uns hiermit
          verbindlich an. Die <a href='https://www.radar-online.net/agb'
          class='rd-text-yellow-300' target='_blank'> AGB Aktion STADTRADELN und
          Meldeplattform RADar!</a> gelten ergänzend.*
        privacyCheck: >-
          Wir haben die <a href='https://www.radar-online.net/datenschutz'
          target="_blank" class='rd-text-yellow-300'>
          Datenschutzinformationen</a> zur Kenntnis genommen und willigen ein,
          dass unsere Angaben und Daten für die darin aufgeführten Zwecke
          erhoben und gespeichert werden. Uns ist bewusst, dass wir die
          Einwilligung jederzeit für die Zukunft per EMail an <a
          href='mailto:datenschutz{'@'}stadtradeln.de' target='_blank'
          class='rd-text-yellow-300'> datenschutz{'@'}stadtradeln.de</a>
          widerrufen können.*
        right: Wir verzichten auf unser Widerrufsrecht.*
      country:
        label: 'Land*:'
        placeHolder: '-- Bitte wählen --'
      description: >-
        Nur von kommunaler Seite/Verwaltung ausfüllbar bzw. durch dazu
        beauftragte Personen!
      designation:
        label: 'Bezeichnung:'
      footer:
        label: >-
          <span class='rd-text-yellow-300'>Bitte beachten:</span> Die Anmeldung
          wurde erst dann erfolgreich abgeschlossen, wenn Sie auf der Website
          einen entsprechenden Hinweis sehen. Zudem erhalten Sie im Anschluss
          eine automatische E-Mail-Bestätigung.
      license:
        helper: >-
          Verwaltungsbereich für die Koordinator*innen in der Kommune wird 1
          oder 3 Jahre nach dem ersten Tag, an dem Meldungen von Radler*innen
          abgegeben werden können, geschlossen.
        label: Lizenz
        option:
          free: Kostenfreie Nutzung
          helper: >-
            Falls der aktuelle Verwaltungszeitraum noch nicht abgelaufen ist,
            verlieren Sie den Koordinationszugang bis zum ersten Tag des neuen
            Meldezeitraums.
          one: >-
            1 Jahr (mit maximal 1 Jahr Meldezeitraum) - KOSTENPFLICHTIG, s.  <a
            href='https://www.radar-online.net/lizenzen' target="_blank"
            class='rd-text-yellow-300 rd-z-10 rd-relative'>Lizenzgebühren</a>
          two: >-
            3 Jahre (mit maximal 3 Jahren Meldezeitraum) - KOSTENPFLICHTIG, s.
            <a href='https://www.radar-online.net/lizenzen' target="_blank"
            class='rd-text-yellow-300 rd-z-10 rd-relative'>Lizenzgebühren</a>
      location:
        label: 'Lage des Mittelpunkts der Kommune*:'
        latitude: Breitengrad
        longitude: Längengrad
      member:
        label: 'Mitgliedschaften:'
        option:
          agfk_agfs: >-
            Mitglied bei der AGFK Baden-Württemberg, AGFK, Bayern, AGFK BB, AGFK
            Niedersachsen/Bremen, AGFK-TH oder AGFS NRW
          climate_alliance: Mitglied beim Klima-Bündnis
      name:
        helper: >-
          Korrekte Bezeichnung bei obiger Vorauswahl nicht enthalten? Nur dann
          hier ergänzen:
        inputManualPlaceholder: Z.B. „Die“
        inputManualRegionPlaceholder: z. B. Stadt, Gemeinde, Landkreis/Region
        label: 'Bezeichnung*:'
      nameManual:
        helper: So, wie er später auf der Seite erscheinen soll (ohne Bezeichnung!)
        label: 'Name der Kommune*:'
        placeHolder: Frankfurt
      noInTheList:
        label: >-
          Falls Ihre Kommune nicht aufgeführt ist, kontaktieren Sie uns bitte,
          damit wir Ihre Kommune hinzufügen können.
      officialName:
        label: 'Offizieller Name:'
        placeholder: ...
      personAsCoordinator:
        label: Ich brauche einen Account zur Koordination von RADar!
      platform:
        label: 'Meldeplattform Radverkehr:'
        option:
          MPR_FORWARD: >-
            RADar! mit Weiterleitung an Meldeplattform Radverkehr: <br/>

            Meldungen werden ausschließlich in RADar! bearbeitet und als Kopie
            (read-only) an die MPR übergeben. Änderungen, die in RADar!
            vorgenommen werden, werden an die MPR übermittelt.
          MPR_ONLY: >-
            Meldeplattform Radverkehr: <br/>

            Meldungen werden vollständig an die MPR übertragen und dort
            bearbeitet. In RADar! verbleibt eine Kopie (read-only) der Meldung,
            die jedoch in RADar! nicht bearbeitet werden kann. Änderungen, die
            in der MPR vorgenommen werden, werden an RADar! übermittelt.
          NONE: >-
            Kommunen in Hessen können sowohl die <a
            href="https://meldeplattform-radverkehr.de/?x=474387.64&y=5552554.46&z=10"
            class="rd-text-yellow-300" target="_blank"> Meldeplattform
            Radverkehr </a>  als auch <a href="https://www.radar-online.net"
            class="rd-text-yellow-300" target="_blank"> RADar!</a> nutzen. <a
            href="https://meldeplattform-radverkehr.de/kommunen?x=474387.64&y=5552554.46&z=10"
            class="rd-text-yellow-300" target="_blank">Hier</a> können Sie
            sehen, ob Ihre Kommune bereits die Meldeplattform Radverkehr nutzt.

            <br/>

            <br/>

            Alle über RADar! eingegebenen Meldungen, inklusive der Kontaktdaten
            der Stadtradeln-Koordinatoren, werden automatisch an die
            Meldeplattform Radverkehr übermittelt. Nachträgliche Änderungen an
            Meldungen in RADar! werden ebenfalls an die Meldeplattform
            Radverkehr übertragen. Änderungen, die in der Meldeplattform
            Radverkehr vorgenommen werden, werden nicht an RADar! übermittelt

            <br/>

            <br/>

            Sollten Sie sich für die Nutzung beider Meldungssysteme entscheiden,
            stimmen Sie sich bitte mit den jeweiligen Bearbeiterinnen und
            Bearbeitern in Ihrer Kommune ab. 

            <br/>

            <br/>

            Sollten Sie die Zuständigen für die Meldeplattform Radverkehr in
            Ihrer Kommune nicht kennen, kontaktieren Sie bitte die ivm GmbH (<a
            href="to:meldeplattform{'@'}ivm-rheinmain.de"
            class="rd-text-yellow-300"
            target="_blank">meldeplattform{'@'}ivm-rheinmain.de;</a>
            069-660759-0).

            <br/>

            <br/>

            Für die ganzjährige Nutzung der landesweiten Meldeplattform
            Radverkehr übernimmt das Land Hessen alle Kosten. Die landesweite
            Nutzungsmöglichkeit ermöglicht es Radfahrenden in Hessen unabhängig
            von Gemeinde- oder Kreisgrenzen Meldungen abzugeben.
      population:
        helper: Nur Hauptwohnsitz
        label: 'Einwohnerzahl*:'
        placeHolder: Bitte möglichst genau
      reportingPeriod:
        helper: >-
          Als Start können Sie jeden beliebigen Tag im Jahr wählen. Das Ende ist
          spätestens ein Jahr bzw. drei Jahre später, minus einen Tag
      reportingTimeRange:
        from: Von
        helper: >-
          Wenn Sie den Ort auswählen, werden Längen- und Breitengrad automatisch
          angezeigt. Sie können sie manuell ändern.
        label: >-
          Meldezeitraum, in dem Meldungen von Radler*innen abgegeben werden
          können*:
        to: Bis
      rs:
        helper: Händische Korrektur ggf. hier
        label: 'Regionalschlüssel (RS)*:'
      sameNameCountryOrRegion:
        description: >-
          Bei Registrierung für Teams/Radler*innen erscheint bei Kommunenauswahl
          in alphabetischer Reihenfolge z. B. „Bamberg, Landkreis“ und „Bamberg,
          Stadt“
        label: 'Gleichnamige Stadt und Landkreis/Region vorhanden:'
      search:
        label: 'Kommune suchen:'
      state:
        label: 'Bundesland*:'
        placeHolder: '-- Bitte wählen --'
      timeZone:
        label: Time Zone
      title: Anmeldung für Kommunen
      usable:
        label: 'Test Kommune :'
    licenseRenewal:
      submit: Kostenpflichtig buchen
      title: Buchungsverlängerung
    parent:
      search:
        label: 'Kommune Eltern-Beziehungen:'
    pending:
      title: 'Freischalten:'
    update:
      actions:
        confirm: Buchung bestätigen
        delete: löschen
        save: Speichern
      title: 'Kommune bearbeiten:'
  licence:
    bad-period: Der Meldezeitraum muss zwischen dem Verwaltungszeitraum liegen.
  menu:
    selectedOverview: 'Meldezeitraum: '
  overlay:
    select:
      description: >-
        Wählen Sie eine Gemeinde aus der Gemeindeliste aus, um alle Details und
        Meldungen der ausgewählten Gemeinde anzuzeigen.
  search:
    btn: Suchen
    placeHolder: Suche Kommune
  select:
    commune:
      label: 'Kommune suchen:'
    status:
      placeholder: Aktuelle Meldungen
  table:
    header:
      action:
        new: Neue Kommune anlegen
      form:
        filter:
          dateEnd: 'Ende der Verwaltung:'
          dateStart: 'Verwaltungsstart:'
          helper:
            label: 'Stard- oder Enddatum von Verwaltungszeitraum wählen:'
      labels:
        actions: Aktionen
        coordinator: Koordinator
        end: Bis
        id: RS
        license: Lizenz
        managementPeriod: Verwaltungszeitraum
        name: Name der Kommune
        reportPeriod: Meldezeitraum
        start: Von
      pending: Freizuschaltende Kommunen
      pendingHelper: >-
        Hier werden alle neuen Buchungen von bestehenden oder neuen Kommunen
        aufgelistet, die noch nicht ins System übernommen wurden. Überprüfe die
        Buchung (inbesondere, ob Kommune und/oder Koordinator*innen schon
        existieren) und klicke anschließend auf “Buchung freischalten”, um die
        Kommunen-Sychronisierung anzustoßen, neue Koordinator*innen anzulegen
        und alle alten und neuen Koordinator*innen der Kommune über die
        Freischaltung zu informieren. Um einen Zwischenstand abzuspeichern,
        kannst du auf Speichern klicken. Regionalschlüssel können aktuell nur in
        SR angelegt werden.
      pendingSubTitle: Alle Änderungen gehen erst durch diesen Arbeitsschritt live!
      title: Übersichtsliste aller Kommunen
      totalCountLabel: Einträge pro Seite anzeigen
      totalLabel: 'Einträge anzeigen  '
  update-contract:
    failed: Ungültiger Berichtszeitraum und Verwaltungszeitraum angegeben
configuration:
  confirmation:
    content: Möchten Sie die Änderungen für diese E-Mail-Vorlage übernehmen?
  email:
    address:
      label: 'Anrede:'
    body:
      extra: 'Ergänzungen:'
      label: 'Text:'
    comment:
      subtitle: >-
        Text ändern, den die meldende Person erhält, wenn der Meldung ein
        Kommentar hinzugefügt wird.
      title: Kommentar
    commnet:
      subtitle: >-
        Text ändern, den die meldende Person erhält, wenn der Meldung ein
        Kommentar hinzugefügt wird.
      title: Kommentar
    confirmation:
      subtitle: >-
        E-Mail anpassen, den die meldende Person als Eingangsbestätigung der
        Meldung erhält.
      title: Eingangsbestätigung
    delete:
      subtitle: >-
        Text ändern, den die meldende Person erhält, wenn die Meldung gelöscht
        wird.
      title: Löschen
    greeting:
      label: 'Grußformel:'
    info:
      body: >-
        Optional können Sie hier Text ergänzen, der sich an die Radelnden
        richtet. Andernfalls bleibt das Feld leer.
      replyTo: >-
        Etwaige Antworten der meldenden Personen auf automatisch versendete
        E-Mails werden an sämtliche Reply-to-Adressen (Antworten-an-Adressen)
        gesendet.
      text: 'Die Textpassagen mit ### werden automatisch ergänzt.'
    note:
      subtitle: >-
        Text ändern, den die meldende Person erhält, wenn der Meldung eine
        Anmerkung hinzugefügt wird.
      title: Anmerkung
    position:
      subtitle: >-
        Text ändern, den die meldende Person erhält, wenn die Position der
        Meldung geändert wird.
      title: Position
    replyTo:
      subtitle: Übersicht aller Nutzer*innen, die als Reply-to-Adresse markiert sind.
      title: Reply-to-Adressen
    reset:
      label: E-Mail-Vorlage zurücksetzen
    status:
      subtitle: >-
        Text ändern, den die meldende Person erhält, wenn der Status der Meldung
        - z. B. von „unbearbeitet“ auf „in Bearbeitung“ - geändert wird.
      title: Statusänderung
    subject:
      label: 'Betreff:'
  feedback:
    replySuccess: Ihre Änderungen in der Reply-to-Liste wurden übernommen. Vielen Dank!
    resetSuccess: Ihre E-Mail-Vorlage wurde zurückgesetzt. Vielen Dank!
    success: Ihre Änderungen in der E-Mail-Vorlage wurden übernommen. Vielen Dank!
  map:
    feedback:
      confirmation: Möchten Sie Ihre Änderungen in den Kontaktinformationen übernehmen?
      email:
        error: Geben Sie eine gültige E-Mail-Adresse ein
      name:
        error: Name ist erforderlich
      phone:
        error: Geben Sie eine gültige Telefonnummer an
      success: >-
        Ihre Änderungen in den Kontaktinformationen wurden übernommen. Vielen
        Dank!
    information:
      email:
        label: 'E-Mail-Adresse*:'
        placeHolder: email.com
      freeText:
        help: >-
          Hier können Sie den Nutzer*innen weitere Informationen darüber geben,
          an wen sie sich in bestimmten Fällen wenden können, oder zusätzliche
          Informationen zur Bearbeitungsdauer usw. geben.
        label: Zusätzliche Informationen oder Hinweise (optionales Freitextfeld)
        placeHolder: Free text...
      name:
        label: 'Name oder Institution*:'
        placeHolder: Name ...
      phone:
        label: 'Telefonnummer*:'
        placeHolder: 00-000-000-0000
      title:
        label: Informationen zur Kontaktaufnahme
    menu:
      comment: Kommentar
      confirmation: Eingangsbestätigung
      contactInformation: Kontaktinformationen
      contactInformationHelper: >-
        Hier Kontaktinformationen einstellen/ändern, die öffentlich auf der
        Karte einzusehen sind.
      delete: Löschen
      embeddedInformation: Karteneinbettung
      embeddedInformationComponent: Karteneinbettung
      embeddedInformationHelper: >-
        Wenn Sie die öffentliche RADar!-Karte z. B. auf der Website Ihrer
        Kommune einbinden möchten, können Sie dies mit folgendem HTML-Code tun.
      note: Anmerkung
      position: Position
      replyTo: Reply-to-Adressen
      statusChange: Statusänderung
  replyTo:
    confirmation:
      content: Möchten Sie Ihre Änderungen in der Reply-to-Liste übernehmen?
  reset:
    content: Möchten Sie die E-Mail-Vorlage zurücksetzen?
coordinator:
  dashboard:
    mobile:
      dashboard: Dashboard
      title: Das Dashboard ist für Desktop und Tablet optimiert.
  export:
    help:
      label: Sie können den Filter nutzen, um eine genauere Auswahl zu treffen.
  feedback:
    deleteAll:
      error: >-
        Es können nicht alle ausgewählten Meldungen gelöscht werden. Bitte
        versuchen Sie es erneut
      success: Die ausgewählte(n) Meldung(en) wurden gelöscht. Vielen Dank!
    unMerge:
      error: >-
        Aktion zum Zurücksetzen doppelter Meldungen fehlgeschlagen. Bitte
        versuchen Sie es erneut.
      success: >-
        Alle duplizierten Meldungen werden auf den ursprünglichen Zustand
        zurückgesetzt.
    updateAll:
      error: >-
        Es können nicht alle ausgewählten Meldungen aktualisiert werden. Bitte
        versuchen Sie es erneut
      success: Der Status aller ausgewählten Meldungen wurde bearbeitet. Vielen Dank!
    users:
      coordinator:
        error: >-
          Der Benutzer kann nicht als Koordinator festgelegt werden. Bitte
          versuchen Sie es erneut
        success: Die Benutzerrolle wurde geändert.
      delete:
        success: Benutzer*innen wurden gelöscht. Vielen Dank!
      reporters:
        error: >-
          Reporter können nicht zur Gemeinde hinzugefügt werden. Bitte versuchen
          Sie es erneut
        success: Die Benutzerrolle wurde geändert.
  fistTimeLogin:
    form:
      checkbox1: >-
        Ich habe die <a target='_blank'
        href='https://www.radar-online.net/datenschutz'
        class="rd-text-yellow-300">Datenschutzinformationen </a> zur Kenntnis
        genommen und willige ein, dass meine Angaben und Daten für die darin
        aufgeführten Zwecke erhoben und gespeichert werden. Mir ist bewusst,
        dass ich meine Einwilligung jederzeit für die Zukunft per E-Mail an <a
        target='_blank' href="mailto:datenschutz{'@'}radar-online.net"
        class="rd-text-yellow-300">datenschutz{'@'}radar-online.net</a>
        widerrufen kann.
      checkbox2: >-
        Ich bin mir bewusst, dass zwischen der teilnehmenden kommunalen
        Gebietskörperschaft und dem Klima-Bündnis eine gemeinsame
        Verantwortlichkeit nach Art.26 DSGVO bzgl. der Verarbeitung von
        personenbezogenen Daten im Rahmen der Durchführung der Kampagne
        STADTRADELN besteht. Ich stimme <a  href='https://www.stadtradeln.de'
        target='_blank' class='rd-text-yellow-300' > dieser  Vereinbarung </a>
        hiermit zu.
      checkbox3: >-
        Ich versichere hiermit, dass ich die Kontaktinformationen von Radelnden
        innerhalb meiner Kommune vertraulich behandele, diese ausschließlich im
        Zusammenhang mit der Nutzung von RADar! verwende und die Daten nicht an
        Dritte weitergebe.
      checkbox4: >-
        Ich bin mir bewusst, dass die teilnehmenden Kommunen die Verantwortung
        für die lokale Verwendung sowie alle damit verbundenen Maßnahmen und
        Veranstaltungen übernimmt und das Klima-Bündnis lediglich die technische
        Infrastruktur und die begleitenden Materialien zur Verfügung stellt.
    subtitle: 'Bevor Sie RADAr! nutzen können, brauchen wir Ihr Einverständnis:'
    title: Willkommen als Koordinator*in
  licenseRenovationCTA:
    title: >-
      Der Verwaltungszeitraum Ihrer Kommune ist abgelaufen. Bitte verlängern Sie
      Ihre Lizenz und warten anschließend die Freischaltung ab. Kostenlose
      Lizenzen nur über <a href='https://www.stadtradeln.de'
            target='_blank' class="rd-text-yellow-300">www.stadtradeln.de</a>.
  overlay:
    CTA: Buchung verlängern
    footer: >-
      Kostenlose Lizenzen nur über <a href='https://www.stadtradeln.de'
      target='_blank' class="rd-text-yellow-300">www.stadtradeln.de</a>.
    noCommuneTitle: Der Verwaltungszeitraum Ihrer Kommune ist abgelaufen.
    subTitle: >-
      Bitte verlängern Sie Ihre Lizenz und warten anschließend die Freischaltung
      ab.
    title: 'Die Verwaltungszeitraum Ihrer Kommune ist abgelaufen. '
  reportEdit:
    history:
      table:
        DUPLICATE:
          endLabel: Getrennt
          label: 'Identische Meldung:'
        FORWARD:
          label: 'Weiterleitung an:'
        STATUS:
          label: 'Status: '
        date: Datum
        modification: Änderung
        username: Nutzer'in
      title:
        label: Bearbeitungsverlauf der Meldung
  users:
    actions:
      delete:
        content: >-
          Wollen Sie die ausgewählten Benutzer*innen löschen? Meldungen bleiben
          anonymisiert erhalten.
        title: Sind Sie sicher?
    userDetails:
      form:
        coordinatorOn: 'Koordinatorin in den folgenden Gemeinden:'
        reporterOn: 'Reporterrolle in den folgenden Gemeinden:'
dashboard:
  breadcrumb:
    admin: Admin
    allUsers: Alle
    communes: Kommune
    coordinators: Koordinator*innen
    dashboard: Dashboard
    export: Exportieren
    homePage: Home
    license: Ihre Lizenz
    myReports: Meine Meldungen
    pendingCommune: Vorläufige Gemeindeangaben
    pendingCommunes: Genehmigung ausstehend
    report-status: Nach Status
    reportMerge: Ausgewählte
    reports: Alle
    reportsList: Meldugen
    setting: Konfiguration
    setting-commune: Kommune
    setting-contact: Kontaktinfo konfigurieren
    setting-email: E-Mails konfigurieren
    setting-embedded: Karte einbetten
    setting-map: Karte
    tools: Tools
    users: Alle
    usersList: Benutzer*innenwaltung
  reportsView:
    DONE:
      title: Erledigte Meldungen
    IN_PROGRESS:
      title: Meldungen in Bearbeitung
    NEUTRAL:
      title: Neutrale Meldungen
    NEW:
      title: Unbearbeitete Meldungen
    POSITIVE:
      title: Positive Meldungen
    all:
      title: Alle Meldungen
email:
  verification:
    feedback:
      deleting: Verladen...
      invalidEmail: >-
        Bitte geben Sie eine gültige E-Mail-Adresse an oder registrieren Sie
        sich.
      invalidToken: >-
        Das Verifizierungs-Token ist ungültig, bitte fordern Sie einen neuen
        Verifizierungslink an.
      loading: E-Mail-Überprüfung läuft...
      sendEmail: >-
        Wir haben eine Nachricht an deine hinterlegte E-Mail-Adresse {email}
        geschickt. Bitte klicke in dieser E-Mail auf den grünen Button, um deine
        Registrierung abzuschließen.
export:
  admin:
    commune:
      header: Kommune exportieren
    report:
      header: 'Meldungen exportieren '
  download:
    error: Bitte überprüfen Sie das ausgewählte Datum
    success: Export erfolgreich heruntergeladen
filter:
  actions:
    DATAEXPORT: Meldungen exportieren
    FILTERRESET: Filter zurücksetzen
    close: Filter schliessen
  card:
    info: >-
      Mit Hilfe der Filter kann die Anzahl der angezeigten Meldungen
      eingeschränkt werden.
    title: Filter
  confirmation:
    message: Die Aktion setzt alle Filter zurück, bitte bestätigen Sie die Aktion.
    reset:
      title: Bestätigung
  menu:
    filter:
      headers:
        REPORT_OTHERS: Sonstiges
        REPORT_REASONS: Meldungsgrund
        REPORT_STATUS: Bearbeitungsstatus
    status:
      DONE: Erledigt
      IN_PROGRESS: In Bearbeitung
      MEINE_MELDUNG: Meine Meldung
      NEUTRAL: Neutrale Meldung
      NEW: Unbearbeitet
      POSITIVE: Positive Meldung
  other:
    address:
      label: 'Nächstgelegene Adresse:'
      placeHolder: Straße, Stadt, Gemeinde
      title: Address
    empty: ' '
    reportId:
      label: 'ID-Nummer der Meldung:'
      placeHolder: 2015-4371
      title: 'Meldung ID:'
    reportPeriod:
      format: dd.MM.yyyy
      label: 'Zeitraum der Meldung:'
      labelTo: Bis
      placeHolder: 10.08.2022
      title: Berichtszeitraum
    reporterBy:
      label: 'Vorname:'
      labelEmail: E-Mail
      labelUuId: 'UUID:'
      lastNameLabel: Nachname
      lastNamePlaceHolder: Bitte Nachnamen eingeben
      lastNameTitle: ' '
      placeHolder: Bitte Vornamen eingeben
      placeHolderEmail: formatbeispiel#email.com
      placeHolderUuId: 0000-22132
      title: Gemeldet durch
      usernameLabel: 'Benutzername:'
      usernamePlaceHolder: Bitte Benutzernamen eingeben
  reasons:
    groupLabel:
      ABSTELLANLAGEN: Abstellanlagen ({selected} von {total} ausgewählt)
      BEHINDERUNG: Behinderung ({selected} von {total} ausgewählt)
      LICHTSIGNALANLAGE_(AMPEL): Lichtsignalanlage (Ampel) ({selected} von {total} ausgewählt)
      OBERFLÄCHE: Oberfläche ({selected} von {total} ausgewählt)
      POSITIVE_MELDUNG: Positive Meldung ({selected} von {total} ausgewählt)
      RADWEGWEISUNG: Radwegweisung ({selected} von {total} ausgewählt)
      SONSTIGE_HINWEISE: Sonstige Hinweise ({selected} von {total} ausgewählt)
      STRASSENBAUARBEITEN: Straßenbauarbeiten ({selected} von {total} ausgewählt)
      VERKEHRSBESCHILDERUNG/MARKIERUNG/BELEUCHTUNG: >-
        Verkehrsbeschilderung/Markierung/Beleuchtung ({selected} von {total}
        ausgewählt)
      VERKEHRSFÜHRUNG: Verkehrsführung ({selected} von {total} ausgewählt)
filtering:
  confirmation:
    'no': Abbrechen
    'yes': Zurücksetzen
footer:
  content:
    copyright: Copyright {year} Klima-Bündnis. Alle Rechte vorbehalten.
    social:
      label: Social Media STADTRADELN
      mediaOptions:
        facebook:
          icon: Facebook
          link: https://www.facebook.com/
        instagram:
          icon: Instagram
          link: https://www.instagram.com/
        tumblr:
          icon: Tumblr
          link: https://www.tumblr.com/
        twitter:
          icon: Twitter
          link: https://www.twitter.com/
        youtube:
          icon: Youtube
          link: https://www.youtube.com/
    toolLabel: RADar! ist ein Tool des Klima-Bündnis.
global:
  action:
    confirmation:
      title:
        sie: Sind Sie sicher?
    home: Zur Kartenansicht
  actions:
    backToMap: Zur Kartenansicht
    cancel: Abbrechen
    close: Schließen
    closeModal: Nah dran
    continue: Bestätigen
    coordinator:
      merge:
        label: Zusammenlegen
        unMerge: Trennen
    copy: Code kopieren
    copyDone: kopiert in die Zwischenablage
    create: Einreichen
    createUser: Speichern
    delete: Löschen
    deleteCoordinators: Als Koordinator entfernen
    deleteReporters: Als Reporter entfernen
    download: Exportieren
    fileType:
      all: Beide
      label: Wähle Dateiformat
      shp: Shapefile
      title: Meldungen exportieren
      xlsx: Excel
    filter: Filter
    fordward: Weiterleiten
    goBack: Zurück
    hover:
      hideOnMap: Verbergen
    login: Login
    merge: Zusammenlegen
    newReport:
      helper:
        label: >-
          Es gibt zwei Möglichkeiten den genauen Ort deiner Meldung zu
          bestimmen:
        step1: Direkt in der Karte
        step2: Über die Eingabe der Adresse
    notVerified: Überprüfung ausstehend
    ok: Ok
    register: Registrieren
    reset: Zurücksetzen
    save: Speichern
    search: Suchbegriff
    serch: Suchen
    setAsCoordinator: Rolle zu Koordination ändern
    setAsReporters: Rolle zu Meldung ändern
    status: Status
    toggleVisibility: Sichtbarkeit umschalten
    undo:
      label: Zurücksetzen
    update: Speichern
    verified: geprüft
  collapseInfo: So funktionier's
  commune:
    country:
      empty: Keine Daten
    create:
      label: Kostenpflichtig anmelden
    inactive:
      feedback: Der Meldezeitraum ist zu Ende. Du kannst nur noch Kommentare erstellen.
      feedback-admin: Der Meldezeitraum ist zu Ende.
    licenseExtend:
      label: Kostenpflichtig buchen
    search:
      empty: >-
        Bitte geben Sie eine Suchanfrage ein, um zu suchen oder die Suchanfrage
        zu ändern.
    tooltip: '  Ausgewählte Kommune'
  data:
    protection:
      info: |-
        Datenschutzinformation: Sie haben bei Buchung oder erstmaligem Login <a
                      href='/data/pdf?commune={commune}' target="_blank"
                      class='rd-text-yellow-300 rd-z-10 rd-relative'>dieser Vereinbarung</a> zur Gemeinsamen Verantwortlichkeit nach Art.26 DSGVO zugestimmt.
  help:
    info: Info
    label: Mehr Infos
  helper:
    anon:
      reports: >-
        Es steht der Kommune frei, ob sämtliche Meldungen öffentlich auf der
        Karte einsehbar sind.
  infoLabel: Mehr Infos
  information:
    commune: >-
      Wenn Sie keine Kommune auswählen, werden die Daten aller Kommunen
      exportiert.
    export: >-
      Wenn Sie kein Datum und keine Kommune auswählen, werden die gesamten
      Systemdaten exportiert.
  labels:
    icons:
      collapsed: Legende einblenden
      open: Legende ausblenden
  menu:
    contact:
      title: Kontakt
  pagination:
    label: '{size}'
    total: Gesamt {total}
  report:
    edit:
      noMap: Bitte öffnen Sie die Karte
      noMapContent: Bitte öffnen Sie die Karte, bevor Sie einen Meldung bearbeiten
    view:
      multiple:
        communes:
          label: >-
            Diese Meldung wurde an einem Ort abgegeben, an dem sich die
            Gemarkung mehrerer Kommunen überschneidet. Wegen abweichender
            Konfigurationseinstellungen kann die Ansicht der Meldung in den
            Kommunen abweichen. <br/> Bitte wählen Sie eine Kommune:
          title: Meldung befindet sich in mehreren Kommunen
  search:
    action:
      go: Go
    address:
      placeholder: Adresse durchsuchen
  table:
    empty:
      communesList: >-
        Bitte geben Sie einen Suchbegriff ein, um die verfügbaren Gemeinden zu
        überprüfen.
      description: Es liegen keine Meldungen mit diesem Status vor.
      label: Keine Daten
    none: (Keine)
    pagination:
      total: 'Meldungen Gesamt: {total}'
      totalCommune: 'Kommunen gesamt: {total}'
      usersTotal: 'Benutzer Gesamt: {total}'
  upload:
    helper:
      label: Bild hinzufügen
  user:
    options:
      salutation:
        MISTER: Herr
        MRS: Frau
        none: (Keine Angabe)
header:
  callToActionText: So funktioniert's!
  description: >-
    Deine Kommune lädt dich ein die Radinfrastruktur zu verbessern. Einfach auf
    der Karte eine Meldung eintragen oder von unterwegs über die <a
    href='https://www.stadtradeln.de/app' target="_blank"
    class='rd-text-yellow-300 rd-font-medium'>STADTRADELN-App</a> oder die <a
    href='https://www.radar-online.net/app' target="_blank"
    class='rd-text-yellow-300 rd-font-medium'>RADar!-App</a>. Mehr erfährst du
    mit Klick auf „So funktioniert’s!
  menu:
    dashboard: Dashboard
    logout: Logout
    map: Kartenansicht
    profile: Profil
    register: Anmeldung
  title: Gemeinsam zu einer besseren Radinfrastruktur
icon:
  status:
    DONE: erledigte Meldung
    IN_PROGRESS: Meldung in Bearbeitung
    MEINE: meine Meldung
    NEUTRAL: neutrale Meldung
    NEW: unbearbeitete Meldung
    POSITIVE: positive Meldung
iframe:
  share:
    contact:
      description: >-
        Für registrierte Nutzer*innen besteht an dieser Stelle die Möglichkeit,
        Kontaktinformationen von Ansprechpartner*innen einzusehen.

        <br />
                    <br />
                    Mehr Informationen unter <a target='_blank' href="www.radar-online.net" class="rd-text-yellow-300">www.radar-online.net </a>
    language:
      de: Deutsch
      en: English
      label: 'Wählen Sie die Sprache:'
    overlay:
      description: |2-
         Für registrierte Nutzer*innen besteht an dieser Stelle die Möglichkeit, neue Meldungen einzutragen.
                    <br />
                    <br />
                    Mehr Informationen unter <a target='_blank' href="www.radar-online.net" class="rd-text-yellow-300">www.radar-online.net</a>
      title: Sie sind nicht angemeldet!
    preview:
      desktop: Desktop
      label: 'Vorschau der Ansicht:'
      mobile: Mobil
    theme:
      dark: Dunkel
      label: 'Wählen Sie das voreingestellte Farbschema:'
      light: Hell
labels:
  name: Name
license:
  form:
    administration:
      end:
        inputLabel: Ende Verwaltungszeitraum
      start:
        header: Verwaltungszeitraum
        inputLabel: Beginn Verwaltungszeitraum
        label: Verwaltungszeitraum
        labelHelper: Andauernd
    feedback:
      confirmation:
        content: Möchte Sie den neuen Meldezeitraum übernehmen?
    reportingPeriod:
      end:
        inputLabel: Ende Berichtszeitraum
      header:
        label: Berichtszeitraum
      start:
        inputLabel: Beginn Berichtszeitraum
        label: Meldezeitraum
        labelHelper: Beendet
      startEnd:
        label: 'Meldezeitraum ändern:'
    status:
      active: Andauernd
      inactive: Beendet
  header:
    label: Ihre RADar!-Lizenz
    renew:
      helper: >-
        Sie nutzen RADar! kostenlos. Wenn Sie einen oder beide Zeiträume ändern
        möchten, brauchen Sie eine kostenpflichtige Lizenz. Informieren Sie sich
        auf <a href='https://www.radar-online.net' target="_blank"
        class='rd-text-yellow-300'>www.radar-online.net</a> über unsere Lizenzen
        und Gebühren.
      helper2: >-

        Informieren Sie sich auf <a href='https://www.radar-online.net'
        target="_blank" class='rd-text-yellow-300'>www.radar-online.net</a> über
        unsere Lizenzen und Gebühren.
      label: Buchung verlängern
  update:
    feedback:
      success: Der neue Meldezeitraum wurde übernommen. Vielen Dank
login:
  feedback:
    login:
      error: >-
        Die Benutzename oder das Passwort, das Sie eingegeben haben, ist falsch.
        Bitte überprüfen Sie Ihre Informationen und versuchen Sie es erneut.
      username:
        noWhiteSpace: Keine Leerzeichen erlaubt
        required: Benutzername ist erforderlich
  modal:
    actions:
      passwordReset: Zugangsdaten vergessen?
      register: Registrieren
    form:
      fromSR:
        label: Konto von  SR
      password:
        placeholder: Passwort
      username:
        placeholder: Benutzername oder E-Mail
    header:
      label: Login
maintenance:
  view:
    description: |-
      RADar! ist wegen geplanter Wartungsarbeiten nicht verfügbar. <br/>
      Wir sind bald mit neuen Funktionen und Verbesserungen zurück.
    title: Wartungsarbeiten
    url: >-
      https://www.radar-online.net/fileadmin/_processed_/9/4/csm_grafik-guter-ueberblick-durch-pins_ab725d96bf.png
map:
  coordinator:
    actions:
      showMap:
        closeLabel: Karte schließen
        label: Karte öffnen
  errors:
    attachment:
      attachmentIdMissing: Anhang nicht gefunden
      deleteSuccess: Anhang aus Meldung entfernt
      filesize: >-
        Bilder hinzufügen (max. 3 MB/Datei). Bitte achten Sie darauf, dass auf
        den Bildern keine Personen erkennbar sind.
      genericError: Anhang kann nicht aktualisiert werden
      maxLimit: Es können maximal 3 Bilder angehängt werden.
      reportIdMissing: Anhang erfolgreich aktualisiert
      success: Anhang erfolgreich aktualisiert
    create:
      fail: Diesmal kann der Meldung nicht erstellt werden. Versuchen Sie es erneut
      missingRequiredFields: >-
        Bitte füllen Sie alle erforderlichen Felder aus, um einen neue Meldung
        zu erstellen
      success: Meldung erfolgreich erstellt
  popup:
    commune:
      inactive:
        description: >-
          Hier wurde von Radelnden eine Meldung zu <strong>{reason}</strong>
          abgegeben. Die Kommune kann diese Meldung erst wieder bearbeiten, wenn
          sie eine neue Lizenz erwirbt. Kontaktiere die Kommune, um das
          anzuregen.
        title: Die Kommune ist derzeit inaktiv
    content:
      attachment: 'Anhänge:'
      comment: 'Beschreibung:'
      commentsAdminLabel: 'Anmerkungen der Gemeindeverwaltung:'
      commentsLabel: 'Kommentare:'
      coordinator:
        title: Anmerkung der Kommune
      formattedAddress: 'Nächstgelegene Adresse: '
      id: 'ID :'
      location: 'Geo-Position: '
      reporter:
        email: 'E-Mail:'
        firstName: 'Vorname:'
        lastName: 'Nachname:'
        role: 'Rolle:'
        title: Benutzerinformationen
        username: 'Benutzername:'
        uuid: 'UuId:'
      status: 'Bearbeitungsstatus: '
      subTitle: 'Allgemeine Angaben: '
      timeStamp: 'Zeitpunkt:'
      title: 'Meldung: '
      workDuration: 'Bearbeitungsdauer:'
    likes: '{likes} Bestätigungen'
  useUserLocation:
    label: Verwenden Sie meinen Standort
menu:
  administration: Administration
  config: Konfiguration
  contact: Kontakt
  dashboard: Dashboard
  dataExport: Daten exportieren
  filter: Filter
  mobile:
    profile: Profile
  newReport: Neue Meldung
  ownReport: Meine Meldungen
  publicMap: Öffentliche Karten
  report: Meldungen
  reports:
    DONE: erledigt ({view}/{total})
    ICONS: legende einblenden
    IN_PROGRESS: in Bearbeitung ({view}/{total})
    NEUTRAL: Neutral ({view}/{total})
    NEW: unbearbeitet ({view}/{total})
    POSITIVE: Positiv ({view}/{total})
    POSITIVECONTENT: |-
      <div class='map-wrapper-text-sm rd-mb-2'><strong>
          @:{'menu.reports.POSITIVECONTENTTITLE'}
      </strong> 
      </div> 
      <div>  </div>
    POSITIVECONTENTTITLE: 'Gesamt: {view} von {total} Meldungen angezeigt'
mobile:
  reports:
    table:
      attachmentLabel: Bilder
      idLabel: 'ID: '
      reasonLabel: 'Meldungsgrund:'
profile:
  update:
    user:
      profile:
        bad-commune: Kommune mit angegebener UUID kann nicht gefunden werden
        success: Die Änderungen wurden übernommen. Vielen Dank!
  user:
    action:
      update:
        label: Bearbeiten
    back:
      confirmation: Bist du sicher? Es werden keine Änderungen übernommen.
    commune:
      label: 'Kommune:'
    delete:
      confirmation: Willst du deinen Account wirklich löschen?
      label: Account löschen?
    feedback:
      deleteSuccess: >-
        Dein Account wird mit allen personenbezogenen Daten gelöscht. Deine
        Meldungen bleiben anonymisiert erhalten, damit sie zur Verbesserung der
        Radinfrastruktur beitragen.
    modal:
      title: Profil bearbeiten
    role:
      label: ' Benutzer*innenrolle:'
    title: Profil bearbeiten
    update:
      confirmation: Willst du dein Profil ändern?
public:
  anonUser:
    contact:
      hint: >-
        Bitte registriere dich oder melde dich an, um Meldungen zu erstellen und
        Kontaktkinformationen einzusehen.
  information:
    card:
      content: >-
        Kommunen entscheiden selbst, wann Meldungen abgegeben werden können und
        welche Meldungsgründe dir dafür zur Verfügung stehen. Sie richten sich
        dabei nach ihren Möglichkeiten. Fragen dazu kann dir am besten die
        Kommune selbst beantworten.
      title: 'BITTE BEACHTE:'
    list:
      '1': >-
        Suche die gewünschte Kommune in der Karte oder über „Kommune suchen“. 
        Falls du die Kommune nicht findest,  suche die Verbandsgemeinde oder den
        Landkreis.
      '2': >-
        Falls die Kommune eine rote Fläche hat, kannst du neue Meldungen
        abgeben.  Dafür musst du dich einloggen. Als STADTRADELN-Teilnehmer*in
        kannst du deine STADTRADELN-Zugangsdaten verwenden.
      '3': >-
        Falls die Fläche der Kommune grau ist, kannst du vorhandene Meldungen
        immer noch kommentieren und bestätigen.
      '4': >-
        Falls du die Kommune überhaupt nicht finden kannst: Nicht alle Kommunen
        nutzen RADar!. Kontaktiere deine Kommune und rege eine RADar!-Nutzung
        an.
    question: ' Meldungen werden automatisch der zuständigen Stelle in der Kommune mitgeteilt, die sich der Sache annimmt und dich über RADar! über die Bearbeitungsschritte informieren wird. Bist du für diese Kommune registriert, kann sie dich auch anhand deiner E-Mail-Adresse kontaktieren'
    title: So funktioniert's!
  newComment:
    hint: Bitte registriere dich oder melde dich an, um Meldungen zu erstellen.
  newReport:
    hint: Bitte registriere dich oder melde dich an, um Meldungen zu abgeben.
registration:
  actions:
    resendEmail: E-Mail-Link nochmal versenden
  commune:
    addCoordinator:
      label: Koordinator*innen hinzufügen
    feedback:
      success: Neue Kommune wurde erstellt. Vielen Dank!
    selectCommune:
      btn: Anmeldung verlängern
      label: 'Kommune wählen:'
    step2:
      title: Pflichtfelder sind mit einem * gekennzeichnet.
    step3:
      title: Für die Kommune anmeldende Person
    step4:
      title: Kontaktdaten (zusätzlicher) lokaler Koordinator*innen
    step5:
      title: Rechnungsempfänger*in
    step6:
      title: 'Nur für Landkreise/Regionen: Zugehörige Städte/Gemeinden anmelden'
    user:
      address:
        label: 'Straße, Hausnr.*:'
        placeHolder: ...
      addressExtend:
        label: 'Adresszusatz:'
        placeHolder: ...
      department:
        label: 'Abteilung/Fachbereich:'
        placeHolder: ...
      email:
        label: 'E-Mail*:'
        placeHolder: email{'@'}email.example
      firstName:
        label: 'Vorname*:'
        placeHolder: Vorname...
      function:
        label: 'Funktion:'
        placeHolder: ...
      institution:
        helper: falls nicht für die Kommune tätig
        label: 'Institution (nur, falls nicht für die Kommune tätig):'
        placeHolder: z.b. Klima-Bündnis e.V.
      lastName:
        label: 'Nachname*:'
        placeHolder: Nachname...
      password:
        label: 'Passwort:'
        placeHolder: Passwort...
      place:
        label: 'PLZ, Ort*:'
        placeHolder: Ort
        zipPlaceHolder: PLZ
      pronouns:
        label: 'Anrede*:'
        placeHolder: '-- Bitte wählen --'
      telephone:
        PlaceHolder: Rufnummer
        areaPlaceHolder: z.B. +49
        label: 'Telefon*:'
        midPlaceHolder: Vorwahl (ohne 0)
      title:
        label: 'Titel:'
        placeHolder: (Kein)
      username:
        label: 'Benutzername:'
        placeHolder: Benutzername...
  modal:
    description: Bitte gib deine Daten ein und klicke anschließend auf “Registrieren”.
    feedback:
      content: >-
        <p>Wir haben eine Nachricht an deine hinterlegte E-Mail-Adresse  <span
        class='rd-text-yellow-300'>{userEmail}</span> geschickt. Bitte klicke in
        dieser E-Mail auf den Link "Benutzerkonto bestätigen", um deine
        Registrierung abzuschließen.</p> <p class="rd-mt-10"> 

        In Einzelfällen kann der Versand bis zu 6 Stunden benötigen. Bitte
        schaue auch in Spam-Ordner nach. Wenn du keine E-Mail von uns erhältst,
        wende dich an deine lokale Koordination oder an <br/> <a
        href="mailto:info{'@'}radar-online.net"' target='_blank'
        class="rd-text-yellow-300">info{'@'}radar-online.net</a>.</p>
      footer: >-
        <h2 class="rd-text-xl">Der Link ist 24 Stunden lang gültig.</h2>

        <p>Danach werden alle Daten gelöscht und du musst dich erneut
        registrieren.</p>
      passwordReset: >-
        <p>Wir haben eine Nachricht an deine hinterlegte E-Mail-Adresse  <span
        class='rd-text-yellow-300'>{userEmail}</span> geschickt. Bitte klicke in
        dieser E-Mail auf den Link "Neues Passwort vergeben", um ein neues
        Passwort festzulegen.</p> <p class="rd-mt-10"> 

        In Einzelfällen kann der Versand bis zu 6 Stunden benötigen. Bitte
        schaue auch in Spam-Ordner nach. Wenn du keine E-Mail von uns erhältst,
        wende dich an deine lokale Koordination oder an <br/> <a
        href="mailto:info{'@'}radar-online.net"' target='_blank'
        class="rd-text-yellow-300">info{'@'}radar-online.net</a>.</p>
      passwordResetFooter: >-
        <h2 class="rd-text-xl">Der Link ist 24 Stunden lang gültig.</h2>

        <p>Danach wird der Link ungültig und du musst erneut die "Zugangsdaten
        vergessen"-Funktion verwenden</p>
      title: Fast fertig!
    header:
      action:
        login: RADar!-Registrierung
        register: STADTRADELN-Login
      label: >-
        STADTRADELN-Zugangsdaten gültig! Hat deine Kommune RADar! währender der
        (letzten) STADTRADELN-Kampagne angeboten, ist keine neuerliche
        Registrierung notwendig! All jene können sich direkt hier einloggen, um
        Meldungen abzugeben.
    subtitle: Alle Felder sind Pflichtfelder, mit Ausnahme der Kommune.
    title: Registrieren
report:
  action:
    create:
      selectCommune:
        adminPeriod: >-
          Der Verwaltungszeitraum ist zu Ende. Damit du wieder Meldungen
          erstellen und kommentieren kannst, muss die Kommune eine neue Lizenz
          erwerben
        description: >-
          Bitte wähle eine Kommune aus, die sich im Meldezeitraum befindet. Auf
          der Karte erkennst du das an der roten Gemarkung.
        reportingPeriod: >-
          Der Meldezeitraum ist zu Ende. Du kannst nur noch Kommentare
          erstellen.
        title: Bitte wählen Sie eine Kommune aus
      titleCommuneInactive: Einschränkung in dieser Kommune
  actions:
    edit:
      coordinatorTooltip: Bearbeiten
      tooltip: Ändern
  attachment:
    modal:
      title: Anhänge aus der Meldung
  comment:
    action:
      delete:
        message: Möchten Sie den Kommentar wirklich löschen?
        'no': Nein
        title: ' Kommentar  löschen'
        'yes': loschen
      edit:
        cancel: Abbrechen
        title: Kommentar bearbeiten
        update: Aktualisieren
  coordinator:
    delete:
      confirmation:
        singleContent: Möchten Sie die ausgewählten Meldungen wirklich löschen?
        singleTitle: Sind Sie sich absolut sicher?
    edit:
      actions:
        cancel: Abbrechen
        delete: Meldung löschen?
        save: Speichern
        saveAndClose: Speichern und schließen
      period:
        label: 'Umsetzungsdauer:'
        placeholder: Keine Angabe
      status:
        label: 'Bearbeitungsstatus:'
        placeholder: In Bearbeitung
      title: Meldung bearbeiten
    tabs:
      FORWARDMESSAGES: Meldung weiterleiten
      HISTORY: Verlauf
      IDENTICALREPORT: Identische Meldung
      NOTES: Notizen
      REMARK: Anmerkung
      content:
        duplicate:
          infoLabel: >-
            Diese Meldung wird zur Hauptmeldung. Die als identisch ausgewählten
            Meldungen sind auf der öffentlichen Karte nicht mehr sichtbar.
        noteInformationContent: >-
          Öffentlich einsehbar, wenn auch generell diese Meldung öffentlich
          sichtbar ist
        noteInformationLabel: >-
          Öffentlich einsehbar, wenn auch generell diese Meldung öffentlich
          sichtbar ist
        notePlaceholder: Anmerkung eingeben …
        noteTitle: Anmerkungen
        notesInformationContent: Helper notes **Need update**
        notesInformationLabel: Nur sichtbar für Koordinator*innen Ihrer Kommune
        notesPlaceholder: Notiz eingeben …
        notesTitle: Eigene Notizen
  create:
    collapse:
      NEWREPORTFORM: Neue Meldung
      NEWREPORTFORMEXTRAINFO: Weitere Angaben zu deiner Meldung
      NEWREPORTFORMPICTURES: Fotos hochladen
      NEWREPORTFORMREASON: Meldungsgrund
      UPDATEREPORTFORM: 'Ändere Meldung: {reportId}'
      UPDATEREPORTFORMEXTRAINFO: Meldungsbeschreibung
      UPDATEREPORTFORMPICTURES: Fotos hochladen
      UPDATEREPORTFORMREASON: Meldungsgrund
      UPDATEREPORTFORMSTATUS: Meldungsstatus aktualisieren
    errors:
      outOfCommuneClick: Achtung
      outOfCommuneContent: >-
        Die Position deiner Meldung muss sich innerhalb der Germarkung der
        ausgewählten Kommune befinden. Beachte auch den Meldezeitraum
    feedback:
      address:
        street: Der Straßenname ist erforderlich
      validation:
        error: Die folgenden Felder müssen ausgefüllt werden
    form:
      address: 'Nächstgelegene Adresse:'
      addressPlaceholder: Straße, Stadt, Gemeinde
      attachmentHelpText: >-
        Bilder hinzufügen (max. 3 MB / Datei).<br/> Bitte achte darauf, dass
        keine Personen oder Kennzeichen auf den Bildern erkennbar sind.
      commentHelpText: Fasse dich möglichst kurz und stichwortartig - noch {count} Zeichen.
      commentMaxReached: Maximal zulässige Textmenge erreicht
      commentPlaceholder: Beschreibe hier, was deine Kommune außerdem wissen muss
      latitude: 'Breitengrad:'
      latitudePlaceholder: 'Formatbeispiel: 50.082251'
      longitude: 'Längengrad:'
      longitudePlaceholder: 'Formatbeispiel: 8.624607'
      search: Suchen
      termAndConditionAttachment: >-
        Ich erteile als Urheber*in des Bildes dem Klima-Bündnis die
        erforderlichen, zeitlich, inhaltlich und räumlich unbeschränkten Rechte
        zur beschriebenen Nutzung und sichere zu, dass eventuelle
        Persönlichkeitsrechte mit etwaig abgebildeten Personen geklärt wurden
        und der umrissenen Verwendung nicht entgegenstehen.

        <br/><br/>

        Du hast jederzeit das Recht erteilte Einwilligungen
        datenschutzrechtlicher Natur für die Zukunft zu widerrufen, dazu reicht
        ein Klick auf "Bild löschen/Widerruf" unterhalb des Uploads.
    mobile:
      '1': Addresse
      '2': Meldungsgrund
      '3': Weitere Angaben zu deiner Meldung
      '4': Fotos hochladen
      tooltip:
        content: |2-
           <header class="rd-text-sm rd-font-bold">
              Es gibt zwei Möglichkeiten den genauen Ort Ihrer Meldung zu bestimmen:
            </header>
            <section class="rd-flex">
              <span class="map-wrapper-counter-item">1</span>
              <span>Direkt in der Karte (Dein Standort oder in die Karte klicken)</span>
            </section>
            <section class="rd-flex">
              <span class="map-wrapper-counter-item">2</span>
              <span>Über die Eingabe der Adresse</span>
            </section>
  delete:
    confirmation:
      cancel: Abbrechen
      confirm: Bestätigen
      content: Möchtest du die Meldung löschen?
      coordinator:
        content: Möchten Sie die ausgewählte(n) Meldung(en) wirklich löschen?
        title: Sind Sie sicher?
      title: Sind Sie sicher?
    feedback:
      success: Deine Meldungen wurde gelöscht. Vielen Dank!
  details:
    address: Adresse
    attachments: Anhänge
    id: ID
    reasonCategoryName: Meldungsgrund
    timestamp: Zeitpunkt
  errors:
    attachment:
      attachment:
        file:
          write-error: Bitte wenden Sie sich an die Gemeindeverwaltung
      attachmentIdMissing: Datei als Anhang nicht gefunden
      deleteSuccess: Anhang aus der Meldung entfernt
      filesize: >-
        Bilder hinzufügen (max. 3 MB / Datei). Bitte achten Sie darauf, dass
        keine Personen auf den Bildern erkennbar sind.
      genericError: Meldungsanhang kann nicht aktualisiert werden
      maxLimit: Maximal 3 Bilder können angehängt werden.
      reportIdMissing: Anhang konnte nicht hinzugefügt werden, die Meldung existiert nicht mehr
      success: Meldungsanhang erfolgreich aktualisiert
    comment:
      maxNum:
        reached: Die maximale (3) Anzahl an Kommentaren wurde erreicht
    commune:
      check:
        exists: Bitte überprüfen Sie, ob Sie eine gültige Gemeinde ausgewählt haben
    create:
      fail: Meldung kann diesmal nicht erstellt werden, versuchen Sie es erneut
      missingRequiredFields: >-
        Bitte füllen Sie alle erforderlichen Felder aus, um einen neue Meldung
        zu erstellen
      success: Meldung erfolgreich erstellt
    person:
      missing: Reporter-UUID fehlt
    reason-category:
      missing: Bitte wählen Sie eine gültige Meldungskategorie aus
    report:
      address:
        unsupported-country: >-
          Derzeit haben wir keine offizielle Unterstützung für dieses Land,
          bitte kontaktieren Sie das Radar-Team für weitere Informationen.
      create:
        location-not-in-area: >-
          Der Standort liegt außerhalb der Grenzen der aktuell ausgewählten
          Gemeinde
    update:
      error: Die Meldung kann nicht aktualisiert werden
      success: Meldung erfolgreich aktualisiert
    validation:
      error: Alle Felder sind erforderlich
  feedback:
    coordinatorSuccess: Meldung erfolgreich bearbeitet.
    error: Die Meldung kann nicht aktualisiert werden
    success: Meldung erfolgreich aktualisiert
  filter:
    modal:
      title: Berichtsoptionen filtern
  forward:
    dialog:
      content: Möchten Sie diese Meldung(en) an {email} weiterleiten?
      negative: Abbrechen
      positive: Weiterleiten
      title: Sind Sie sicher?
    success: Meldung wurde weitergeleitet. Vielen Dank!
    view:
      form:
        feedback:
          email: Bitte geben Sie eine gültige E-Mail ein.
        helper:
          changes: >-
            Falls Sie Änderungen vorgenommen haben, werden sie erst nach dem
            Speichern gesendet.
          regular: >-
            Die E-Mail enthält einige Details zur Meldung, inklusive einem Link
            zur Karte, sowie Ihren Namen und Ihre E-Mail-Adresse. Alle
            personenbezogenen Daten der Radelnden werden anonymisiert. Der Link
            führt nur dann zur Meldung, wenn sie auf der Karte öffentlich
            einsehbar ist.
        input:
          placeholder: max.mustermann{'@'}mustermail.de
        label: >-
          Geben Sie hier die E-Mail-Adresse ein, an die die Meldung
          weitergeleitet werden soll:
        submit: Senden
      title: Meldung weiterleiten
  merge:
    confirmation:
      content: >-
        Sollen die ausgewählten Meldungen mit der Meldung {report} als
        Hauptmeldung zusammengeführt werden?
    description: >-
      Wählen Sie die Hauptmeldung aus. Die anderen Meldungen werden nicht mehr
      auf der öffentlichen Karte angezeigt.
    feedback:
      success: Die Meldungen wurden erfolgreich zusammengeführt. Vielen Dank!
    identical:
      child:
        header: Identische Meldungen
      header: Identische Meldung auswählen und mit dieser zusammenführen
      input:
        prefix: 'Ausgewählt ({selected}/{total}) :'
      label: 'Identische Meldung auswählen:'
    title: Ausgewählte Meldungen zusammenführen
  overlay:
    '404':
      description: >-
        Die Kommune kann Einstellungen vornehmen, die Meldungen auf der Karte
        ausblenden.
      title: Diese Meldung kann nicht auf der Karte angezeigt werden.
  unMerge:
    confirmation:
      confirm: Duplikate wiederherstellen
      content: >-
        Möchten Sie die zusammengeführten Meldungen von Meldung { reportId }
        trennen?
  useUserLocation:
    label: Meinen Standort verwenden
  visibility:
    confirmation:
      confirm: Sichtbarkeit aktualisieren
      content: 'Die Meldung mit ID-Nr.: {reportId} wird für alle Benutzer ausgeblendet.'
      contentVisible: 'Die Meldung mit der ID: {reportId} ist für alle Benutzer sichtbar.'
  warning:
    confirmation:
      confirm: Alle aktualisieren
      content: Soll der Status aller ausgewählten Meldungen geändert werden?
report-status:
  byId:
    '1': Unbearbeitet
    '2': in Bearbeitung
    '3': Erledigt
    '4': Neutrale Meldung
    '5': Positive Meldung
    '6': Meine Meldung
reset:
  actions:
    resetPassword: Passwort übernehmen
  new:
    password:
      confirmation: Soll das Passwort zurückgesetzt werden?
      placeholder: Neues Passwort erstellen
      repeatPlaceholder: Neues Passwort bestätigen
      title: Neues Passwort erstellen
  password:
    action:
      send: Senden
    email:
      label: 'Bitte E-Mail-Adresse eingeben:'
      placeholder: E-Mail-Adresse oder Benutzername
    feedback:
      info: >-
        Nach dem Senden wird ein Link zugeschickt, über den ein neues Passwort
        festgelegt werden kann.
      invalidEmail: Bitte geben Sie eine gültige E-Mail an oder registrieren Sie sich
      invalidToken: >-
        Ungültiges Token, bitte fordern Sie eine neue E-Mail zum Zurücksetzen
        des Passworts an
      password: Passwort ist erforderlich
      passwordConfirm: Passwortbestätigung ist erforderlich
      passwordConfirmNotMatch: ' Passwörter stimmen nicht überein'
      success: Das Passwort wurde zurückgesetzt.
    title: Passwort und/oder Benutzernamen vergessen?
settings:
  view:
    commune:
      archive:
        action:
          content: Möchten Sie Ihre Änderung im Archiv übernehmen?
        description: >-
          Der Status der Meldung wurde auf „erledigt“ oder „neutral“ gesetzt.
          Für die Archivierung ist das Datum, wann die Meldung abgegeben wurde
          entscheidend.
        feedback:
          success: Ihre Änderung im Archiv wurde übernommen. Vielen Dank!
        information: >-
          Über den Export von Meldungen können Sie archivierte Meldungen
          weiterhin einsehen.
        subtitle: >-
          Meldungen werden archiviert und sind nicht mehr öffentlich sichtbar
          nach Monaten:
        title: Archiv
      reasons:
        action:
          content: Möchten Sie Ihre Änderungen in den Meldungsgründen übernehmen?
          title: Bist du sicher?
        feedback:
          success: >-
            Ihre Änderungen in den Meldungsgründen wurden übernommen. Vielen
            Dank!
        information: >-
          Ist ein Häkchen gesetzt, wird dieser Meldungsgrund den Radelnden bei
          Abgabe einer Meldung angezeigt.
        subtitle: >-
          Durch Anklicken festlegen, welche Meldungsgründe den Radelnden zur
          Auswahl stehen.
        title: Meldungsgründe an- und abwählen
        visible: ({visible} von {total} ausgewählt)
      status:
        action:
          content: >-
            Möchten Sie Ihre Änderungen in der Sichtbarkeit auf der Karte
            übernehmen?
        confirmation: >-
          Ist ein Häkchen gesetzt, sind die Meldungen mit dem jeweiligen Status
          für Radelnde und Koordinator*innen auf der Karte und in der Liste der
          Meldungen sichtbar.
        feedback:
          success: >-
            Ihre Änderungen in der Sichtbarkeit auf der Karte wurden übernommen.
            Vielen Dank!
        information: >-
          Ist Häkchen gesetzt, sind die Meldungen für Ihre Radelnden im
          Login-Bereich und auf der Unterseite Ihrer Kommune einsehbar
        subtitle: >-
          Durch Anklicken festlegen, welche Meldungen mit welchem Status
          öffentlich einsehbar sind.
        title: Sichtbarkeit Status auf Karte
sidebar:
  collapse:
    label: Weniger zeigen
  communes:
    RD: Radar!
    SR: Stadtradeln
    all: Alle
    inactive: Inaktiv
    main: Kommunen
    pending: Freizuschaltende Kommunen
  dashboard: Dashboard
  help:
    contact: Kontakt
    description: ' Werfen Sie einen Blick in das Handbuch oder kontaktieren Sie uns telefonisch oder per E-Mail.'
    reports: Handbuch
    title: Sie haben Fragen?
  license: Ihre Lizenz
  myReports: Meine Meldungen
  reports:
    all: Alle ({count})
    done: Erledigt ({count})
    main: Meldungen
    neutral: Neutral ({count})
    new: Unbearbeitet ({count})
    onProgress: In Bearbeitung ({count})
    positive: Positiv ({count})
  settings:
    commune: Meldungen konfigurieren
    contact: Kontaktinfo konfigurieren
    contract: Vertrag
    email: E-Mails konfigurieren
    embedded: Karte einbetten
    main: Konfiguration
    map: Karte konfigurieren
    reports: Meldungen konfigurieren
  tools:
    export: Exportieren
    import: Importieren
    main: Export
  users:
    all: Alle
    allSystem: Alle im System
    coordinators: Koordinator*innen
    main: Benutzer*innen
user:
  create:
    feedback:
      invalidFields: >-
        Bitte stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt
        sind. Leere Pflichtfelder sind rot markiert.
  registration:
    form:
      username:
        min:
          error: Der Benutzername muss mindestens 3 Zeichen enthalten
        required:
          error: Benutzername ist erforderlich
        taken:
          error: Dieser Benutzername ist bereits vergeben
  verification:
    feedback:
      deleteSuccess: Das Konto wurde gelöscht.
      success: >-
        Du hast deine E-Mail-Adresse bestätigt und und kannst dich jetzt
        einloggen.
users:
  coordinator:
    actions:
      newCoordinator:
        button: Neuer Koordinator
      newUser:
        button: Benutzer*in anlegen
      search:
        button: Suche Benutzer*in
    feedback:
      noSelectedCommune: Bitte wähle eine Kommune aus
      selectCommuneInstructions: |
        <div>
          Nutze die Dropdown-Liste oben rechts.
        </div>
    header:
      coordinatorLabel: Liste der Koordinator*innen
      label: Benutzer*innenwaltung
  table:
    filter:
      exact:
        label: Nur exakte Übereinstimmung
      filterBy:
        label: 'Suche in:'
        options:
          email: E-Mail-Adressen
          fistName: Vornamen
          lastName: Nachnamen
          username: Benutzernamen
        placeholder: Alle Felder
      keyword:
        label: 'Suchen:'
        placeholder: Keyword
      reset:
        tooltip: Suche zurücksetzen
views:
  dashboard:
    commune:
      settings:
        button: Kontaktinfo konfigurieren
        description: >-
          Hier können die Kontaktinformationen bearbeiten, die öffentlich auf
          der Karte einzusehen sind:
    title: 'Sie sind Koordinator*in der Kommune: {name}'
    users:
      button: Neue Accounts erstellen
      description: >-
        Hier können Sie neue Radelnde- und weitere Koordinator*innen-Accounts
        erstellen:
  license:
    action:
      renewButton: Buchung <strong>kostenpflichtig </strong> verlängern
