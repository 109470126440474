import { CommuneRegistrationUserDto, UserCommuneDto, UserCreationDto, UserProfileDto, UserProfileUpdateDto } from '@/typings/api';
import { CommuneDto } from '@/typings/commune';
import { UserDetails } from '@/services/UsersService';
import { DeepRequired } from '@/stores/GlobalStore';
import CommuneService from '@/services/CommuneService';

export type ROLES = 'radar-administrator' | 'radar-coordinator' | 'radar-reporter';

export type keycloakUserDetails = {
  username: string;
  fullName: string;
  id: string;
  roles: Record<ROLES, boolean>;
};

export class KeycloakUserClass implements keycloakUserDetails {
  fullName = '';
  id = '';
  username = '';

  roles = {
    'radar-administrator': false,
    'radar-reporter': false,
    'radar-coordinator': false,
  };

  constructor(data?: keycloakUserDetails) {
    if (data) {
      Object.assign(this, data);
    }
  }

  get isAdmin(): boolean {
    return this.roles['radar-administrator'];
  }

  get isCoordinator() {
    return this.roles['radar-coordinator'];
  }

  get isReporter() {
    return this.roles['radar-reporter'];
  }

  ownReportOrIsCommuneCoordinator({ userId, communeId }: { userId: string; communeId?: string }): boolean {
    const ownReport = userId === this.id;

    /*---------- TODO: Check if the user is coordinator for the current open report  commune   ----------*/
    return ownReport || this.isAdmin;
  }

  /*----------  Comment related  ----------*/
  showCommentActions({ ownerId }: { ownerId: string }): boolean {
    const ownComment = ownerId === this.id;

    /*----------  TODO: is the current user the commune coordinator can edit the comment ?  ----------*/

    return ownComment || this.isAdmin;
  }
}

export class UserCrud implements UserDetails {
  uuid = '';
  username = '';
  email = '';
  title = '';
  firstname = '';
  lastname = '';
  role = 3;

  password? = '';
  password_confirm? = '';
  salutation = 'MRS';
  telephone = '';

  communes: UserCommuneDto[] = [];
  coordinatedCommunes: UserCommuneDto[] = [];
  replyTo = false;

  isVerified = false;
  asCoordinatorIds: string = '';
  asReporterIds: string = '';

  /*----------  Internal use only  ----------*/
  originalUsername = '';

  get haveCoordinatedCommunes() {
    return !!this.coordinatedCommunes.length;
  }

  constructor(user?: UserDetails) {
    if (user) {
      Object.assign(this, user);
      this.originalUsername = user.username;
    }
  }

  setRole({ role }: { role: number }) {
    this.role = role;
  }
  setUserCommune(communeUuid: string) {
    this.role === 3 || !this.role ? (this.asReporterIds = communeUuid) : (this.asCoordinatorIds = communeUuid);
  }
  /*----------  Set coordinator ids and communes ids   ----------*/

  setCoordinatorCommunesAndCommunesIds(): void {
    if (this.coordinatedCommunes.length && this.role !== 7) {
      this.asReporterIds = this.coordinatedCommunes.map((i) => i.uuid || '')[0];
    } else {
      this.asReporterIds = this.communes.map((i) => i.uuid || '')[0];
    }
  }

  createUserData(): UserCreationDto & { telephone: string } {
    const { email, username, firstname, lastname, salutation, replyTo, password, role, telephone, isVerified } = this;
    return {
      email,
      username: username ? username : email,
      firstname,
      lastname,
      password: password ? password : '',
      role: role as unknown as string,
      salutation,
      replyTo,
      acceptTermsAndConditions: true,
      telephone,
      verified: isVerified,
    };
  }
}

export class UserProfileDtoClass implements UserProfileDto {
  username = '';
  firstname = '';
  lastname = '';
  email = '';

  password = '';
  password_confirm = '';
  salutation = '';
  telephone = '';
  termAndConditions = true;
  assignedCommunes: Required<UserCommuneDto>[] = [];

  coordinatedCommune = {
    name: '',
    uuid: '',
  };
  administrationPeriodExpired = false;
  /*----------  Internal coordinated commune details  ----------*/
  assignedCommuneUuid: string | null = null;
  originalUsername = '';

  constructor(user?: DeepRequired<UserProfileDto>) {
    if (user) {
      Object.assign(this, user);
      this.originalUsername = user.username || '';
    }
  }

  get IsCoordinatingCommune(): boolean {
    return !!this.coordinatedCommune.uuid;
  }
  get IsAssignedToACommune(): boolean {
    return !!this.assignedCommunes.length;
  }
  get IsCoordinatingCommuneAdministrationActive(): boolean {
    // Get coordinated commune  details and check if the commune still active
    return !!(this.coordinatedCommune.uuid && this.administrationPeriodExpired);
  }
  get getCoordinatedCommune(): string {
    return this.coordinatedCommune.uuid;
  }
  get getAssignedCommune() {
    return this.assignedCommunes[0];
  }
  get getCoordinatedCommuneName(): string {
    return this.coordinatedCommune.name || 'No coordinated commune';
  }
  get getRoleNumber(): number {
    return this.IsCoordinatingCommune ? 2 : 3;
  }

  get userInformationForCommuneRegistration(): CommuneRegistrationUserDto {
    const { username, email, firstname, lastname, salutation, telephone } = this;

    return {
      email,
      username,
      firstname,
      lastname,
      salutation,
    };
  }
  get userDisabledInputs(): string[] {
    const { username, email, firstname, lastname, salutation } = this;
    const disabled: string[] = [];
    username ? disabled.push('username') : null;
    email ? disabled.push('email') : null;
    firstname ? disabled.push('firstname') : null;
    lastname ? disabled.push('lastname') : null;
    salutation ? disabled.push('salutation') : null;
    return disabled;
  }

  isCoordinatorForTheCurrentCommune({ communeUuid }: { communeUuid: string }): boolean {
    return this.coordinatedCommune.uuid === communeUuid;
  }

  async fetchCoordinatedCommuneDetails() {
    if (this.IsCoordinatingCommune) {
      this.assignedCommuneUuid = this.getCoordinatedCommune || null;
    }
  }
  async fetchAssignedCommuneDetails() {
    if (this.IsAssignedToACommune) {
      this.assignedCommuneUuid = this.getAssignedCommune.uuid || null;
    }
  }
  async setAssignedCommune() {
    await this.fetchAssignedCommuneDetails();
    await this.fetchCoordinatedCommuneDetails();
  }

  UserProfileUpdate(): UserProfileUpdateDto {
    const { email, firstname, lastname, password, salutation, telephone, username } = this;

    const requestBody = {
      email,
      firstname,
      lastname,
      salutation,
      saludation: salutation,
      telephone,
      username,
    };

    if (password && password.length > 0 && password === this.password_confirm) {
      Object.assign(requestBody, { password });
    }
    return requestBody;
  }
}

export interface UserRegistrationProps {
  greeting: string;
  firstname: string;
  lastname: string;
  birthday: string;
  email: string;
  email_confirm: string;
  username: string;
  password: string;
  password_confirm: string;
  commune: string;
  accept_one: boolean;
  accept_two: boolean;
}
