<script lang="ts" setup>
/**
 *
 * Component Description:User date picker
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { IconLocal } from '@/Dashboard';
import { isAfter, isBefore, isSameSecond } from 'date-fns';
import { FormKitFrameworkContext } from '@formkit/core';
import { NDatePicker } from 'naive-ui';
import { watch, ref } from 'vue';
import { DatePickerStyleOverride } from '../composables';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

interface Props {
  context: FormKitFrameworkContext;
}

const props = defineProps<Props>();
const picked = ref();

watch(picked, (newValue) => {
  props.context.node.input(newValue);
});

watch(
  props,
  () => {
    props.context?.attrs?.disabledRange ? (disabledRange.value = props.context.attrs.disabledRange) : null;
  },
  {
    deep: true,
  },
);

const formatType = ref('MM/dd/yyyy HH:mm');
const defaultvalue = ref(props.context._value ? new Date(props.context._value).valueOf() : null);
const disabledRange = ref(props.context.attrs.disabledRange);

const isRangeDateDisabled = (ts: number, type: 'start' | 'end', range: [number, number] | null) => {
  if (type === 'start' && range !== null) {
    const rangeDisabled: [number, number] | null = disabledRange.value || null;

    if (!rangeDisabled || !disabledRange.value) return false;
    if (isSameSecond(ts, new Date(rangeDisabled[0]))) return false;

    return isBefore(ts, new Date(rangeDisabled[0])) || isAfter(ts, new Date(rangeDisabled[1]));
  }
  if (type === 'end' && range !== null) {
    const rangeDisabled: [number, number] | null = disabledRange.value || null;

    if (!rangeDisabled || !disabledRange.value) return false;

    return isBefore(ts, new Date(rangeDisabled[0])) || isAfter(ts, new Date(rangeDisabled[1]));
  }
  return false;
};

watch(
  locale,
  () => {
    locale.value === 'de' ? (formatType.value = 'dd.MM.yyyy') : (formatType.value = 'MM/dd/yyyy');
  },
  { immediate: true },
);
</script>

<template>
  <n-date-picker
    v-model:formatted-value="picked"
    class="rd-w-full"
    :type="props.context.attrs.datetype ? props.context.attrs.datetype : 'date'"
    clearable
    :default-value="props.context.attrs.defaultValue ? props.context.attrs.defaultValue : defaultvalue"
    :theme-overrides="DatePickerStyleOverride"
    :is-date-disabled="isRangeDateDisabled"
  >
    <template #separator>
      <span class="rd-opacity-40">{{ '-' }}</span>
    </template>
    <template #date-icon>
      <IconLocal :size="16" type="CalendarIcon" class="rd-opacity-40" />
    </template>
  </n-date-picker>
</template>
<style scoped></style>
