actions:
  cancel: Cancel
  comment:
    create:
      success:
        message: |-
          Your comment has been sent. 
          Thanks very much!
  filter:
    cancel: Cancel
    helper: You can only choose start or end date, but not both.
    save: Apply
  report:
    create:
      submit: submit a report
      success:
        message: |-
          Your message has been sent. 
          Thanks very much!
        report_created: Report generated successfully
    unMerge:
      success:
        SingleReport: Message processed successfully
        message: >-
          The merged messages have been separated and are displayed individually
          again. 

          Thank you!
    update:
      actions:
        close: Done
        update: Update
      attachment:
        formTitle: Report attachment
      attachmentActive: Message attachment (optional)
      confirmation:
        cancel: Abort
        content: Are you sure you want to remove the attachment?
        submit: Continue
        title: Please confirm the following action
      error:
        empty: Please select a new location
      locationLabel: Please choose a location on the map
      submit: save Changes
    visibility:
      singleSuccess: Report visibility successfully updated
      success: Visibility of reports updated successfully
  save: Save
admin:
  commune:
    email:
      send:
        success: |-
          Email has been sent. 
          Thanks very much!
        tooltip:
          send: Send Email
          sended: Email was already sent
    pending:
      feedback:
        approval:
          success: |-
            The booking data for this municipality was adopted. 
            All coordinators have been notified. 
            Thank you!
        content: |-
          Would you like to confirm the booking of this commune? 
          Existing data will be overwritten!
    registration:
      feedback:
        confirmation:
          error: Please update the username and all the required fields!
        delete:
          success: Pending community successfully deleted
        update:
          content: Want to commit your changes to the commune?
          success: |-
            Your commune changes have been applied. 
            Thanks very much!
    set:
      active:
        tooltip:
          body: View as coordinator of this municipality
    update:
      feedback:
        content: Want to commit your changes to the commune?
        success: |-
          Your commune changes have been applied. 
          Thanks very much!
        update:
          error: Please check that all information for the municipality is correct
  users:
    create:
      birthday: 'Year of birth:'
      birthdayHelp: |-
        We need your consent to our privacy policy. 
        You must be at least 16 years old to do this.
      checkbox1: >-
        I have read the <a href='https://www.radar-online.net/datenschutz'
        class='rd-text-yellow-300' target="_blank">Privacy Policy</a> and agree 

        that my details and data will be collected and stored for the purposes
        listed therein. 

        I am aware that I can revoke my consent at any time for the future by
        email to <a href="mailto:datenschutz&#64;radar-online.net"
        class='rd-text-yellow-300' >datenschutz&#64;radar-online.net</a>
      checkbox2: >-
        I hereby confirm that I am at least 16 years old or have the consent of
        a legal guardian to use the reporting platform RADar! 

        have.
      checkbox3: |-
        The submission of reports is voluntary. 
        The legal process is excluded.
      commune: 'Municipality (optional):'
      coordinatorIn: 'Coordinator in the following communities:'
      email: 'E-mail:'
      emailConfirmation: 'Repeat E-mail:'
      emailInformation: >-
        This email address is already registered. 

        Please enter a username if you want to register multiple accounts with
        the same email address.
      favoriteCommune: Home commune
      feedback:
        error: >-
          If your username or email address is not available, please change your
          username or email address.
      firstName: 'First name:'
      firstNamePlaceholder: example
      greeting: 'Salutation:'
      lastName: 'Last name:'
      password: 'Password:'
      passwordRepeat: 'Repeat password:'
      reporterIn: 'Municipality:'
      role: 'User Role:'
      success:
        message: The new user creation process was completed successfully.
      telephone: 'Telephone number:'
      title: 'Salutation:'
      userTitle: 'Title:'
      userTitlePlaceholder: (No)
      username: 'Username (freely selectable):'
      usernamePlaceholder: example
      verification: 'User status:'
    delete:
      confirmation:
        message: |-
          Do you want to delete the user data? 
          Messages remain anonymous.
      success:
        message: |-
          Users have been deleted. 
          Thank you!
    feedback:
      telephone: The phone number must be in the format x (xxx) xxx-xxxx.
    form:
      delete:
        label: Delete user
      description: All configurable fields are mandatory.
    roles:
      radar-administrator: Administration
      radar-coordinator: Coordination
      radar-reporter: Reporter
    title:
      create: Create user
      update: Edit profile
    update:
      success:
        message: The user's information has been successfully updated.
      title: Update user information
administrator:
  commune:
    actions:
      delete:
        content: Do you want to delete municipality {communeName}?
auth:
  errorView:
    back: Close
    subtitle: >-
      If you are already logged in or registered, please close this modal and
      try again.
    title: Please login or register
bottom:
  content:
    description: >-
      Municipalities can use RADar! 

      optionally offer their cyclists, most do this in connection with the
      campaign

      CITY CYCLING. 

      <br />

      Many municipalities have entered new territory with such a planning and
      public participation tool. 

      Should it e.g. 

      B. with

      If you think the processing of the reports is progressing too slowly, we
      ask you - and certainly also on behalf of you

      your municipality - for forbearance and patience.
    title: Last but not least
comment:
  new:
    attachmentButton: Save
    createButton: Create comment
    header: write a comment
    photo:
      headerLabel: Upload a photo
      label: Upload a photo
    placeholder: Comment...
    successClose: Update comment
  update:
    feedback:
      attachmentRemoveSuccess: Comment attachment deleted successfully
      attachmentSuccess: Updated comment attachment successfully
      success: Comment updated successfully
    header: Update comment
    photo:
      headerLabel: Update attachments
      label: Comment attachment
    updateButton: Update comment
commune:
  admin:
    create:
      content: Do you want to create a new commune?
      success: |-
        The new municipality has been registered. 
        Thanks very much!
    table:
      helpers:
        status:
          active: The municipality is in the reporting period
          inactive: The registration period is over, the administration period is not
          noValidLicesense: The administrative period has ended
    update:
      footer: >-
        Please note: the registration has not been successfully completed until
        you have registered on the website see corresponding note. You will also
        receive an automatic email confirmation afterwards.
  contract-update:
    url:
      invalid: URL slug invalid or already in use
  coordinator:
    dialog:
      setActive:
        confirm: Confirm
        content: >-
          Would you like to see the dashboard as a coordinator of this
          municipality?
        title: Are you sure?
    users:
      table:
        commune: commune
        email: E-mail
        firstname: First Name
        lastname: Surname
        role: role
        username: Username
  create:
    errors:
      invalid:
        json: GEOJSON not valid
    failed: The commune is still active
  current:
    commune: 'Municipality:'
  feedback:
    coordinator:
      required: At least one coordinating user is required
    coordinators:
      validation: |-
        Please ensure that all required fields are filled out. 
        Empty mandatory fields are marked in red.
    error:
      generalError: |-
        An error has occurred. 
        Please try again later.
      invalidFields: Please tick all required fields
      reporting: |-
        Please check the reporting period:

        {reportEnd} must be before {adminEnd}
    field:
      mandatory: Mandatory information
    registration:
      content: Are you sure you want to purchase this license?
      success: >-
        Your booking has been received and will usually be activated within 72
        hours. 

        "All coordinators will be notified of the activation"
    user:
      email:
        required: Mandatory information
      institution:
        required: Mandatory information
      telephone:
        required: Mandatory information
  filter:
    byDate:
      endPlaceholder: To
      label: 'Filter by date: '
      startPlaceholder: From
    byYear:
      label: 'Filter by year:'
    byuuid:
      label: 'Countys :'
      placeholder: Search for a municipality
    export:
      endPlaceholder: end date
      startPlaceholder: start date
      years: 'Filter by date:'
  form:
    approve:
      child:
        header:
          helper: >-
            Select one or more communities to be assigned to the children's
            communities.
          label: 'Assigned municipalities (e.g. districts):'
      geojson:
        feedback:
          invalid: GEOJSON not valid
        header:
          helper: >-
            Entry is only required if no district has yet been saved or the
            saved district is incorrect.
          label: GEOJSON
    child:
      search:
        label: 'View municipal child relationships:'
    create:
      STADTRADELN:
        license:
          label: 'CITY CYCLING participation (cost-reducing):'
          option:
            radar: >-
              The municipality will not participate in <a
              href='https://www.stadtradeln.de' target="_blank"
              class='rd-text-yellow-300'>CITY CYCLING</a> during the license
              period.
            sr: >-
              I assure that the municipality will participate in <a
              href='https://www.stadtradeln.de' target="_blank"
              class='rd-text-yellow-300'>CITY CYCLING</a> in all years of the
              license period.
      adminTimeRange:
        label: 'Management period:'
      article:
        label: 'Article:'
      billing:
        contact:
          label: Deviating from registering person
      confirmation:
        confidentially: >-
          We hereby assure that we treat the contact information of cyclists
          within our community confidentially, use it exclusively in connection
          with the CITY CYCLING campaign and do not pass the data on to third
          parties.*
        context: >-
          We are aware that there is a joint responsibility between the
          participating local authority and the Climate Alliance in accordance
          with Art. 26 GDPR with regard to the processing of personal data as
          part of the implementation of the CITY CYCLING campaign. 

          We hereby agree to <a href='/data/pdf?commune={commune}'
          target="_blank" class='rd-text-yellow-300'>this agreement </a>.*
        feesCheck: >-
          We accept the prices listed in <a
          href='https://www.radar-online.net/lizenzen'  target='_blank'
          class='rd-text-yellow-300'>Royalty Fees</a>.*
        municipalityCheck: >-
          As a municipality (city, municipality or district/region), we acquire
          a license for the reporting platform RADar! and hereby register with
          binding effect. The <a href='https://www.radar-online.net/agb'
          class='rd-text-yellow-300' target='_blank'> GTC campaign CITY CYCLING
          and reporting platform RADar!</a> apply additionally.*
        privacyCheck: >-
          We have taken note of the <a
          href='https://www.radar-online.net/datenschutz' target="_blank"
          class='rd-text-yellow-300'> data protection information</a> and agree
          that our information and data will be collected and stored for the
          purposes listed therein. 

          We are aware that we can revoke our consent at any time for the future
          by sending an email to <a href='mailto:datenschutz{'@'}stadtradeln.de'
          class='rd-text-yellow-300'> datenschutz{'@'}stadtradeln.de</a>.*
        right: We waive our right of withdrawal.*
      country:
        label: 'Country*:'
        placeHolder: '-- Please choose --'
      description: >-
        Can only be filled out by the municipal side/administration or by
        persons commissioned to do so!
      designation:
        label: 'Designation:'
      footer:
        label: >-
          <span class='rd-text-yellow-300'>Please note:</span> Registration has
          not been successfully completed until you see a corresponding message
          on the website. 

          You will also receive an automatic email confirmation afterwards.
      license:
        helper: >-
          Administrative area for the coordinators in the municipality will be
          closed 1 or 3 years after the first day on which cyclists can
          register.
        label: license
        option:
          free: Free use
          helper: >-
            If the current administration period has not expired, you will lose
            coordination access until the first day of the new reporting period.
          one: >-
            1 year (with a maximum of 1 year reporting period) - PAID, see <a
            href='https://www.radar-online.net/lizenzen' target="_blank"
            class='rd-text-yellow-300 rd-z-10 rd-relative'>License Fees</a>
          two: >-
            3 years (with a maximum of 3 years reporting period) - PAID, see <a
            href='https://www.radar-online.net/lizenzen' target="_blank"
            class='rd-text-yellow-300 rd-z-10 rd-relative'>License Fees</a>
      location:
        label: 'Location of the center of the municipality*:'
        latitude: latitude
        longitude: longitude
      member:
        label: 'Memberships:'
        option:
          agfk_agfs: >-
            Member of the AGFK Baden-Württemberg, AGFK, Bayern, AGFK BB, AGFK
            Niedersachsen/Bremen, AGFK-TH or AGFS NRW
          climate_alliance: Member of the Climate Alliance
      name:
        helper: |-
          Correct designation not included in the preselection above? 
          Just add here:
        inputManualPlaceholder: E.g. "The"
        inputManualRegionPlaceholder: |-
          e.g. 
          e.g. city, municipality, county/region
        label: 'Designation*:'
      nameManual:
        helper: As it should appear later on the page (without a label!)
        label: 'Municipality name*:'
        placeHolder: Frankfurt
      noInTheList:
        label: >-
          If your municipality is not listed, please contact us so we can add
          your municipality.
      officialName:
        label: 'Official name:'
        placeholder: ...
      personAsCoordinator:
        label: I need an account to coordinate RADar!
      platform:
        label: 'Reporting platform for cycling:'
        option:
          MPR_FORWARD: |-
            Radar! 
            with forwarding to the reporting platform for cycling: <br/>

            Messages are only in RADar! 
            processed and handed over to the MPR as a copy (read-only). 
            Changes introduced in RADar! 
            are made are transmitted to the MPR.
          MPR_ONLY: |-
            Reporting platform for cycling: <br/>

            Reports are completely transferred to the MPR and processed there. 
            In RADar! 
            remains a copy (read-only) of the message, but in RADar! 
            cannot be edited. 
            Changes made in the MPR are reflected in RADar! 
            transmitted.
          NONE: >-
            Municipalities in Hesse can use both the <a
            href="https://meldeplattform-radverkehr.de"
            class="rd-text-yellow-300" target="_blank"> cycling reporting
            platform </a> and <a href="https://www.radar-online.net" class="rd-text-yellow-300"
            target="_blank"> RADar!</a>. 
            <a href="https://meldeplattform-radverkehr.de/kommunen?x=474387.64&y=5552554.46&z=10"
            class="rd-text-yellow-300" target="_blank">Here</a> you can see
            whether your municipality already has the cycling reporting
            platform uses.
            <br/>


            <br/>


            All about RADar! 

            Reports entered, including the contact details of the city cycling
            coordinators, are automatically transmitted to the cycling reporting
            platform. 

            Subsequent changes to reports in RADar! 

            are also transmitted to the cycling reporting platform. 

            Changes made to the cycling reporting platform will not be sent to
            RADar! 

            transmitted


            <br/>
            <br/>
            If you decide to use both reporting systems, please coordinate with
            the relevant processors in your municipality.
            <br/>
            <br/>
            If you do not know who is responsible for the cycling reporting
            platform in your municipality, please contact ivm GmbH (<a
            href="to:meldeplattform{'@'}ivm-rheinmain.de"
            class="rd-text-yellow-300" target="_blank">
            reporting platform{'@'}ivm-rheinmain.de;</a> 069-660759-0).
            <br/>
            <br/>
            The state of Hesse covers all costs for year-round use of the
            state-wide cycling reporting platform. 

            The nationwide possibility of use enables cyclists in Hesse to
            submit reports regardless of municipal or district boundaries.
      population:
        helper: Main residence only
        label: 'Population*:'
        placeHolder: Please be as precise as possible
      reportingPeriod:
        helper: |-
          You can choose any day of the year to start. 
          The end is at the latest one year or three years later, minus one day
      reportingTimeRange:
        from: From
        helper: >-
          When you select the location, longitude and latitude are automatically
          displayed. 

          You can change them manually.
        label: 'Reporting period in which cyclists can submit reports*:'
        to: Until
      rs:
        helper: Manual correction if necessary here
        label: 'Regional key (RS)*:'
      sameNameCountryOrRegion:
        description: |-
          When registering for teams/cyclists, e.g. 
          B. "Bamberg, district" and "Bamberg, city"
        label: 'City and district/region of the same name available:'
      search:
        label: 'Search municipality:'
      state:
        label: 'Federal State*:'
        placeHolder: '-- Please choose --'
      timeZone:
        label: Time Zone
      title: Registration for municipalities
      usable:
        label: 'Test municipality:'
    licenseRenewal:
      submit: Book with costs
      title: Booking extension
    parent:
      search:
        label: 'Commune parent relationships:'
    pending:
      title: 'Commune:'
    update:
      actions:
        confirm: Confirm booking
        delete: Delete
        save: Save
      title: 'Edit municipality:'
  licence:
    bad-period: The reporting period must be between the administrative period.
  menu:
    selectedOverview: 'Reports date:'
  overlay:
    select:
      description: >-
        Select a commune from the commune list to view all the details and
        reports from  the selected commune.
  search:
    btn: Search
    placeHolder: Search municipality
  select:
    commune:
      label: 'Search municipality:'
    status:
      placeholder: current news
  table:
    header:
      action:
        new: Create a new municipality
      form:
        filter:
          dateEnd: 'Administration End:'
          dateStart: 'Administration Start:'
          helper:
            label: 'Select start or end date of administration period:'
      labels:
        actions: Actions
        coordinator: Coordinator
        end: To
        id: ID
        license: license
        managementPeriod: Management Period
        name: Name
        reportPeriod: Report Period
        start: From
      pending: Municipalities to be activated
      pendingHelper: >-
        All new bookings from existing or new municipalities that have not yet
        been transferred to the system are listed here. 

        Check the booking (in particular whether the municipality and/or
        coordinators already exist) and then click on "Activate booking" to
        initiate the municipality synchronization, create new coordinators and
        all old and new coordinators of the municipality via the 

        to inform activation. 

        To save an intermediate result, you can click on Save. 

        Regional keys can currently only be created in SR.
      pendingSubTitle: All changes only go live after this step!
      title: Overview list of all municipalities
      totalCountLabel: Show entries per page
      totalLabel: 'Show entries  '
  update-contract:
    failed: Invalid reporting period and management period specified
configuration:
  confirmation:
    content: Do you want to apply the changes to this email template?
  email:
    address:
      label: 'Address:'
    body:
      extra: 'Additions:'
      label: 'Text:'
    comment:
      subtitle: >-
        Change the text that the reporter receives when a comment is added to
        the report.
      title: Comment
    commnet:
      subtitle: >-
        Change the text that the reporter receives when a comment is added to
        the report.
      title: comment
    confirmation:
      subtitle: >-
        Change the text that the reporter receives as confirmation of receipt of
        the report.
      title: Acknowledgment of receipt
    delete:
      subtitle: Change the text that the reporter receives when the report is deleted.
      title: Delete
    greeting:
      label: 'Greeting:'
    info:
      body: |-
        Optionally, you can add text here that is aimed at cyclists. 
        Otherwise the field remains empty.
      replyTo: >-
        Any replies from the reporting person to automatically sent E-Mails will
        be sent to all reply-to addresses.
      text: Leave the text passages with
    note:
      subtitle: >-
        Change the text that the reporter receives when an annotation is added
        to the report.
      title: Notes
    position:
      subtitle: >-
        Change the text that the reporter receives when the position of the
        report is changed.
      title: Position
    replyTo:
      subtitle: Overview of all users who are marked as reply-to addresses.
      title: Reply-to addresses
    reset:
      label: Reset email template
    status:
      subtitle: >-
        Change the text that the reporter receives when the status of the report
        - e.g. 

        B. from "unprocessed" to "in process" - is changed.
      title: Status change
    subject:
      label: 'Subject:'
  feedback:
    replySuccess: |-
      Your changes in the reply-to list have been applied. 
      Thanks very much!
    resetSuccess: |-
      Your email template has been reset. 
      Thanks very much!
    success: |-
      Your changes in the email template have been applied. 
      Thanks very much!
  map:
    feedback:
      confirmation: Do you want to apply your changes to the contact information?
      email:
        error: Enter a valid email address
      name:
        error: Name is required
      phone:
        error: Provide a valid phone number
      success: |-
        Your changes in contact information have been applied. 
        Thanks very much!
    information:
      email:
        label: 'E-mail address*:'
        placeHolder: email.com
      freeText:
        help: >-
          Here you can give users more information about who to contact in
          specific cases, or provide additional information on processing times,
          etc.
        label: Additional information or notes (optional free text field)
        placeHolder: freetext...
      name:
        label: 'Name or institution*:'
        placeHolder: Surname ...
      phone:
        label: 'Telephone number*:'
        placeHolder: 00-000-000-0000
      title:
        label: Contact information
    menu:
      comment: Comment
      confirmation: Confirmation
      contactInformation: contact information
      contactInformationHelper: Set/edit contact information here that is publicly viewable on the map.
      delete: Delete
      embeddedInformation: map embedding
      embeddedInformationComponent: map embedding
      embeddedInformationHelper: >-
        If you use the public RADar! map e.g. 

        B. want to embed on your municipality's website, you can do this with
        the following HTML code.
      note: Note
      position: Position
      replyTo: Reply-to addresses
      statusChange: Status
  replyTo:
    confirmation:
      content: Would you like to apply your changes to the reply-to list?
  reset:
    content: Do you want to reset the email template?
coordinator:
  dashboard:
    mobile:
      dashboard: Dashboard
      title: The dashboard is optimized for desktop and tablet.
  export:
    help:
      label: You can use the filter to make a more precise selection.
  feedback:
    deleteAll:
      error: Can't delete all selected reports, Please try again
      success: |-
        The selected message(s) have been deleted. 
        Thanks very much!
    unMerge:
      error: 'Revert duplicted reports action fail, Please try again. '
      success: All duplicated reports,reverted to the original state.
    updateAll:
      error: Can't update all seleceted reports, please try again
      success: |-
        The status of all selected messages has been edited. 
        Thank you!
    users:
      coordinator:
        error: The user's can't be set as coordinator please try again
        success: The user role has been changed.
      delete:
        success: |-
          Users have been deleted. 
          Thank you!
      reporters:
        error: Can't add Reporters to the commune,Please try again
        success: The user role has been changed.
  fistTimeLogin:
    form:
      checkbox1: >-
        I have read the <a href='https://www.radar-online.net/datenschutz'
        class="rd-text-yellow-300">Privacy Policy</a> and agree that my
        information and 

        Data is collected and stored for the purposes listed therein. 

        I am aware that I can withdraw my consent at any time for the future by
        email to <a href="mailto:datenschutz{'@'}radar-online.net"
        class="rd-text-yellow-300">datenschutz{ 

        '@'}radar-online.net</a> can revoke.
      checkbox2: >-
        I am aware that there is joint responsibility between the participating
        local authority and the Climate Alliance in accordance with Art. 

        I <a class='rd-text-yellow-300' > hereby agree to this Agreement </a>.
      checkbox3: >-
        I hereby certify that I will keep the contact information of cyclists
        within my community confidential, only in connection with the use of
        RADar! 

        use and do not pass the data on to third parties.
      checkbox4: >-
        I am aware that the participating municipalities assume responsibility
        for local use and all associated measures and events and that Climate
        Alliance only provides the technical infrastructure and the accompanying
        materials.
    subtitle: |-
      Before you RADAr! 
      use, we need your consent:
    title: Welcome as a coordinator
  licenseRenovationCTA:
    title: |-
      Your municipality's administrative period has expired. 
      Please extend your license and then wait for activation. 
      Free licenses only via <a href='https://www.stadtradeln.de'
            
      target='_blank' class="rd-text-yellow-300">www.stadtradeln.de</a>.
  overlay:
    CTA: Extend booking
    footer: >-
      Free licenses only via <a href='https://www.stadtradeln.de'
      target='_blank' class="rd-text-yellow-300">www.stadtradeln.de</a>.
    noCommuneTitle: Your municipality's administrative period has expired.
    subTitle: Please extend your license and then wait for activation.
    title: The administration period of your municipality has expired.
  reportEdit:
    history:
      table:
        DUPLICATE:
          endLabel: Separated
          label: Merge/Un-Merge
        FORWARD:
          label: 'Forward Report:'
        STATUS:
          label: 'Status:'
        date: Date
        modification: Modification
        username: User
      title:
        label: Processing history of the notification
  users:
    actions:
      delete:
        content: |-
          Do you want to delete the selected users? 
          Messages remain anonymous.
        title: Are you absolutely sure?
    userDetails:
      form:
        coordinatorOn: 'Coodinator in the fallowing communes:'
        reporterOn: 'Reporter role in the fallowing communes:'
dashboard:
  breadcrumb:
    admin: admin
    allUsers: All
    communes: commune
    coordinators: coordinators
    dashboard: Dashboard
    export: Export
    homePage: Home
    license: your license
    myReports: My Reports
    pendingCommune: Preliminary municipal information
    pendingCommunes: Approval pending
    report-status: By Status
    reportMerge: Selected
    reports: All
    reportsList: Reports
    setting: configuration
    setting-commune: commune
    setting-contact: Configure contact info
    setting-email: Configure emails
    setting-embedded: Embed map
    setting-map: Map
    tools: tools
    users: Users
    usersList: User Management
  reportsView:
    DONE:
      title: Complete reports
    IN_PROGRESS:
      title: In progress reports
    NEUTRAL:
      title: Neutral reports
    NEW:
      title: Unprocessed reports
    POSITIVE:
      title: Positive reports
    all:
      title: All reports
email:
  verification:
    feedback:
      deleting: Loading...
      invalidEmail: Please enter a valid email address or register.
      invalidToken: >-
        The verification token is invalid, please request a new verification
        link.
      loading: Email verification in progress...
      sendEmail: >-
        We have sent a message to your registered email address {email}. 

        Please click on the green button in this e-mail to complete your
        registration.
export:
  admin:
    commune:
      header: Export Municipality
    report:
      header: Report data export
  download:
    error: Please check the selected date
    success: Export downloaded successfully
filter:
  actions:
    DATAEXPORT: Export notifications
    FILTERRESET: Reset filter
    close: close filters
  card:
    info: The number of messages displayed can be limited using the filters.
    title: Filter
  confirmation:
    message: Action will reset all the filtering, plase confirm action.
    reset:
      title: Confirmation
  menu:
    filter:
      headers:
        REPORT_OTHERS: Others
        REPORT_REASONS: Report reason
        REPORT_STATUS: Processing status
    status:
      DONE: Done
      IN_PROGRESS: In Progress
      MEINE_MELDUNG: my Report
      NEUTRAL: Neutral Report
      NEW: Unedited
      POSITIVE: Positive Report
  other:
    address:
      label: Containing Text
      placeHolder: Please enter text
      title: Address
    empty: ' '
    reportId:
      label: ID
      placeHolder: Please enter ID
      title: 'Report ID:'
    reportPeriod:
      format: dd/MM/yyyy
      label: From
      labelTo: To
      placeHolder: 08/10/2022
      title: Reporting period
    reporterBy:
      label: First name
      labelEmail: E-mail
      labelUuId: 'UUID:'
      lastNameLabel: Username
      lastNamePlaceHolder: Please enter last name
      lastNameTitle: ' '
      placeHolder: Please enter first name
      placeHolderEmail: Please enter email
      placeHolderUuId: 0000-22132
      title: Reported by
      usernameLabel: 'Username:'
      usernamePlaceHolder: Please enter username
  reasons:
    groupLabel:
      ABSTELLANLAGEN: Storage facilities ({selected} from {total} selected)
      BEHINDERUNG: Disability ({selected} selected from {total})
      LICHTSIGNALANLAGE_(AMPEL): Traffic signal system (traffic light) ({selected} selected from {total})
      OBERFLÄCHE: Surface ({selected} out of {total} selected)
      POSITIVE_MELDUNG: Positive message ({selected} selected from {total})
      RADWEGWEISUNG: Cycle route ({selected} out of {total})
      SONSTIGE_HINWEISE: Other notes ({selected} selected from {total})
      STRASSENBAUARBEITEN: Road Works ({selected} selected from {total})
      VERKEHRSBESCHILDERUNG/MARKIERUNG/BELEUCHTUNG: Traffic signage/marking/lighting ({selected} out of {total})
      VERKEHRSFÜHRUNG: Routing ({selected} selected from {total})
filtering:
  confirmation:
    'no': Cancel
    'yes': Reset
footer:
  content:
    copyright: |-
      Copyright {year} Climate Alliance. 
      All rights reserved.
    social:
      label: Social Media CITY CYCLING
      mediaOptions:
        facebook:
          icon: Facebook
          link: https://www.facebook.com/
        instagram:
          icon: Instagram
          link: https://www.instagram.com/
        tumblr:
          icon: Tumblr
          link: https://www.tumblr.com/
        twitter:
          icon: Twitter
          link: https://www.twitter.com/
        youtube:
          icon: Youtube
          link: https://www.youtube.com/
    toolLabel: |-
      Radar! 
      is a tool of Climate Alliance.
global:
  action:
    confirmation:
      title:
        sie: Are you sure?
    home: To the map view
  actions:
    backToMap: To the map view
    cancel: Cancel
    close: Close
    closeModal: Close
    continue: Confirm
    coordinator:
      merge:
        label: Merge
        unMerge: Separate
    copy: copy code
    copyDone: copied to the clipboard
    create: Submit
    createUser: Save
    delete: Delete
    deleteCoordinators: Remove as coordinator
    deleteReporters: Remove as reporter
    download: Download
    fileType:
      all: Both
      label: Choose file type
      shp: Shapefile
      title: Export messages
      xlsx: Excel
    filter: filter
    fordward: Forward onto
    goBack: Back
    hover:
      hideOnMap: Hide
    login: Login
    merge: Merge
    newReport:
      helper:
        label: 'There are two ways to determine the exact location of your report:'
        step1: Directly in the map
        step2: By entering the address
    notVerified: Pending Verification
    ok: ok
    register: Register
    reset: Reset to default
    save: Save
    search: search term
    serch: Search
    setAsCoordinator: Change Role to Coordination
    setAsReporters: Change role to reporter
    status: Status
    toggleVisibility: Toggle visibility
    undo:
      label: Reset
    update: Save
    verified: checked
  collapseInfo: How it works
  commune:
    country:
      empty: No data
    create:
      label: Register for a fee
    inactive:
      feedback: |-
        The reporting period has ended. 
        You can only create comments.
      feedback-admin: The reporting period has ended.
    licenseExtend:
      label: Book with costs
    search:
      empty: Please enter a search query to search or change your search query.
    tooltip: Selected municipality
  data:
    protection:
      info: >-
        Data protection information: When booking or logging in for the first
        time, you have <a
                      
        href='/data/pdf?commune={commune}' target="_blank"
                      
        class='rd-text-yellow-300 rd-z-10 rd-relative'>agreed to this
        agreement</a> on joint responsibility according to Art.26 GDPR.
  help:
    info: Info
    label: More info
  helper:
    anon:
      reports: ' It is up to the commune to decide whether all reports are shown on the map.'
  infoLabel: More info
  information:
    commune: >-
      If you do not select a municipality, the data from all municipalities will
      be exported.
    export: >-
      If you do not select a date or municipality, the entire system data will
      be exported.
  labels:
    icons:
      collapsed: Show legend
      open: Hide legend
  menu:
    contact:
      title: Contact
  pagination:
    label: '{size}/Page'
    total: Total {total}
  report:
    edit:
      noMap: Please open the map
      noMapContent: Please open the map, before editing a report
    view:
      multiple:
        communes:
          label: >-
            This report was made in a location where the boundaries of several
            municipalities overlap. 

            Due to different configuration settings, the view of the message may
            differ in the municipalities. 

            Please select a municipality:
          title: Reporting is in several municipalities
  search:
    action:
      go: Go
    address:
      placeholder: Browse address
  table:
    empty:
      communesList: Please enter a search term to check available communities.
      description: There are no reports with this status.
      label: No data
    none: (Empty)
    pagination:
      total: 'Total Reports: {total}'
      totalCommune: 'Municipalities total: {total}'
      usersTotal: ' Total users: {total}'
  upload:
    helper:
      label: add a picture
  user:
    options:
      salutation:
        MISTER: Mister
        MRS: Woman
        none: (Not specified)
header:
  callToActionText: How it works!
  description: >-
    Your municipality invites you to improve the cycling infrastructure. 

    Simply enter a message on the map or use <a
    href='https://www.stadtradeln.de' target="_blank" class='rd-text-yellow-300
    rd-font-medium'>CITY CYCLING while you're out and about 

    -App</a> or the <a href='https://www.radar-online.net/app' target="_blank"
    class='rd-text-yellow-300 rd-font-medium'>RADar 

    ! app</a>. 

    You can find out more by clicking on “How it works!”
  menu:
    dashboard: Dashboard
    logout: logout
    map: Map view
    profile: Profile
    register: Registration
  title: Together towards a better cycling infrastructure
icon:
  status:
    DONE: completed message
    IN_PROGRESS: Message is in progress
    MEINE: my reports
    NEUTRAL: neutral message
    NEW: unedited message
    POSITIVE: positive message
iframe:
  share:
    contact:
      description: >-
        Registered users have the option here to view contact information for
        contact persons.


        <br />
                    
        <br />
                    
        More information at <a href="www.radar-online.net"
        class="rd-text-yellow-300">www.radar-online.net </a>
    language:
      de: German
      en: English
      label: 'Choose the language:'
    overlay:
      description: >-
        Registered users can enter new reports here.
                    
        <br />
                    
        <br />
                    
        More information at <a href="www.radar-online.net"
        class="rd-text-yellow-300">www.radar-online.net</a>
      title: You are not logged in!
    preview:
      desktop: Desktop
      label: 'Preview view:'
      mobile: Mobile
    theme:
      dark: Dark
      label: 'Choose the preset color scheme:'
      light: Bright
labels:
  name: Name
license:
  form:
    administration:
      end:
        inputLabel: End of the administration period
      start:
        header: Administrative period
        inputLabel: Beginning of administration period
        label: administrative period
        labelHelper: constantly
    feedback:
      confirmation:
        content: Would you like to accept the new reporting period?
    reportingPeriod:
      end:
        inputLabel: End of reporting period
      header:
        label: Reporting period
      start:
        inputLabel: Start of reporting period
        label: Reporting period
        labelHelper: Completed
      startEnd:
        label: 'Change reporting period:'
    status:
      active: Constantly
      inactive: Completed
  header:
    label: Your RADar! license
    renew:
      helper: >-
        You use RADar! 

        for free. 

        If you want to change one or both periods, you need a paid license. 

        Find out more at <a href='https://www.radar-online.net' target="_blank"
        class='rd-text-yellow-300'>www.radar-online.net</a> 

        our licenses and fees.
      helper2: >-
        Find out more at <a href='https://www.radar-online.net' target="_blank"
        class='rd-text-yellow-300'>www.radar-online.net</a> 

        our licenses and fees.
      label: Extend booking
  update:
    feedback:
      success: |-
        The new reporting period has been adopted. 
        Thank you
login:
  feedback:
    login:
      error: |-
        The username or password you entered is incorrect. 
        Please check your information and try again.
      username:
        noWhiteSpace: No spaces allowed
        required: Username is required
  modal:
    actions:
      passwordReset: Forgot your login?
      register: Register
    form:
      fromSR:
        label: SR's account
      password:
        placeholder: Password
      username:
        placeholder: Username or e-mail
    header:
      label: login
maintenance:
  view:
    description: |-
      Radar! 
      is unavailable due to scheduled maintenance. 
      <br/>

      We'll be back soon with new features and improvements.
    title: Maintenance work
    url: >-
      https://www.radar-online.net/fileadmin/_processed_/9/4/csm_grafik-guter-ueberblick-durch-pins_ab725d96bf.png
map:
  coordinator:
    actions:
      showMap:
        closeLabel: Close Map
        label: Open Map
  errors:
    attachment:
      attachmentIdMissing: Attachment file not found
      deleteSuccess: Attachment removed from report
      filesize: |-
        Add images (max 3MB/file). 
        Please make sure that no people are recognizable in the pictures.
      genericError: Report attachment cannot be updated
      maxLimit: A maximum of 3 images can be attached.
      reportIdMissing: Report attachment updated successfully
      success: Report attachment updated successfully
    create:
      fail: Unable to create report this time, try again
      missingRequiredFields: Please fill out all required fields to create a new report
      success: Report created successfully
  popup:
    commune:
      inactive:
        description: Please contact the commune for more detail
        title: The commune is currently inactive
    content:
      attachment: 'Attachments:'
      comment: 'Description:'
      commentsAdminLabel: 'Commune Admins notes:'
      commentsLabel: 'Comments: '
      coordinator:
        title: Municipality note
      formattedAddress: 'Nearest address:'
      id: 'ID :'
      location: 'Geo position:'
      reporter:
        email: 'E-mail:'
        firstName: 'FirstName:'
        lastName: 'LastName:'
        role: 'Role:'
        title: Reporter Details
        username: 'Username:'
        uuid: 'UuId:'
      status: 'Processing status:'
      subTitle: 'General Information:'
      timeStamp: 'Time Stamp :'
      title: 'Report:'
      workDuration: Processing time
    likes: '{likes} confirmations'
  useUserLocation:
    label: Use my location
menu:
  administration: Administration
  config: Configuration
  contact: Contact
  dashboard: Dashboard
  dataExport: Export data
  filter: Filter
  mobile:
    profile: Profile
  newReport: New Report
  ownReport: My reports
  publicMap: Public Maps
  report: Reports
  reports:
    DONE: Done ({view}/{total})
    ICONS: Show legends
    IN_PROGRESS: In progress ({view}/{total})
    NEUTRAL: Neutral Reports ({view}/{total})
    NEW: Unedited ({view}/{total})
    POSITIVE: Positive reports ({view}/{total})
    POSITIVECONTENT: |-
      <div class='map-wrapper-text-sm'><strong>
          
      @:{'menu.reports.POSITIVECONTENTTITLE'}

      </strong>

      </div>

      <div> </div>
    POSITIVECONTENTTITLE: 'Total: {view} of {total} reports displayed'
mobile:
  reports:
    table:
      attachmentLabel: Pictures
      idLabel: 'ID:'
      reasonLabel: 'Reason for notification:'
profile:
  update:
    user:
      profile:
        bad-commune: unable to find commune with given uuid
        success: |-
          The changes have been applied. 
          Thanks very much!
  user:
    action:
      update:
        label: Edit
    back:
      confirmation: |-
        Are you sure? 
        No changes will be applied.
    commune:
      label: 'Municipality:'
    delete:
      confirmation: Do you really want to delete your account?
      label: Delete account?
    feedback:
      deleteSuccess: >-
        Your account will be deleted with all personal data. 

        Your reports will remain anonymous so that they contribute to improving
        the cycling infrastructure.
    modal:
      title: Edit profile
    role:
      label: 'User role:'
    title: edit profile
    update:
      confirmation: Do you want to change your profile?
public:
  anonUser:
    contact:
      hint: >-
        Please register or log in to create reports and view contact
        information.
  information:
    card:
      content: >-
        Municipalities decide for themselves when reports can be submitted and
        what reasons for reporting are available to them. 

        They do this according to their options. 

        The municipality itself can best answer your questions.
      title: 'PLEASE NOTE:'
    list:
      '1': >-
        Click on "new message". 

        Then put a marker/pin on the spot on the map to suggest an improvement
        there - alternatively enter a message about the nearest address (if
        possible with house number) in the address field
      '2': >-
        Under "Message Description/Message Reason" select the reason via a
        category.
      '3': >-
        Additional information can be added as a note or more detailed
        description of the message and/or an image can be uploaded.
      '4': >-
        Click on "Send message" - that's it! 

        Your report will automatically be forwarded to the competent authority
        in your municipality, which will take care of the matter and contact you
        if necessary.
    question: >-
      Reports are automatically communicated to the responsible authority in the
      municipality, which takes care of the matter and informs you via RADar! 

      will inform you about the processing steps. 

      If you are registered for this municipality, it can also contact you using
      your email address
    title: How it works!
  newComment:
    hint: Please register or log in to create reports.
  newReport:
    hint: Please register or log in to create reports.
registration:
  actions:
    resendEmail: Resend email link
  commune:
    addCoordinator:
      label: Add coordinators
    feedback:
      success: |-
        New commune was created. 
        Thanks very much!
    selectCommune:
      btn: extend registration
      label: 'Choose municipality:'
    step2:
      title: Required fields are marked with an *.
    step3:
      title: For the municipality registering person
    step4:
      title: Contact details of (additional) local coordinators
    step5:
      title: Invoice recipient
    step6:
      title: 'Only for districts/regions: Register associated cities/communities'
    user:
      address:
        label: 'Address*:'
        placeHolder: ...
      addressExtend:
        label: 'Address supplement:'
        placeHolder: ...
      department:
        label: 'Department/Department:'
        placeHolder: ...
      email:
        label: 'E-mail*:'
        placeHolder: email{'@'}email.example
      firstName:
        label: 'First Name*:'
        placeHolder: First name...
      function:
        label: 'Function:'
        placeHolder: ...
      institution:
        helper: if not working for the municipality
        label: 'Institution (only if not working for the municipality):'
        placeHolder: |-
          e.g. 
          Climate Alliance e.V.
      lastName:
        label: 'Last name*:'
        placeHolder: Last name...
      password:
        label: 'Password:'
        placeHolder: Password...
      place:
        label: 'ZIP/City*:'
        placeHolder: location
        zipPlaceHolder: Postcode
      pronouns:
        label: 'Salutation*:'
        placeHolder: '-- Please choose --'
      telephone:
        PlaceHolder: Phone Number
        areaPlaceHolder: e.g. +49
        label: 'Phone*:'
        midPlaceHolder: area code (without 0)
      title:
        label: 'Title:'
        placeHolder: (No)
      username:
        label: 'User name:'
        placeHolder: Username...
  modal:
    description: Please enter your data and then click on “Register”.
    feedback:
      content: >-
        <p>We have sent a message to your registered email address <span
        class='rd-text-yellow-300'>{userEmail}</span>. 

        Please click on the "Confirm user account" link in this email to
        complete your registration.</p> <p class="rd-mt-10">


        In individual cases, shipping can take up to 6 hours. 

        Please also check spam folders. 

        If you do not receive an email from us, contact your local coordination
        or <br/> <a href="mailto:info{'@'}radar-online.net"
        class="rd-text-yellow- 

        300">info{'@'}radar-online.net</a>.</p>
      footer: >-
        <h2 class="rd-text-xl">The link is valid for 24 hours.</h2>
                
        <p>After that, all data will be deleted and you will have to register
        again.</p>
      passwordReset: >-
        <p>We have sent a message to your registered email address <span
        class='rd-text-yellow-300'>{userEmail}</span>. 

        Please click on the "Assign new password" link in this email to set a
        new password.</p> <p class="rd-mt-10">


        In individual cases, shipping may take up to 6 hours. 

        Please also check your spam folders. 

        If you do not receive an email from us, contact your local coordination
        or <br/> <a href="mailto:info{'@'}radar-online.net"' target='_blank'
        class=" 

        rd-text-yellow-300">info{'@'}radar-online.net</a>.</p>
      passwordResetFooter: >-
        <h2 class="rd-text-xl">The link is valid for 24 hours.</h2>


        <p>The link will then become invalid and you will have to use the
        “forgotten login details” function again</p>
      title: Almost finished!
    header:
      action:
        login: RADar! registration
        register: CITY CYCLING login
      label: >-
        CITY CYCLING access data valid! 

        Does your municipality have RADar! 

        offered during the (last) CITY CYCLING campaign, no new registration is
        necessary! 

        All those can log in directly here to submit reports.
    subtitle: All fields are mandatory, with the exception of the municipality.
    title: Registration
report:
  action:
    create:
      selectCommune:
        adminPeriod: >-
          The administrative period has ended. 

          In order for you to be able to create and comment on reports again,
          the municipality must purchase a new license
        description: |-
          Please select a municipality that is in the registration period. 
          You can see this on the map by the red district.
        reportingPeriod: |-
          The reporting period has ended. 
          You only have to create comments.
        title: Please select a commune
      titleCommuneInactive: Restriction in this municipality
  actions:
    edit:
      coordinatorTooltip: Coordinator Edit menu
      tooltip: Edit
  attachment:
    modal:
      title: Image attachments from the Report
  comment:
    action:
      delete:
        message: Do you really want to delete the comment
        'no': 'No'
        title: Confirm to delete comment
        'yes': Delete
      edit:
        cancel: Cancel
        title: Edit comment
        update: Update
  coordinator:
    delete:
      confirmation:
        singleContent: Do you really want to delete the selected messages?
        singleTitle: Are you absolutely sure?
    edit:
      actions:
        cancel: Cancel
        delete: Delete Report?
        save: Update
        saveAndClose: Save and close
      period:
        label: 'Implementation time:'
        placeholder: No information
      status:
        label: 'Processing status:'
        placeholder: In processing
      title: Edit Report
    tabs:
      FORWARDMESSAGES: Forward messages
      HISTORY: History
      IDENTICALREPORT: Identical message
      NOTES: Notes
      REMARK: Remark
      content:
        duplicate:
          infoLabel: >-
            This message becomes the main message. 

            The reports selected as identical are no longer visible on the
            public map.
        noteInformationContent: publicly visible, even if the reports are generally publicly visible
        noteInformationLabel: publicly visible, even if the reports are generally publicly visible
        notePlaceholder: Enter comment...
        noteTitle: Remarks
        notesInformationContent: Helper notes **Need update**
        notesInformationLabel: Not public
        notesPlaceholder: Enter note...
        notesTitle: Coordinator notes
  create:
    collapse:
      NEWREPORTFORM: New Report
      NEWREPORTFORMEXTRAINFO: More information about your report
      NEWREPORTFORMPICTURES: Upload photos
      NEWREPORTFORMREASON: Report reason
      UPDATEREPORTFORM: 'Update report: {reportId}'
      UPDATEREPORTFORMEXTRAINFO: Report Description
      UPDATEREPORTFORMPICTURES: Upload photos
      UPDATEREPORTFORMREASON: Report reason
      UPDATEREPORTFORMSTATUS: Update status message
    errors:
      outOfCommuneClick: danger
      outOfCommuneContent: >-
        The position of your report must be within the boundaries of the
        selected municipality. 

        Also note the reporting period
    feedback:
      address:
        street: The street name is required
      validation:
        error: The following fields must be filled out
    form:
      address: 'Nearest address:'
      addressPlaceholder: street, town, community
      attachmentHelpText: >-
        Add pictures (max. 3 MB / file).<br/> Please make sure that no people or
        license plates are recognizable on the pictures.
      commentHelpText: >-
        Keep it as short and keyword-like as possible - still {count}
        characters.
      commentMaxReached: Reached the maximum amount of text allowed
      commentPlaceholder: Keep it as short and keyword-like as possible - xxx characters left
      latitude: 'Latitude:'
      latitudePlaceholder: 'Format example: 50.082251'
      longitude: 'Longitude:'
      longitudePlaceholder: 'Format example: 8.624607'
      search: Search
      termAndConditionAttachment: >-
        As the author of the picture, I grant Climate Alliance the necessary
        rights for the described use, unlimited in terms of time, content and
        space, and I assure that any personal rights with any persons depicted
        have been clarified and do not conflict with the outlined use. 

        <br/><br/>


        You have the right to revoke your consent of a data protection nature
        for the future at any time. To do this, just click on "Delete
        image/Revocation" below the upload.
    mobile:
      '1': Address
      '2': Reason
      '3': Extra information
      '4': Photos
      tooltip:
        content: |-
          <header class="rd-text-sm rd-font-bold">
                          
          There are two ways to determine the exact location of your report:
                        
          </header>
                        
          <section class="rd-flex">
                          
          <span class="map-wrapper-counter-item">1</span>
                          
          <span>Directly on the map (your location or click on the map)</span>
                        
          </section>
                        
          <section class="rd-flex">
                          
          <span class="map-wrapper-counter-item">2</span>
                          
          <span>About entering the address</span>
                        
          </section>
  delete:
    confirmation:
      cancel: Cancel
      confirm: Confirm
      content: Do you want to delete the Report?
      coordinator:
        content: Do you really want to delete the selected message(s)?
        title: Are you sure?
      title: Are you absolutely sure?
    feedback:
      success: |-
        Your reports have been deleted. 
        Thanks very much!
  details:
    address: Address
    attachments: Attachments
    id: ID
    reasonCategoryName: Reason
    timestamp: Date
  errors:
    attachment:
      attachment:
        file:
          write-error: Please contact the municipal administration
      attachmentIdMissing: Attachment file not found
      deleteSuccess: Attachment removed from report
      filesize: |-
        Add images (max 3MB/file). 
        Please make sure that no people are recognizable in the pictures.
      genericError: Report attachment cannot be updated
      maxLimit: A maximum of 3 images can be attached.
      reportIdMissing: Unable to add attachment, the report does not exist
      success: Report attachment updated successfully
    comment:
      maxNum:
        reached: The maximum(3) amount of comment have been reached
    commune:
      check:
        exists: Please check that you have selected a valid municipality
    create:
      fail: Unable to create report this time, try again
      missingRequiredFields: Please fill out all required fields to create a new report
      success: Report created successfully
    person:
      missing: 'Reporter uuid is missing '
    reason-category:
      missing: Please select a valid Report category
    report:
      address:
        unsupported-country: >-
          We currently have no official support for this country, please contact
          the Radar team for more information.
      create:
        location-not-in-area: Location is outside of the boundaries of the current selected commune
    update:
      error: Unable to update the report
      success: Report updated successfully
    validation:
      error: All the field are required
  feedback:
    coordinatorSuccess: Message processed successfully.
    error: The message cannot be updated
    success: Message updated successfully
  filter:
    modal:
      title: Filter reports options
  forward:
    dialog:
      content: Would you like to forward this report(s) to {email}?
      negative: Cancel
      positive: Forward onto
      title: Are you sure?
    success: |-
      Message has been forwarded. 
      Thanks very much!
    view:
      form:
        feedback:
          email: Please enter a valid email.
        helper:
          changes: If you have made changes, they will only be sent after saving.
          regular: >-
            The email contains some details about the report, including a link
            to the map, as well as your name and email address. 

            All personal data of the cyclists are anonymised. 

            The link only leads to the report if it is publicly visible on the
            map.
        input:
          placeholder: max.mustermann{'@'}mustermail.de
        label: 'Enter the e-mail address to which the message should be forwarded:'
        submit: Send
      title: Forward Report
  merge:
    confirmation:
      content: The report with the id {report} will be the leading report
    description: Select the leading report
    feedback:
      success: |-
        The reports were successfully merged. 
        Thanks very much!
    identical:
      child:
        header: Identical messages
      header: Select identical message and merge with it
      input:
        prefix: 'Selected ({selected}/{total}) :'
      label: 'Select identical message:'
    title: Merge selected reports
  overlay:
    '404':
      description: The municipality can make settings that hide messages on the map.
      title: This message cannot be displayed on the map.
  unMerge:
    confirmation:
      confirm: Revert duplicates
      content: Do you want to separate the merged reports from report { reportId }?
  useUserLocation:
    label: Use my location
  visibility:
    confirmation:
      confirm: Update visibility
      content: 'The report with id: {reportId} will be hidden from all users'
      contentVisible: 'The report with the ID: {reportId} will be visible to all users.'
  warning:
    confirmation:
      confirm: Update all
      content: Do you want to change the status of all selected messages?
report-status:
  byId:
    '1': unedited
    '2': In Progress
    '3': Completed
    '4': Neutral message
    '5': Positive message
    '6': my message
reset:
  actions:
    resetPassword: Accept password
  new:
    password:
      confirmation: Do you want to reset the password?
      placeholder: Create new password
      repeatPlaceholder: Confirm new password
      title: Create new password
  password:
    action:
      send: Send
    email:
      label: 'Please enter email address:'
      placeholder: email address or username
    feedback:
      info: After sending, a link will be sent to set a new password.
      invalidEmail: Please enter a valid email or register
      invalidToken: Invalid token, please request a new password reset email
      password: Password is required
      passwordConfirm: Password confirmation is required
      passwordConfirmNotMatch: Passwords do not match
      success: The password has been reset.
    title: Forgot your password and/or username?
settings:
  view:
    commune:
      archive:
        action:
          content: Would you like to apply your changes to the archive?
        description: |-
          The status of the notification was set to "completed" or "neutral". 
          The date when the report was submitted is decisive for archiving.
        feedback:
          success: |-
            Your change in the archive has been accepted. 
            Thanks very much!
        information: You can still view archived messages by exporting messages.
        subtitle: 'Messages are archived and are no longer publicly visible after months:'
        title: Archive
      reasons:
        action:
          content: Would you like to accept your changes in the notification reasons?
          title: ' Are you sure?'
        feedback:
          success: |-
            Your changes in the notification reasons have been adopted. 
            Thanks very much!
        information: >-
          If a tick is set, this reason for the report will be displayed to
          cyclists when they submit a report.
        subtitle: Specify by clicking which report reasons are available to cyclists.
        title: Select and deselect message reasons
        visible: ({visible} selected from {total})
      status:
        action:
          content: Do you want to apply your changes in visibility on the map?
        confirmation: >-
          If a tick is ticked, the messages with the respective status for
          cyclists and coordinators are visible on the map and in the list of
          messages.
        feedback:
          success: |-
            Your changes in visibility on the map have been applied. 
            Thanks very much!
        information: >-
          If the box is ticked, the reports can be viewed by your cyclists in
          the login area and on the subpage of your municipality
        subtitle: >-
          Click to specify which reports are publicly viewable and with which
          status.
        title: Visibility status on map
sidebar:
  collapse:
    label: Show less
  communes:
    RD: Radar!
    SR: city ​​cycling
    all: All
    inactive: Inactive
    main: Communes
    pending: Pending Municipalities
  dashboard: Dashboard
  help:
    contact: Contact
    description: Take a look at the manual or contact us by phone or email.
    reports: Manual
    title: Any questions?
  license: License
  myReports: My Reports
  reports:
    all: All ({count})
    done: Done ({count})
    main: Reports
    neutral: Neutral ({count})
    new: New ({count})
    onProgress: On progress ({count})
    positive: Positive ({count})
  settings:
    commune: Configure reports
    contact: Configure contact info
    contract: Contract
    email: E-mail
    embedded: Embed map
    main: Configuration
    map: Configure map
    reports: Configure notifications
  tools:
    export: Export
    import: Import
    main: Tools
  users:
    all: All
    allSystem: All in the system
    coordinators: Coordinators
    main: Users
user:
  create:
    feedback:
      invalidFields: |-
        Please ensure that all required fields are filled out. 
        Empty mandatory fields are marked in red.
  registration:
    form:
      username:
        min:
          error: The username must contain at least 3 characters
        required:
          error: Username is required
        taken:
          error: This username is already taken
  verification:
    feedback:
      deleteSuccess: The account has been deleted.
      success: You have confirmed your email address and can now log in.
users:
  coordinator:
    actions:
      newCoordinator:
        button: New coordinator
      newUser:
        button: Create user
      search:
        button: Search user
    feedback:
      noSelectedCommune: Please select a municipality
      selectCommuneInstructions: |-
        <div>
          
        Use the dropdown list in the top right.

        </div>
    header:
      coordinatorLabel: Coordinators List
      label: User Management
  table:
    filter:
      exact:
        label: Exact match only
      filterBy:
        label: 'Search in:'
        options:
          email: Email address
          fistName: First Name
          lastName: Last Name
          username: Username
        placeholder: All fields
      keyword:
        label: 'Search:'
        placeholder: Keyword
      reset:
        tooltip: reset search
views:
  dashboard:
    commune:
      settings:
        button: Configure contact info
        description: >-
          Here you can edit the contact information that is publicly visible on
          the map:
    title: 'You are the coordinator of the municipality: {name}'
    users:
      button: Create new accounts
      description: 'You can create new cycling and other coordinator accounts here:'
  license:
    action:
      renewButton: Extend your booking for a fee
