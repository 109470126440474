import { useOidcStore } from 'vue3-oidc';
import { DeepRequired } from '@/stores/GlobalStore';
import {
  V1ReportReportIdDuplicatesParameters,
  V1ReportReportIdDuplicatesResponse,
  V1ExportParameters,
  V1ExportResponse,
  CommentFullDto,
  CommentCreationDto,
  ReportDto,
  V1CommentIdResponse,
  V1CommentParameters,
  V1CommentResponse,
  V1LocationParameters,
  V1LocationResponse,
  V1ReportIdResponse,
  V1ReportParameters,
  V1ReportResponse,
  V1ReportTypesResponse,
  V1CommunestatisticsParameters,
  CommuneStatisticsDto,
  V1ReportReportIdLikeParameters,
  V1CommuneUuidTypesParameters,
  CommuneTypesDto,
  ReportAuditDto,
  V1CommuneperiodParameters,
  V1ReportforwardParameters,
  V1ReportforwardResponse,
  V1ReportReportIdHistoryParameters,
  V1ReporthidestatusOrReasonParameters as SettingsVisibilityToggle,
  ReportCreationDto,
  CoordinationDto,
  V1CommuneUuidSyncParameters,
} from '@/typings/api';
import { ReverseQueryAddress } from '@/typings/dto/Report';
import { Page } from '@/typings/general/Page';
import axios, { AxiosResponse } from 'axios';

export interface CustomLocationInterface {
  latitude: string;
  longitude: string;
}

export type ReportDtoRequired = Required<Omit<ReportCreationDto, 'location'>> & {
  location: CustomLocationInterface;
  coordination: CoordinationDto;
};

export interface SearchResponse {
  address: ReverseQueryAddress;
  display_name: string;
  lat: number;
  lon: number;
  place_id: string;
}

export interface NewReportDto {
  cityId: number | string;
  statusId: number;
  location: {
    latitude: number | string;
    longitude: number | string;
  };
  address: {
    country: string;
    city: string;
    state: string;
    zipCode: string;
    suburb: string;
    street: string;
  };
  reasonGroupId: number;
  reasonCategoryId: number;
  comment: string;
  attachments: string[];
}

export abstract class RoadReport {
  private static ROAD_REPORT_INSTANCE = axios.create({
    baseURL: `${import.meta.env.VITE_ROAD_REPORT}/api/v1`,
  });

  static async GetReportTypes(): Promise<AxiosResponse<V1ReportTypesResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get('/report-types');
  }

  static async GetTypesByCommune({ uuid }: V1CommuneUuidTypesParameters): Promise<AxiosResponse<DeepRequired<CommuneTypesDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/${uuid}/types`);
  }

  /*----------  Reason || status  toggle visibility  ----------*/

  static async ToggleVisibility(q: SettingsVisibilityToggle): Promise<AxiosResponse> {
    const p = q as unknown as Record<string, string>;
    const urlParams = new URLSearchParams(p);
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.patch(`/report/hide/status_or_reason?${urlParams.toString()}`);
  }
  static async UpdateArchivePeriod(q: V1CommuneperiodParameters): Promise<AxiosResponse> {
    const p = q as unknown as Record<string, string>;
    const urlParams = new URLSearchParams(p);
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.patch(`/commune/period?${urlParams.toString()}`);
  }

  static FetchReportsByLocation({ statusIds, ...q }: V1LocationParameters): Promise<AxiosResponse<Required<V1LocationResponse>>> {
    const urlParams = new URLSearchParams(q as Record<string, string>);
    statusIds?.forEach((status) => {
      urlParams.append('statusIds', `${status}`);
    });

    RoadReport.tokenInterceptor();
    return RoadReport.ROAD_REPORT_INSTANCE.get(`/location?${urlParams.toString()}`);
  }

  static async GetAllLocations(p: V1LocationParameters): Promise<AxiosResponse<Required<V1LocationResponse>>> {
    return await this.FetchReportsByLocation(p);
  }

  static async GetReportsByLocationAndCommuneUuid(q: V1LocationParameters): Promise<AxiosResponse<Required<V1LocationResponse>>> {
    return this.FetchReportsByLocation(q);
  }

  static async GetMaintenanceMode(): Promise<
    AxiosResponse<
      Required<{
        maintenanceModeChangeTime: string;
        maintenanceMode: boolean;
      }>
    >
  > {
    return this.ROAD_REPORT_INSTANCE.get('/maintenance');
  }

  static async GetReportById(reportId: string): Promise<AxiosResponse<V1ReportIdResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/report/${reportId}`);
  }
  static async FetchReportHistory({
    reportId,
    ...page
  }: V1ReportReportIdHistoryParameters): Promise<AxiosResponse<Page<ReportAuditDto>>> {
    const urlParams = new URLSearchParams(page as Record<string, string>);
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`report/${reportId}/history?${urlParams.toString()}`);
  }

  static async CreateReport(r: ReportDtoRequired): Promise<AxiosResponse<V1ReportResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.post('/report', r);
  }

  static async UpdateReport({ report, id }: { report: ReportDtoRequired; id: number }): Promise<AxiosResponse<V1ReportResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/report/${id}`, report);
  }

  static async UploadAttachment(formData: FormData, reportId: number, isReport = true): Promise<AxiosResponse<V1ReportResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.post(`/attachment/?reportCommentFlag=${isReport}&Id=${reportId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async DeleteReportById(reportId: number) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.delete(`/report/${reportId}`);
  }

  static async ToggleVisibilityReportById({ reportId, visible = false }: { reportId: number; visible?: boolean }) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.patch(`/report/hide?reportId=${reportId}&visible=${visible}`);
  }

  static async RemoveAttachment({ attachmentId, fromReport = true }: { attachmentId: string; fromReport: boolean }) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.delete(`/attachment/${attachmentId}?reportCommentFlag=${fromReport}`);
  }

  /*----------  Filter Reports by status  ----------*/
  static async FilterReportByStatusAndLocation({
    location,
    statusIdList,
  }: {
    location: V1LocationParameters;
    statusIdList: number[];
  }) {
    return await this.FetchReportsByLocation({ ...location, statusIds: statusIdList });
  }

  /*----------  Filter Reports by status  ----------*/
  static async QueryReports(params: V1ReportParameters): Promise<AxiosResponse<Page<ReportDto>>> {
    const { statusIds, reasonIds, ...p } = params;
    this.tokenInterceptor();
    const urlParams = new URLSearchParams(p as Record<string, string>);
    statusIds?.forEach((status) => {
      urlParams.append('statusIds', `${status}`);
    });
    reasonIds?.forEach((reasonId) => {
      urlParams.append('reasonIds', `${reasonId}`);
    });
    urlParams.sort();

    return await this.ROAD_REPORT_INSTANCE.get(`/report?${urlParams.toString()}`);
  }

  /*----------  Get reports by user id  ----------*/

  static async GetReportByUser(params: V1ReportParameters): Promise<AxiosResponse<Page<ReportDto>>> {
    const { statusIds, ...p } = params;
    this.tokenInterceptor();
    const urlParams = new URLSearchParams(p as Record<string, string>);
    statusIds?.forEach((status) => {
      urlParams.append('statusIds', `${status}`);
    });
    return await this.ROAD_REPORT_INSTANCE.get(`/report?${urlParams.toString()}`);
  }

  /*----------  Merge duplicated reports  ----------*/

  static async MergeReports({
    reportId,
    subReportsIds,
  }: V1ReportReportIdDuplicatesParameters & { subReportsIds: number[] }): Promise<
    AxiosResponse<V1ReportReportIdDuplicatesResponse>
  > {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/report/${reportId}/duplicates`, subReportsIds);
  }

  /*----------  Comment api  ----------*/
  static async FetchAllCommentForReportById(
    p: V1CommentParameters & { page: number },
  ): Promise<AxiosResponse<Page<V1CommentResponse>>> {
    const urlParams = new URLSearchParams(p as unknown as Record<string, string>);

    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/comment?${urlParams.toString()}&size=20`);
  }

  static async CreateNewCommentByReportId({ reportId, text }: V1CommentParameters & Required<CommentCreationDto>) {
    try {
      this.tokenInterceptor();

      return await this.ROAD_REPORT_INSTANCE.post<Required<CommentFullDto>>(`/comment?reportId=${reportId}`, {
        text,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // The client was given an error response (5xx, 4xx)
        console.log(error);
        // rome-ignore lint/suspicious/noExplicitAny: <explanation>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return error as any;
      } else {
        // Anything else

        return {
          status: 400,
          response: {
            data: { label: 'error.comment.create.general' },
          },
        };
      }
    }
  }

  static async DeleteCommentById(commentId: string) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.delete(`/comment/${commentId}`);
  }

  static async GetCommentById(commentId: string): Promise<AxiosResponse<V1CommentIdResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/comment/${commentId}`);
  }
  static async UpdateCommentById({ commentId, text }: Record<string, string>): Promise<AxiosResponse<V1CommentIdResponse>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/comment/${commentId}`, { text });
  }

  /*----------  Export Handlers  ----------*/
  /**
   *
   * @param { outputType  } 'xlsx'|| 'shp'
   * @returns Excel document or Shapefile
   */

  static async FetchDataToExport({
    outputType = 'xlsx',
    exportFilter = {
      type: 'COORDINATOR_REPORT_DATA',
    },
    ...params
  }: V1ExportParameters & { statusIds?: string[]; reasonIds?: string[] }): Promise<AxiosResponse<V1ExportResponse>> {
    this.tokenInterceptor();
    const { statusIds, reasonIds, ...p } = params;
    const urlParams = new URLSearchParams({ outputType, ...exportFilter, ...p } as unknown as Record<string, string>);

    statusIds?.forEach((status) => {
      urlParams.append('statusIds', `${status}`);
    });
    reasonIds?.forEach((reasonId) => {
      urlParams.append('reasonIds', `${reasonId}`);
    });
    urlParams.sort();

    return await this.ROAD_REPORT_INSTANCE.get(`/export?${urlParams.toString()}`, {
      headers: { Accept: 'multipart/form-data' },
      responseType: 'blob',
    });
  }

  /*----------  Statistic for specific commune  ----------*/
  static async SyncCommune({ uuid, action = 'SYNC_ONLY' }: V1CommuneUuidSyncParameters) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/commune/${uuid}/sync?action=${action}`);
  }

  static async GetCommuneStats(p: V1CommunestatisticsParameters): Promise<AxiosResponse<DeepRequired<CommuneStatisticsDto>>> {
    const urlParams = new URLSearchParams(p as Record<string, string>);
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/statistics?${urlParams.toString()}`);
  }

  /*----------  Toggle like status of report  ----------*/
  static async ToggleReportLike({
    like,
    reportId,
  }: V1ReportReportIdLikeParameters): Promise<AxiosResponse<DeepRequired<ReportDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.patch(`/report/${reportId}/${like}`);
  }
  /*----------  Forward report  ----------*/
  static async ForwardReportsTo({ receiverEmails, reportIds }: V1ReportforwardParameters): Promise<V1ReportforwardResponse> {
    const urlParams = new URLSearchParams();
    reportIds?.forEach((status) => {
      urlParams.append('reportIds', `${status}`);
    });
    receiverEmails?.forEach((email) => {
      urlParams.append('receiverEmails', `${email}`);
    });

    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.post(`/report/forward?${urlParams.toString()}`);
  }

  private static tokenInterceptor() {
    const { state } = useOidcStore();

    this.ROAD_REPORT_INSTANCE.interceptors.request.use(
      (config) => {
        if (state.value.token) {
          config.headers.Authorization = `Bearer ${state.value.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}

export default RoadReport;
