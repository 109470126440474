<script lang="ts" setup>
/**
 *
 * Empty table component.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { IconLocal } from '@/Dashboard';
import { toRefs } from 'vue';

interface Props {
  translationLabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
  translationLabel: 'global.table.empty.description',
});
const { translationLabel } = toRefs(props);
</script>

<template>
  <div
    class="rd-flex rd-flex-col rd-max-w-[200px] rd-text-base-990 rd-text-opacity-40 dark:rd-text-base-130 dark:rd-text-opacity-40"
  >
    <slot name="title">
      <section class="rd-text-center rd-mb-3 rd-flex rd-justify-center rd-items-center">
        <IconLocal size="25" /> <span class="rd-font-medium rd-ml-1">{{ $t('global.table.empty.label') }}</span>
      </section>
    </slot>
    <section class="rd-text-center">{{ $t(translationLabel) }}</section>
  </div>
</template>
<style scoped></style>
