<script lang="ts" setup>
/**
 *
 * General success view for different actions
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { toRefs } from 'vue';
import { SuccessIcon } from '@/assets/icons/Icons';
interface Props {
  cssClass: string;
  iconClass: string;
}

const props = withDefaults(defineProps<Props>(), {
  cssClass: 'dark:rd-text-base-110 rd-min-h-full rd-h-[350px]',
  iconClass: 'rd-text-yellow-300',
});
const { cssClass, iconClass } = toRefs(props);
</script>

<template>
  <div class="rd-grid rd-place-content-center rd-bg-transparent" :class="cssClass" data-test="success-view">
    <section class="rd-grid rd-grid-cols-10">
      <section class="rd-col-span-4 rd-col-start-4 rd-mb-7">
        <slot name="icon">
          <div class="rd-text-center" v-html="SuccessIcon(`rd-h-28 rd-w-28 rd-mx-auto ${iconClass}`)" />
        </slot>
      </section>
      <div class="rd-col-span-10">
        <slot>
          <h4 class="rd-text-center rd-text-3.5xl">{{ $t('actions.report.create.success.message') }}</h4>
        </slot>
      </div>
    </section>
  </div>
</template>
<style scoped></style>
