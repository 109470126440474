import { DeepRequired } from '@/stores/GlobalStore';
import { CommuneUserLocation } from '@/stores/CommuneStore';
import {
  type V1CommuneUuidParameters,
  type V1CommuneUuidAreaParameters,
  type V1CommuneParameters,
  type V1CommuneUuidResponse,
  type V1CommuneUuidAreaResponse,
  type V1CommuneUuidRequest,
  type InitialCommuneDto,
  type CommuneDto,
  CommuneRelationshipDto,
  V1CommuneUuidRelationParameters,
  type V1CommuneadminParameters,
  CommuneAdminDto,
  StubDto,
  V1StubParameters,
  type TypesDto,
  V1CommuneUuidNotifyParameters,
} from '@/typings/commune';
import axios, { AxiosResponse } from 'axios';

import { Page } from '@/typings/general/Page';
import { useOidcStore } from 'vue3-oidc';

export type CommuneResponse = Record<string, object> & {
  empty: boolean;
};
export type CommuneDetail = {
  uuid: string;
  manualName: string;
  id: number;
  latitude: number;
  longitude: number;
};

export interface UpdateCommuneParams {
  uuid: string;
  data: CommuneResponse;
}

export abstract class CommuneService {
  private static ROAD_COMMUNE_INSTANCE = axios.create({
    baseURL: `${import.meta.env.VITE_ROAD_COMMUNE}/api/v1`,
  });

  static async GetAllCommunes(options?: V1CommuneParameters) {
    const urlParams = new URLSearchParams({ usable: options?.usable || 'USABLE' } as Record<string, string>);
    if (options?.contractStatus) {
      options.contractStatus.forEach((status) => {
        urlParams.append('contractStatus', status);
      });
    }
    const url = `/commune?${options?.uuid ? `uuid=${encodeURIComponent(options.uuid)}&` : ''}${
      options?.reportStart ? `reportStart=${encodeURIComponent(options.reportStart)}&` : ''
    }${options?.reportEnd ? `reportEnd=${encodeURIComponent(options.reportEnd)}&` : ''}${urlParams.toString()}`;

    return await this.ROAD_COMMUNE_INSTANCE.get(url);
  }
  static async GetAllCommunesAsAdmin(p: V1CommuneadminParameters): Promise<AxiosResponse<Page<Required<CommuneAdminDto>>>> {
    const urlParams = new URLSearchParams(p as Record<string, string>);
    for (const [key, value] of urlParams.entries()) {
      if (!value || value.length < 1) {
        urlParams.delete(key);
      }
    }

    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/admin?${urlParams.toString()}`);
  }
  static async GetRegionTypes(): Promise<AxiosResponse<DeepRequired<TypesDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/types`);
  }
  static async GetCommuneByUserLocation(loc: CommuneUserLocation) {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune?lat=${loc.lat}&long=${loc.long}`);
  }

  static async GetCommuneByUuid({ uuid, parentContract = 'NONE' }: V1CommuneUuidParameters): Promise<AxiosResponse<CommuneDto>> {
    this.tokenInterceptor();

    const urlParam = new URLSearchParams({ parentContract } as Record<string, string>);

    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/${uuid}?${urlParam.toString()}`);
  }
  static async GetCommuneByUrl({ urlSlug, contractStatus, ...P }: V1CommuneParameters): Promise<AxiosResponse<CommuneDto[]>> {
    this.tokenInterceptor();
    const urlParam = new URLSearchParams(P as Record<string, string>);
    contractStatus?.forEach((status) => {
      urlParam.append('contractStatus', status);
    });

    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune?urlSlug=${urlSlug}&${urlParam.toString()}`);
  }
  static async DeleteCommuneByUuid({ uuid }: V1CommuneUuidParameters): Promise<AxiosResponse<V1CommuneUuidResponse, unknown>> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.delete(`/commune/${uuid}`);
  }

  static async UpdateCommuneByUuid({
    uuid,
    data,
  }: {
    uuid: string;
    data: InitialCommuneDto;
  }): Promise<AxiosResponse<CommuneDto>> {
    this.tokenInterceptor();

    return await this.ROAD_COMMUNE_INSTANCE.put<V1CommuneUuidRequest>(`/commune/${uuid}?type=RADAR`, data);
  }
  static async CreateNewCommune({ commune }: { commune: InitialCommuneDto }): Promise<AxiosResponse<CommuneAdminDto>> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.post<V1CommuneUuidRequest>('/commune?notify=false', commune);
  }
  /*----------  Relationship commune  ----------*/
  static async GetCommuneRelationShips({
    uuid,
  }: V1CommuneUuidRelationParameters): Promise<AxiosResponse<Required<CommuneRelationshipDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/${uuid}/relation`);
  }

  static async UpdateCommuneRelationship({ uuid, childUuid, parent }: V1CommuneUuidRelationParameters) {
    const urlParam = new URLSearchParams();
    childUuid?.forEach((id) => {
      urlParam.append('childUuid', id);
    });
    urlParam.append('parent', `${parent}`);
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.put(`/commune/${uuid}/relation?${urlParam.toString()}`);
  }

  /*----------  GeoJson  ----------*/

  static async UpdateCommuneGeoJsonByUuid({ uuid, data }: UpdateCommuneParams) {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.put(`/commune/${uuid}/area`, data);
  }
  static async GetAreaById({
    uuid,
    parentArea = false,
  }: V1CommuneUuidAreaParameters): Promise<AxiosResponse<V1CommuneUuidAreaResponse, unknown>> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/${uuid}/area?parentArea=${parentArea}`);
  }
  static async GetAllCommunesByStatus({ status }: { status: string }) {
    // TODO connect to actual end point
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune?status=${status}`);
  }
  static async IsMarkerWhitInCommune({ lat, communeUuId, long }: { lat: number; long: number; communeUuId: string }) {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/${communeUuId}/area/check?latitude=${lat}&longitude=${long}`);
  }
  static async FetchPredefinedCommunes(p: V1StubParameters): Promise<AxiosResponse<Page<Required<StubDto>>>> {
    this.tokenInterceptor();

    const urlParam = new URLSearchParams(p as Record<string, string>);
    return await this.ROAD_COMMUNE_INSTANCE.get(`/stub?${urlParam.toString()}`);
  }
  static async SyncCommuneByUuId({ uuid, waitFor = 'none' }: V1CommuneUuidNotifyParameters): Promise<[]> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/${uuid}/notify?waitFor=${waitFor}`);
  }
  static async ValidateCommuneUrl({ uuid, newUrl }: { uuid: string; newUrl: string }): Promise<AxiosResponse> {
    this.tokenInterceptor();
    return await this.ROAD_COMMUNE_INSTANCE.get(`/commune/url?uuid=${uuid}&url=${newUrl}`);
  }

  /*----------  Add keycloak token  ----------*/
  private static tokenInterceptor() {
    const { state } = useOidcStore();

    this.ROAD_COMMUNE_INSTANCE.interceptors.request.use(
      (config) => {
        if (state.value.token) {
          config.headers ? (config.headers.Authorization = `Bearer ${state.value.token}`) : null;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  static async LoadTemplate() {
    // const url = `https://radar.object-manager.com/live/admin/data/gemeinden/Zimmern_ob_Rottweil.geojson`;
    const name = 'geojson/lautertal-odenwald.geojson';
    return await axios.get(name);
  }
}

export default CommuneService;
