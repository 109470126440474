import { useLocalStorage } from '@vueuse/core';
import { GlobalThemeOverrides } from 'naive-ui';
import { ref } from 'vue';

/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */

export const UseTheme = useLocalStorage('application-theme', 'darkTheme');
export const UseMapStylesPath = ref('https://maps.stadtradeln.de/styles/sr-white/style.json');
export const SetTheme = (theme: string | null): void => {
  theme === 'darkTheme'
    ? (UseMapStylesPath.value = 'https://maps.stadtradeln.de/styles/sr-dark/style.json')
    : (UseMapStylesPath.value = 'https://maps.stadtradeln.de/styles/sr-white/style.json');
  UseTheme.value = theme;
};

export const saveTheme = (theme: string | null) => {
  if (theme) {
    SetTheme('darkTheme');
    document.documentElement.setAttribute('data-theme', theme);
  } else {
    SetTheme('lightTheme');
    document.documentElement.setAttribute('data-theme', '');
  }
};

export const appTheme = () => {
  return {
    UseTheme,
  };
};

const ThemeCustom: GlobalThemeOverrides = {
  common: {
    fontFamily: 'v-sans',
    fontFamilyMono: 'Roboto',
    fontWeightStrong: '700',
    borderRadius: '17px',
    borderRadiusSmall: '5px',
    fontSize: '15px',
    fontSizeMini: '14px',
    fontSizeTiny: '11px',
    fontSizeSmall: '14px',
    fontSizeMedium: '14px',
    lineHeight: '1.6',

    bodyColor: '#FFFFFFFF',
    baseColor: '#073B50FF',
    primaryColor: '#0086C5FF',
    primaryColorHover: '#0086C5FF',
    primaryColorPressed: '#0086C5FF',
    fontSizeLarge: '14px',
    infoColor: '#2080F014',
    infoColorHover: '#4098FC36',
    infoColorPressed: '#1060C942',
    infoColorSuppl: '#4098FC54',
    successColor: '#8cb82b',
    successColorHover: '#8cb82b',
    successColorPressed: '#8cb82b',
    successColorSuppl: '#8cb82b',
    warningColor: '#FFE000FF',
    warningColorHover: '#FFBD00FF',
    warningColorPressed: '#FFA500FF',
    warningColorSuppl: '#FF9C00FF',
    errorColor: '#BF0A06',
    errorColorHover: '#BF0A06',
    errorColorPressed: '#BF0A06',
    errorColorSuppl: '#C31162FF',
    textColorBase: '#454545FF',

    textColor1: 'rgba(6, 53, 71, 1)',
    textColorDisabled: 'rgba(6, 53, 71, 1)',
    placeholderColor: 'rgba(69, 69, 69, 0.4)',
    closeIconColor: 'rgba(212, 217, 219, 1)',
    invertedColor: 'rgba(3, 79, 154, 1)',
    tableHeaderColor: 'rgba(250, 250, 252, 0.03)',
    hoverColor: 'rgba(243, 243, 245, 0.74)',
    tableColorHover: 'rgba(0, 0, 100, 0.04)',
    inputColorDisabled: 'rgba(250, 250, 252, 0)',
    closeIconColorHover: 'rgba(254, 254, 254, 1)',
    textColor2: 'rgba(6, 53, 71, 1)',
    opacityDisabled: '0.4',
  },
  Collapse: {
    dividerColor: 'var(--radar-base-colors-white-510)',
    textColor: 'rgb(51, 54, 57)',
    titleTextColor: 'rgba(6, 53, 71, 1)',
  },
  Tabs: {
    tabFontWeightActive: '700',
    tabGapMediumSegment: '0',
    tabTextColorActiveLine: 'red',
    barColor: 'transparent',
    tabTextColorActiveBar: 'var(--radar-base-colors-white-100)',
    tabPaddingMediumBar: '9px 10px',
    tabGapMediumBar: '0',
    tabTextColorHoverBar: 'var(--n-tab-text-color)',
  },
  Input: {
    groupLabelTextColor: 'rgba(6, 53, 71, 1)',
    fontSizeTiny: '14px',
    fontSizeLarge: '14px',
    fontSizeSmall: '14px',
    fontSizeMedium: '14px',
    borderRadius: '0px',
    groupLabelColor: 'rgba(0, 0, 31, 1)',
    textColor: 'rgba(6, 53, 71, 0.8)',
    placeholderColor: 'var(--radar-base-colors-blue-250)',
    color: 'rgba(255, 255, 255, 1)',
    groupLabelBorder: 'unset',
    boxShadowFocus: 'unset',
    border: '1px solid transparent',
    borderHover: '1px solid transparent',
    borderDisabled: '1px solid transparent',
    borderFocus: '1px solid transparent',
    borderError: '1px solid transparent',
    borderHoverError: '1px solid transparent',
    borderFocusError: '1px solid transparent',
    colorFocus: 'rgba(255, 255, 255, 0)',

    paddingMedium: '0px',
    paddingLarge: '0px',
    paddingSmall: '0px',
    paddingTiny: '0px',
    textColorDisabled: 'rgba(6, 53, 71, 0.8)',
  },
  Menu: {
    fontSize: '13px',
    color: 'var(--radar-base-colors-white-105)',
    itemColorActive: 'transparent',
    itemColorActiveCollapsed: 'transparent',
    itemColorActiveHover: 'transparent',
    itemColorHover: 'transparent',
    itemTextColorHover: 'var(--radar-base-colors-blue-50)',
    itemTextColorActiveHover: 'var(--radar-base-colors-white-100)',

    itemIconColor: 'var(--radar-base-colors-blue-50)',
    itemIconColorActive: 'var(--radar-base-colors-white-100)',
    itemIconColorHover: 'var(--radar-base-colors-blue-50)',
    itemIconColorActiveHover: 'var(--radar-base-colors-white-100)',
    itemTextColor: 'var(--radar-base-colors-blue-50)',

    itemTextColorHorizontal: 'var(--radar-base-colors-white-100)',

    itemTextColorActiveHorizontal: 'var(--radar-base-colors-white-100)',
    itemTextColorActiveHoverHorizontal: 'var(--radar-base-colors-white-100)',
    itemTextColorHoverHorizontal: 'var(--radar-base-colors-white-100)',
    itemTextColorChildActive: 'var(--radar-base-colors-white-100)',
    itemTextColorChildActiveHover: 'var(--radar-base-colors-white-100)',
    itemIconColorChildActive: 'var(--radar-base-colors-white-100)',
    itemIconColorChildActiveHover: 'var(--radar-base-colors-white-100)',

    itemTextColorActive: 'var(--radar-base-colors-white-100)',
    arrowColorChildActive: 'var(--radar-base-colors-white-100)',
    arrowColorChildActiveHover: 'var(--radar-base-colors-white-100)',
  },
  Button: {
    heightSmall: '40px',
    heightLarge: '40px',
    heightMedium: '40px',
    paddingMedium: '12px 19px 12px 17px',
    heightTiny: '26px',
    border: '1px solid currentColor',
    borderRadiusMedium: '25px',
    borderRadiusSmall: '25px',
    borderRadiusTiny: '25px',
    borderRadiusLarge: '25px',
    borderInfo: '1px solid currentColor',
    color: 'transparent',

    textColor: '#073B50',
    textColorDisabled: 'var(--radar-base-colors-white-100)',
    textColorSuccess: 'var(--radar-base-colors-white-100)',
    textColorHoverSuccess: 'var(--radar-base-colors-white-100)',
    textColorPressedSuccess: 'var(--radar-base-colors-white-100)',
    textColorFocusSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextHoverSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextPressedSuccess: 'var(--radar-base-colors-white-100)',
    textColorDisabledSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextFocusSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextDisabledSuccess: 'var(--radar-base-colors-white-100)',

    colorInfo: 'var(--radar-base-colors-blue-200)',
    colorHoverInfo: 'var(--radar-base-colors-white-500)',
    colorPressedInfo: 'var(--radar-base-colors-white-500)',
    colorFocusInfo: 'var(--radar-base-colors-white-500)',
    colorDisabledInfo: '#073B50',

    textColorInfo: 'var(--radar-base-colors-white-100)',
    textColorHoverInfo: 'currentColor',

    borderHoverInfo: '1px solid var(--radar-base-colors-white-100)',
    textColorPressedInfo: 'currentColor',
    textColorFocusInfo: 'currentColor',
    textColorDisabledInfo: 'currentColor',

    textColorDisabledPrimary: 'var(--radar-base-colors-white-100)',
    textColorPrimary: 'var(--radar-base-colors-white-100)',
    textColorHoverPrimary: 'var(--radar-base-colors-white-100)',
    textColorPressedPrimary: 'var(--radar-base-colors-white-100)',
    textColorFocusPrimary: 'var(--radar-base-colors-white-100)',
    fontSizeMedium: '14px',
    textColorGhost: 'currentColor',
    textColorGhostInfo: 'currentColor',
    textColorGhostHoverInfo: 'currentColor',

    textColorHover: 'currentColor',
    textColorTextFocus: 'currentColor',
    textColorTextHover: 'currentColor',
    textColorTextPressed: 'currentColor',
    textColorTextDisabled: 'currentColor',
  },

  TreeSelect: {
    menuColor: 'var(--radar-base-colors-white-500)',
    menuPadding: '0px 10px',
    // menuBorderRadius: '0px',
    menuBorderRadius: '0 0 5px 5px',

    peers: {
      Tree: {
        nodeColorHover: 'transparent',
        nodeTextColor: 'var(--radar-base-colors-blue-200)',
        nodeBorderRadius: '0px',
        fontSize: '12px',
        peers: {
          Checkbox: {
            color: 'transparent',
            border: '1px solid var(--radar-base-colors-blue-200)',
          },
        },
      },
    },
  },
  Select: {
    // menuBoxShadow: '0 3px 6px -4px rgba(3, 77, 111, .11), 0 6px 16px 0 rgba(3, 77, 111, .8), 0 9px 28px 8px rgba(3, 77, 111, .8)',
    peers: {
      InternalSelection: {
        fontSizeMedium: '14px',
        colorDisabled: 'var(--radar-base-colors-white-540)',
        borderHover: 'unset',
        borderActive: 'unset',
        borderFocus: 'unset',
      },
      InternalSelectMenu: {
        optionFontSizeMedium: '14px',
      },
    },
  },
  Checkbox: {
    color: 'transparent',
    colorChecked: '#8cb82b',
    colorDisabledChecked: '#8cb82b',
    checkMarkColorDisabledChecked: '#FEFEFEFF',
    checkMarkColor: '#FEFEFEFF',
    colorDisabled: 'transparent',
    textColor: 'var(--n-label-text-color)',
    textColorDisabled: 'var(--radar-base-colors-white-540)',
    colorTableHeader: 'transparent',
    borderRadius: '2px',
    sizeMedium: '14px',
    fontSizeMedium: '14px',
    border: '1px solid var(--radar-base-colors-white-500)',
    borderChecked: '1px solid var(--radar-base-colors-white-500)',
    boxShadowFocus: 'unset',
    borderFocus: '1px solid var(--radar-base-colors-white-500)',
  },
  Radio: {
    color: 'transparent',
    textColor: 'rgba(6, 53, 71, 1)',
    dotColorActive: '#8cb82b',
    buttonBorderColorActive: '#153445FF',
    buttonBorderColor: 'rgba(0, 0, 28, 1)',
    buttonColor: '#000000FF',
    buttonColorActive: '#000D12FF',
    buttonTextColorActive: '#000000FF',
    buttonTextColorHover: '#010101FF',
    boxShadow: 'inset 0 0 2px  #153445',
    boxShadowActive: 'inset 0 0 2px  #153445',
    boxShadowFocus: 'inset 0 0 2px  #153445',
    boxShadowHover: 'inset 0 0 2px  #153445',
    fontSizeMedium: '14px',
    radioSizeMedium: '14px',
  },
  Form: {
    labelFontSizeTopMedium: '14px',

    labelTextColor: 'rgba(7, 59, 80, 0.6)',
  },
  Alert: {
    contentTextColorError: 'rgba(255, 255, 255, 1)',
    contentTextColorSuccess: 'rgba(255, 255, 255, 1)',
    titleTextColorSuccess: 'rgba(255, 255, 255, 1)',
    colorSuccess: '#8cb82b',
    iconColor: '#fff',
    fontSize: '13px',

    padding: '6px 30px',
    borderRadius: '40px',
    iconMargin: '5px 10px',
    closeMargin: '5px 10px',
    closeSize: '24px',
    colorError: 'var(--radar-base-colors-red-100)',
    iconColorError: 'var(--radar-base-colors-white-100)',
    border: '1px solid #fefefe',
    borderError: '1px solid #fefefe',
    borderInfo: '1px solid #fefefe',
    borderSuccess: '1px solid #fefefe',
  },
  Tooltip: {
    textColor: 'var(--radar-base-colors-white-100)',
    color: 'var(--radar-base-colors-blue-300)',
    padding: '0px 15px',
    peers: {
      Popover: {
        fontSize: '12px',
      },
    },
  },
  Dialog: {
    borderRadius: '5px',
    closeMargin: '0px',
    titleFontSize: '16px',
    fontSize: '16px',
    peers: {
      Button: {
        textColor: 'var(--radar-base-colors-white-100)',
        textColorError: 'var(--radar-base-colors-white-100)',
        textColorDisabledError: 'var(--radar-base-colors-white-100)',
        textColorFocusError: 'var(--radar-base-colors-white-100)',
        textColorHoverError: 'var(--radar-base-colors-white-100)',
        paddingLarge: '12px 40px',
      },
    },
  },
  Dropdown: {
    // color: 'var(--radar-base-colors-blue-300)',
    // optionColorHover: 'var(--radar-base-colors-blue-400)',
    // optionTextColor: 'var(--radar-base-colors-white-100)',
    // optionTextColorHover: 'var(--radar-base-colors-white-100)',
  },
  DataTable: {
    borderColor: '#D5D9DB',
    thColor: 'transparent',
    thFontWeight: '400',

    tdColor: 'transparent',
    thColorHover: 'transparent',
    tdColorHover: 'transparent',
    tdTextColor: 'var(--radar-base-colors-blue-256)',
    thTextColor: 'var(--radar-base-colors-blue-256)',
    tdPaddingMedium: '5px 10px',
    thPaddingMedium: '5px 10px',
    fontSizeMedium: 'min(max(0.85rem,  14px),1rem)',
    peers: {
      Pagination: {
        buttonBorder: 'unset',
        buttonColor: 'transparent',
        itemBorder: 'unset',
        itemBorderRadius: '5px',

        itemTextColorDisabled: 'var(--radar-base-colors-white-105)',
        itemBorderDisabled: '1px solid transparent',
        itemColor: 'var(--white-hsl)',
        itemColorActive: 'var(--radar-base-colors-white-105)',
        itemColorActiveHover: 'var(--radar-base-colors-white-105)',
        itemBorderActive: '1px solid transparent',
      },
      Radio: {
        color: 'var(--radar-base-colors-blue-100)',
      },
      Checkbox: {
        borderChecked: '1px solid transparent',
      },
      Dropdown: {
        color: 'var(--radar-base-colors-blue-300)',
        optionColorHover: 'var(--radar-base-colors-blue-400)',
      },
    },
  },
  InternalSelection: {
    border: 'unset',
    borderFocus: 'unset',
    borderActive: '1px solid var(--radar-base-colors-white-540)',
    borderHover: '1px solid var(--radar-base-colors-white-540)',
    borderRadius: '17px 17px 17px 17px',

    color: 'var(--radar-base-colors-white-540)',
    colorActive: 'var(--radar-base-colors-white-540)',

    textColor: 'var(--radar-base-colors-blue-200)',
    placeholderColor: 'var(--radar-base-colors-blue-200)',

    boxShadowActive: 'unset',
    arrowColor: 'var(--radar-base-colors-blue-150)',

    arrowSize: '24px',
    clearSize: '24px',
  },
  InternalSelectMenu: {
    color: 'var(--radar-base-colors-white-500)',
    optionColorPending: 'transparent',
    optionColorActive: 'var(--radar-base-colors-blue-300)',
    optionTextColor: 'var(--radar-base-colors-blue-100)',
    optionTextColorActive: 'var(--radar-base-colors-white-100)',
    borderRadius: '0 0',
    optionCheckColor: 'transparent',
    optionColorActivePending: 'var(--radar-base-colors-blue-300)',
    optionTextColorPressed: 'transparent',
  },
  DatePicker: {
    iconColor: 'var(--radar-base-colors-blue-240)',
    peers: {
      Input: {
        paddingMedium: '0',
      },
    },
  },
  Pagination: {
    buttonBorder: 'unset',
    buttonColor: 'transparent',
    buttonIconColor: 'var(--radar-base-colors-blue-150)',
    buttonIconColorHover: 'var(--radar-base-colors-blue-150)',
    buttonIconColorPressed: 'var(--radar-base-colors-blue-150)',
    buttonBorderHover: 'unset',

    itemBorder: 'unset',
    itemBorderRadius: '0px',

    itemTextColorDisabled: 'var(--radar-base-colors-blue-150)',

    itemBorderDisabled: '1px solid transparent',

    itemColor: 'var(--radar-base-colors-blue-151)',
    itemColorActive: 'var(--radar-base-colors-blue-153)',

    itemColorActiveHover: 'var(--radar-base-colors-blue-153)',

    itemTextColor: 'var(--radar-base-colors-blue-150)',
    itemTextColorActive: 'var(--radar-base-colors-blue-150)',

    itemBorderActive: '1px solid transparent',
    itemMarginMedium: '0px 1px 0px 0px',
    itemBorderHover: 'unset',
  },
  Upload: {
    itemBorderImageCard: 'unset',
    draggerBorder: 'unset',
    draggerBorderHover: 'unset',
  },
  Breadcrumb: {
    fontSize: '12px',
    itemColorHover: 'transparent',
    itemColorPressed: 'transparent',
    itemTextColorHover: 'inherit',
  },
  Popover: {
    arrowOffset: '25px',
    arrowHeight: '14px',
    spaceArrow: '20px',
  },
};
const DarkThemeCustom: GlobalThemeOverrides = {
  common: {
    fontFamily: 'v-sans',
    fontFamilyMono: 'Roboto',
    fontWeightStrong: '700',
    borderRadius: '17px',
    borderRadiusSmall: '5px',
    fontSize: '15px',
    fontSizeMini: '14px',
    fontSizeTiny: '11px',
    fontSizeSmall: '14px',
    fontSizeMedium: '12px',
    lineHeight: '1.6',

    bodyColor: '#073B50',

    baseColor: '#2080F014',
    primaryColor: '#0086C5FF',
    primaryColorHover: '#0073B5FF',
    primaryColorPressed: '#0086C5FF',
    fontSizeLarge: '14px',
    infoColor: '#2080F014',
    infoColorHover: '#4098FC36',
    infoColorPressed: '#1060C942',
    infoColorSuppl: '#4098FC54',
    successColor: '#8cb82b',
    successColorHover: '#8cb82b',
    successColorPressed: '#8cb82b',
    successColorSuppl: '#8cb82b',
    warningColor: '#FFE000FF',
    warningColorHover: '#FFBD00FF',
    warningColorPressed: '#FFA500FF',
    warningColorSuppl: '#FF9C00FF',
    errorColor: '#BF0A06',
    errorColorHover: '#BF0A06',
    errorColorPressed: '#BF0A06',
    errorColorSuppl: '#C31162FF',

    textColorBase: '#454545FF',
    textColor1: 'rgba(255, 255,255, 1)',
    textColor2: 'rgba(255, 255,255, 1)',

    textColorDisabled: 'rgba(0, 0, 0, 0.15)',

    placeholderColor: 'rgba(69, 69, 69, 0.4)',
    closeIconColor: 'rgba(212, 217, 219, 1)',
    invertedColor: 'rgba(3, 79, 154, 1)',
    hoverColor: 'rgba(243, 243, 245, 0.74)',

    tableHeaderColor: 'rgba(250, 250, 252, 0.02)',
    tableColorHover: 'rgba(0, 0, 100, 0.04)',

    inputColorDisabled: 'rgba(250, 250, 252, 0)',
    closeIconColorHover: 'rgba(254, 254, 254, 1)',
  },
  Breadcrumb: {
    itemColorHover: 'transparent',
    itemTextColor: 'var(--radar-base-colors-white-100)',
    fontSize: '12px',
  },
  Collapse: {
    dividerColor: 'rgb(0, 0, 0)',
    textColor: 'rgba(255, 255, 255,.8)',
    titleTextColor: 'var(--radar-base-colors-white-100)',
  },
  Tabs: {
    tabFontWeightActive: '700',
    tabGapMediumSegment: '0',
    tabTextColorActiveLine: 'red',
    barColor: 'transparent',
    tabTextColorActiveBar: 'var(--radar-base-colors-blue-150)',
    tabPaddingMediumBar: '9px 10px',
    tabGapMediumBar: '0',
    tabTextColorHoverBar: 'var(--n-tab-text-color)',
  },
  Input: {
    groupLabelTextColor: 'rgba(6, 53, 71, 1)',
    fontSizeTiny: '14px',
    fontSizeLarge: '14px',
    fontSizeSmall: '14px',
    fontSizeMedium: '14px',
    borderRadius: '0px',
    groupLabelColor: 'rgba(0, 0, 31, 1)',
    textColor: 'rgba(255, 255, 255, 1)',
    color: 'rgba(255, 255, 255, 1)',
    colorDisabled: 'transparent',
    textColorDisabled: 'rgba(255, 255, 255, .4)',
    groupLabelBorder: 'unset',

    border: '1px solid transparent',
    borderHover: '1px solid transparent',
    borderDisabled: '1px solid transparent',
    borderFocus: '1px solid transparent',
    borderError: '1px solid transparent',
    borderHoverError: '1px solid transparent',
    borderFocusError: '1px solid transparent',

    boxShadowFocus: 'unset',
    colorFocus: 'rgba(255, 255, 255, 0)',
    placeholderColor: 'var(--radar-base-colors-white-200)',

    paddingMedium: '0px',
    paddingLarge: '0px',
    paddingSmall: '0px',
    paddingTiny: '0px',
  },
  Menu: {
    fontSize: '13px',
    color: 'transparent',
    itemColorActive: 'transparent',
    itemColorActiveCollapsed: 'transparent',
    itemColorActiveHover: 'transparent',
    itemColorHover: 'transparent',
    itemIconColorChildActive: 'var(--radar-base-colors-white-100)',
    itemIconColorActiveHover: 'var(--radar-base-colors-white-100)',
    itemIconColorActive: 'var(--radar-base-colors-white-100)',

    itemTextColorHover: 'var(--radar-base-colors-white-100)',
    itemTextColorActive: 'var(--radar-base-colors-white-100)',
    itemTextColorChildActive: 'var(--radar-base-colors-white-100)',
    itemTextColorChildActiveHover: 'var(--radar-base-colors-white-100)',
    itemIconColorChildActiveHover: 'var(--radar-base-colors-white-100)',
    itemTextColorActiveHover: 'var(--radar-base-colors-white-100)',
    arrowColorChildActive: 'var(--radar-base-colors-white-100)',

    itemTextColorHorizontal: 'var(--radar-base-colors-white-100)',
    itemTextColorActiveHoverHorizontal: 'var(--radar-base-colors-white-100)',
    itemTextColorHoverHorizontal: 'var(--radar-base-colors-white-100)',
    itemTextColorActiveHorizontal: 'var(--radar-base-colors-white-100)',

    peers: {
      Dropdown: {
        color: 'var(--radar-base-colors-blue-400)',
        optionColorActive: 'var(--radar-base-colors-blue-300)',
        optionTextColorActive: 'var(--radar-base-colors-white-100)',
        optionColorHover: 'var(--radar-base-colors-blue-300)',
        borderRadius: '20px',
        optionTextColorChildActive: 'var(--radar-base-colors-white-100)',
      },
    },
  },
  Button: {
    heightSmall: '40px',
    heightLarge: '40px',
    heightMedium: '40px',
    paddingMedium: '12px 19px 12px 17px',
    heightTiny: '26px',

    borderRadiusMedium: '25px',
    borderRadiusSmall: '25px',
    borderRadiusTiny: '25px',
    borderRadiusLarge: '25px',
    color: '#36363603',
    textColor: 'var(--radar-base-colors-white-100)',

    textColorSuccess: 'var(--radar-base-colors-white-100)',
    textColorHoverSuccess: 'var(--radar-base-colors-white-100)',
    textColorPressedSuccess: 'var(--radar-base-colors-white-105)',
    textColorFocusSuccess: 'var(--radar-base-colors-white-100)',
    textColorDisabledSuccess: 'var(--radar-base-colors-white-105)',
    textColorTextSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextHoverSuccess: 'var(--radar-base-colors-white-100)',
    textColorTextPressedSuccess: 'var(--radar-base-colors-white-105)',
    textColorTextFocusSuccess: 'var(--radar-base-colors-white-105)',
    textColorTextDisabledSuccess: 'var(--radar-base-colors-white-100)',

    colorInfo: '#073B50FF',
    colorSuccess: '#8cb82b',
    colorHoverInfo: '#052B3AF0',
    textColorInfo: 'var(--radar-base-colors-white-100)',
    textColorHoverInfo: 'var(--radar-base-colors-white-100)',
    colorPressedInfo: '#173966FF',
    textColorPressedInfo: 'var(--radar-base-colors-white-100)',
    textColorFocusInfo: 'var(--radar-base-colors-white-100)',
    textColorDisabledInfo: 'var(--radar-base-colors-white-100)',
    colorFocusInfo: '#08182AFF',
    colorDisabledInfo: '#072B54FF',

    textColorPrimary: 'var(--radar-base-colors-white-100)',
    textColorHoverPrimary: 'var(--radar-base-colors-white-100)',
    textColorPressedPrimary: 'var(--radar-base-colors-white-100)',
    textColorFocusPrimary: 'var(--radar-base-colors-white-100)',

    textColorDisabledPrimary: '#F4F4F4FF',
    fontSizeMedium: '14px',
    textColorHover: 'inherit',
    textColorTextFocus: 'inherits',
    textColorTextHover: 'inherits',
    textColorTextPressed: 'inherits',
    textColorTextDisabled: 'inherits',
  },
  TreeSelect: {
    menuColor: 'var(--radar-base-colors-blue-250)',
    menuPadding: '0px 10px',
    menuBorderRadius: '0 0 5px 5px',
    peers: {
      Tree: {
        nodeColorHover: 'transparent',
        nodeTextColor: 'var(--radar-base-colors-white-100)',
        nodeBorderRadius: '0px',
        fontSize: '14px',
        peers: {
          Checkbox: {
            color: 'transparent',
            border: '1px solid var(--radar-base-colors-white-100)',
          },
        },
      },
      InternalSelection: {
        fontSizeMedium: '14px',
      },
    },
  },
  Tree: {
    nodeColorHover: 'transparent',
    nodeTextColor: 'var(--radar-base-colors-white-100)',
    nodeBorderRadius: '0px',
    fontSize: '12px',
  },
  Select: {
    menuBoxShadow: 'unset',
    peers: {
      InternalSelection: {
        caretColor: 'var(--radar-base-colors-white-100)',
        fontSizeMedium: '14px',
        borderHover: 'unset',
        borderActive: 'unset',
        borderFocus: 'unset',
      },
      InternalSelectMenu: {
        optionFontSizeMedium: '14px',
      },
    },
  },
  Checkbox: {
    color: 'transparent',
    colorChecked: '#8cb82b',
    colorDisabledChecked: '#8cb82b',
    checkMarkColorDisabledChecked: '#FEFEFEFF',
    checkMarkColor: '#FEFEFEFF',
    colorDisabled: 'transparent',
    textColor: 'var(--n-label-text-color)',
    textColorDisabled: 'var(--radar-base-colors-white-540)',
    colorTableHeader: 'transparent',
    borderRadius: '2px',
    sizeMedium: '14px',
    fontSizeMedium: '14px',
    borderChecked: '1px solid rgba(255, 255, 255, 0.24)',
    boxShadowFocus: 'unset',
    borderFocus: '1px solid rgba(255, 255, 255, 0.24)',
  },
  Radio: {
    color: 'transparent',
    textColor: 'var(--radar-base-colors-white-100)',
    fontSizeMedium: '14px',
    radioSizeMedium: '14px',
    dotColorActive: '#8cb82b',

    buttonBorderColorActive: '#153445FF',
    buttonBorderColor: '#000000FF',
    // buttonColor: '#000000FF',
    // buttonColorActive: '#000D12FF',
    // buttonTextColorActive: '#000000FF',
    // buttonTextColorHover: '#010101FF',
    boxShadow: 'inset 0 0 2px  #153445',
    boxShadowActive: 'inset 0 0 2px  #153445',
    boxShadowFocus: 'inset 0 0 2px  #153445',
    boxShadowHover: 'inset 0 0 2px  #153445',
  },
  Form: {
    labelFontSizeTopMedium: '14px',
    labelTextColor: 'rgba(255, 255, 255, .6)',
    feedbackFontSizeMedium: '10px',
    feedbackTextColor: 'rgba(191, 10, 6, 1)',
    peers: {
      Input: {
        placeholderColor: 'var(--radar-base-colors-white-105)',
        paddingMedium: '0',
        border: '1px solid transparent',
      },
    },
  },
  Alert: {
    contentTextColorError: 'rgba(255, 255, 255, 1)',
    contentTextColorSuccess: 'rgba(255, 255, 255, 1)',
    titleTextColorSuccess: 'rgba(255, 255, 255, 1)',
    colorSuccess: '#8cb82b',
    iconColor: '#fff',
    fontSize: '13px',

    padding: '2px 10px 2px 5px',
    borderRadius: '40px',
    iconSize: '10px',
    iconMargin: '0px',
    closeMargin: '10px 15px 10px 0px',
    closeSize: '12px',
    colorError: 'var(--radar-base-colors-red-100)',
    iconColorError: 'var(--radar-base-colors-white-100)',
  },
  Tooltip: {
    textColor: 'var(--radar-base-colors-white-100)',
    color: 'var(--radar-base-colors-blue-300)',
    padding: '0px 15px',
    peers: {
      Popover: {
        fontSize: '12px',
      },
    },
  },
  Dialog: {
    color: 'var(--radar-base-colors-white-200)',
    textColor: 'var(--radar-base-colors-blue-100)',
    titleTextColor: 'var(--radar-base-colors-blue-100)',
    borderRadius: '5px',
    actionSpace: '10px',
    closeMargin: '0px',
    titleFontSize: '16px',
    fontSize: '16px',
    peers: {
      Button: {
        textColorGhost: 'var(--radar-base-colors-black-200)',
        textColor: 'var(--radar-base-colors-white-100)',
        border: '1px solid var(--radar-base-colors-black-200)',

        textColorWarning: 'var(--radar-base-colors-black-200)',
        textColorHoverWarning: 'var(--radar-base-colors-black-200)',
        textColorFocusWarning: 'var(--radar-base-colors-black-200)',
        textColorDisabledWarning: 'var(--radar-base-colors-black-200)',
        textColorGhostWarning: 'var(--radar-base-colors-black-200)',

        textColorGhostDisabledInfo: 'var(--radar-base-colors-black-200)',
        borderInfo: 'var(--radar-base-colors-black-200)',
        textColorInfo: 'var(--radar-base-colors-black-200)',
        textColorGhostInfo: 'var(--radar-base-colors-black-200)',

        textColorGhostError: 'var(--radar-base-colors-black-200)',
        textColorDisabledError: 'var(--radar-base-colors-white-100)',
        textColorError: 'var(--radar-base-colors-white-100)',
        textColorFocusError: 'var(--radar-base-colors-white-100)',
        textColorHoverError: 'var(--radar-base-colors-white-100)',

        textColorGhostSuccess: 'var(--radar-base-colors-black-200)',
        textColorGhostPrimary: 'var(--radar-base-colors-black-200)',
      },
    },
  },
  Modal: {
    color: 'red',
    peers: {
      Card: { colorModal: 'var(--radar-base-colors-blue-50)' },
    },
  },
  Steps: {
    indicatorTextColorProcess: 'var(--radar-base-colors-white-100)',
    splitorColorProcess: 'var(--radar-base-colors-white-100)',

    splitorColorWait: 'var(--radar-base-colors-white-105)',
    indicatorColorWait: 'transparent',
    headerTextColorWait: 'var(--radar-base-colors-white-105)',
    descriptionTextColorWait: 'var(--radar-base-colors-white-105)',
    indicatorTextColorWait: 'var(--radar-base-colors-white-100)',

    descriptionTextColorFinish: '#8cb82b',
    headerTextColorFinish: '#8cb82b',
    indicatorBorderColorFinish: '#8cb82b',
    splitorColorFinish: '#8cb82b',
    indicatorTextColorFinish: '#8cb82b',
  },
  InternalSelectMenu: {
    color: 'var(--radar-base-colors-blue-200)',

    optionColorPending: 'var(--radar-base-colors-blue-200)',
    optionColorActive: 'var(--radar-base-colors-blue-300)',
    optionTextColor: 'var(--radar-base-colors-white-600)',
    optionTextColorActive: 'var(--radar-base-colors-white-100)',
    optionTextColorDisabled: 'var(--radar-base-colors-white-100)',
    borderRadius: '0 0  5px 5px',
    optionCheckColor: 'transparent',
    optionColorActivePending: 'var(--radar-base-colors-blue-300)',
    optionTextColorPressed: 'transparent',
  },
  InternalSelection: {
    textColor: 'var(--radar-base-colors-white-100)',
    textColorDisabled: 'var(--radar-base-colors-white-105)',

    border: 'unset',
    placeholderColor: 'var(--radar-base-colors-white-105)',
    // colorActive: 'transparent',
    boxShadowActive: 'unset',
    color: 'var(--radar-base-colors-blue-150)',
    borderRadius: '17px 17px 17px 17px',
    borderActive: '1px solid var(--radar-base-colors-blue-150)',
    arrowSize: '24px',
    clearSize: '24px',
    heightSmall: '24px',
  },
  Dropdown: {
    color: 'hsla(197, 83%, 12%, 0.95)',
  },
  DataTable: {
    thColor: 'transparent',

    thFontWeight: '400',

    thColorHover: 'transparent',
    tdColorHover: 'rgba(212, 217, 219, .05)',
    tdColor: 'transparent',
    tdTextColor: 'rgba(254, 254, 254, .6)',
    thTextColor: 'rgba(254, 254, 254, .4)',
    borderColor: '#0C4158',
    tdPaddingMedium: '5px 10px',
    thPaddingMedium: '5px 10px',
    fontSizeMedium: 'min(max(0.85rem,  14px),1rem)',

    peers: {
      Empty: {
        textColor: 'var(--radar-base-colors-white-105)',
      },
      Pagination: {
        buttonBorder: 'unset',
        buttonColor: 'transparent',
        buttonIconColor: 'var(--radar-base-colors-white-100)',
        buttonIconColorHover: 'var(--radar-base-colors-white-100)',
        buttonIconColorPressed: 'var(--radar-base-colors-white-100)',
        itemBorder: 'unset',
        itemBorderRadius: '5px',

        itemTextColorDisabled: 'var(--radar-base-colors-white-105)',
        itemBorderDisabled: '1px solid transparent',
        itemColor: 'var(--white-hsl)',
        itemColorActive: 'var(--radar-base-colors-white-105)',
        itemColorActiveHover: 'var(--radar-base-colors-white-105)',
        itemTextColor: 'var(--radar-base-colors-white-100)',
        itemTextColorActive: 'var(--radar-base-colors-white-100)',
        itemBorderActive: '1px solid transparent',
        peers: {
          Input: {
            // borderRadius: 'unset',
          },
        },
      },
      Checkbox: {
        sizeMedium: '14px',
      },
    },
  },
  Layout: {
    siderColor: 'var(--radar-base-colors-blue-250)',
    headerColor: 'var(--radar-base-colors-blue-50)',
  },
  Pagination: {
    buttonBorder: 'unset',
    buttonColor: 'transparent',
    buttonIconColor: 'var(--radar-base-colors-white-100)',
    buttonIconColorHover: 'var(--radar-base-colors-white-100)',
    buttonIconColorPressed: 'var(--radar-base-colors-white-100)',
    buttonBorderHover: 'unset',
    itemBorder: 'unset',
    itemBorderRadius: '0px',

    itemTextColorDisabled: 'var(--radar-base-colors-white-105)',
    itemBorderDisabled: '1px solid transparent',
    itemColor: 'var(--white-hsl)',
    itemColorActive: 'var(--radar-base-colors-white-530)',
    itemColorActiveHover: 'var(--radar-base-colors-white-105)',
    itemTextColor: 'var(--radar-base-colors-white-100)',
    itemTextColorActive: 'var(--radar-base-colors-white-100)',
    itemBorderActive: '1px solid transparent',
    itemMarginMedium: '0px 1px 0px 0px',
    itemBorderHover: 'unset',
  },
  Popselect: {
    menuBoxShadow: '1px 1px 0 var(--radar-base-colors-blue-200), -1px -1px 0 var(--radar-base-colors-blue-200)',
    peers: {
      Popover: {
        color: 'var(--radar-base-colors-blue-250)',
        borderRadius: '20px',
      },
      InternalSelectMenu: {
        color: 'red',
        optionCheckColor: 'transparent',
        optionColorActive: 'transparent',
        optionTextColorActive: 'var(--radar-base-colors-white-500)',
        optionColorActivePending: 'transparent',

        borderRadius: 'unset',
      },
    },
  },
  DatePicker: {
    panelColor: 'var(--radar-base-colors-blue-150)',
    panelTextColor: 'var(--radar-base-colors-white-100)',
    calendarTitleTextColor: 'var(--radar-base-colors-white-100)',
    calendarDividerColor: 'var(--radar-base-colors-blue-200)',
    arrowColor: 'var(--radar-base-colors-white-100)',
    iconColor: 'var(--radar-base-colors-white-100)',
    itemTextColor: 'var(--radar-base-colors-white-100)',
    itemColorIncluded: 'rgba(0, 134, 197, .2)',
    itemTextColorDisabled: 'var(--radar-base-colors-white-530)',
    peers: {
      Input: {
        placeholderColor: 'var(--radar-base-colors-white-105)',
        paddingMedium: '0',
        border: '1px solid transparent',
      },
    },
  },
  Upload: {
    itemBorderImageCard: '1px solid var(--radar-base-colors-blue-200)',
    draggerBorder: 'unset',
    draggerBorderHover: 'unset',
  },
  Popover: {
    arrowOffset: '25px',
    arrowHeight: '14px',
    spaceArrow: '20px',
  },
};

export { ThemeCustom, DarkThemeCustom };
