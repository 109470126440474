import { IconLocal } from '@/Dashboard';
import { useDialog } from 'naive-ui';
import { h } from 'vue';
import SuccessView from '@/core/components/SuccessView.vue';

const dialog = useDialog();

export const useDialogSuccess = ({ message, iconType }: { message: string; iconType: string }) => {
  return dialog.create({
    class: 'dark:rd-bg-base-960',
    showIcon: false,
    action: () => h('span'),
    content: () =>
      h(
        SuccessView,
        { cssClass: 'dark:rd-text-base-100 dark:rd-bg-base-960 rd-text-center' },
        {
          default: () => h('h4', { class: 'rd-text-center rd-text-xl rd-px-16' }, message),
          icon: () => h(IconLocal, { type: iconType, size: '90', class: 'rd-mx-auto' }),
        },
      ),
    onAfterLeave: () => dialog.destroyAll(),
  });
};
