<script lang="ts">
import { Provider, Viewer } from '@/Dashboard';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RadarData',
  components: {
    Provider,
    Viewer,
  },
});
</script>

<template>
  <Provider>
    <Viewer />
  </Provider>
</template>

<style lang="scss">
.n-base-selection .n-base-selection__border {
  @apply rd-border dark:rd-border-0 rd-border-solid rd-border-base-140 rd-border-opacity-40 dark:rd-border-b-base-110 dark:rd-border-opacity-60;
}
.pagination .n-base-selection .n-base-selection__border {
  @apply rd-border-base-bg rd-border-[1px] dark:rd-border-base-110 dark:rd-border-opacity-60;
}
.n-input {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: unset;
  &.n-input--disabled .n-input__border,
  .n-input__border {
    @apply rd-border-b-base-950 rd-border-opacity-40 dark:rd-border-b-base-110 dark:rd-border-opacity-60;
  }
}

/*
Helper css
 */
.n-menu.n-menu--horizontal {
  .n-submenu .n-menu-item-content,
  .n-menu-item {
    height: unset;

    &.n-menu-item--selected {
      background: var(--active-blue);
    }
  }

  .n-menu-item-content {
    grid-template-areas: 'icon' 'content' 'arrrow';
    grid-template-columns: auto;
    place-content: center;
    @apply rd-pt-4;
    @apply rd-pb-2;

    .n-menu-item-content__icon {
      margin: auto !important;
    }
  }
}

.map-wrapper-map-container {
  min-height: var(--map-min-height);
  padding: 0.5rem;
  background: transparent;
  background-size: cover;
  border-radius: 0 0 var(--base-bd-rd) var(--base-bd-rd);
  position: relative;
}

.map-wrapper-card_modal {
  position: relative;
  color: var(--base-text-color);

  .n-card-header {
    // display: none;
    padding: 0;
  }

  .n-card-header__close {
    --close-size: 14px;
    position: absolute;
    right: -29px;
    top: 0;
    padding: 0px;
    display: grid;
    place-content: center;
    z-index: 1000;
    color: var(--white);
    .n-base-icon {
      background: var(--active-blue);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.map-wrapper-close-inside {
  .map-wrapper-card_modal .n-card-header__close {
    position: absolute;
    right: 5px;
    top: 5px;

    background: var(--active-blue);
    border-radius: 50%;
  }
}

.n-card > .n-card__content {
  padding: unset;
}

// Global Collapse
.n-collapse {
  --collapse-padding: 7px 10px;

  border-radius: var(--base-card-rd);

  // padding: 0 0 0.5rem 0;

  .map-wrapper-collapse-item {
    --title-text-color: var(--base-text-color);
    --title-font-size: var(--base-fz);

    padding: var(--collapse-padding);
    line-height: var(--base-flh);
    color: var(--base-text-color);
    margin: 0 0;
    border: unset;
    @apply rd-mb-px rd-rounded-lg;
    //@apply first-of-type:rd-rounded-tr-lg first-of-type:rd-rounded-tl-lg last-of-type:rd-rounded-br-lg last-of-type:rd-rounded-bl-lg

    &:nth-of-type(5) {
      border-radius: 0 0 var(--base-card-rd) var(--base-card-rd);
    }
    &.map-wrapper-icon-container {
      border-radius: var(--base-card-rd) var(--base-card-rd);
      .n-collapse-item__content-inner div {
        @apply rd-rounded-b-lg;
      }
    }

    .n-collapse-item__header {
      padding: 0;

      &.n-collapse-item__header--active {
        --title-font-weight: bold;
        color: var(--base-text-color);
      }
    }

    &.n-collapse-item--active {
      padding: unset;

      .n-collapse-item__header--active {
        padding: 7px 10px;
        & {
          @apply dark:rd-bg-base-950 rd-bg-base-140 rd-bg-opacity-40 rd-rounded-t-lg;
        }
      }
    }
    &.sub-collapse .n-collapse-item__header--active {
      @apply dark:rd-bg-base-950 rd-bg-transparent;
    }

    &.n-collapse-item--active .n-collapse-item__header.n-collapse-item__header--active .n-collapse-item-arrow {
      transform: rotate(180deg);
      color: var(--active-blue);
      margin-left: unset;
      margin-right: unset;
    }

    .n-collapse-item__content-wrapper .n-collapse-item__content-inner {
      // margin-top: 0.5rem;
      padding: unset;
      background: unset;
    }
  }
}
[data-theme='rd-dark'] {
  .n-collapse {
    .map-wrapper-collapse-item {
      --white-600: var(--radar-base-colors-blue-50);

      border: unset;
    }
  }
}
//Counter styles for the dropdown
.map-wrapper-info-container {
  font-size: var(--base-icon-fz);
  font-weight: normal;
  display: inline-grid;
  grid-template-columns: 20px 1fr;
  margin-left: var(--base-icon-ml);
  align-items: center;
  cursor: pointer;
}
</style>
