<script lang="ts" setup>
/**
 *
 * Information text component.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { FormKitFrameworkContext } from '@formkit/core';
import { IconLocal } from '@/Dashboard';

interface Props {
  context: FormKitFrameworkContext;
}

const props = defineProps<Props>();
</script>

<template>
  <div v-if="props.context.attrs.show" class="rd-flex rd-text-xs rd-opacity-40">
    <div class="rd-flex rd-max-w-md rd-w-full rd-ml-auto rd-tracking-tighter">
      <IconLocal class="rd-mr-1" size="15" />
      <div>
        <slot>
          {{ $t(props.context.attrs.translation) }}
        </slot>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
