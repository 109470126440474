<script lang="ts" setup>
/**
 *
 * Commune menu overview
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { computed } from 'vue';
import { useCommuneStore } from '@/stores/CommuneStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { parse } from 'date-fns';
import { utcToCommuneDateTimeZone } from '../composables';

/*----------  Translations  ----------*/
const { t, locale } = useI18n();

/*----------  Store  ----------*/
const communeStore = useCommuneStore();
const { GET_SELECTED_COMMUNE } = storeToRefs(communeStore);
const pipeToDate = (s: string) => {
  return parse(s, "yyyy-MM-dd'T'HH:mm:ss", new Date()).toLocaleDateString(locale.value, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

const getSelectedCommuneDate = computed(() => {
  if (GET_SELECTED_COMMUNE.value?.uuid) {
    return `${pipeToDate(utcToCommuneDateTimeZone({ date: GET_SELECTED_COMMUNE.value.RPeriod.start }))} - ${pipeToDate(utcToCommuneDateTimeZone({ date: GET_SELECTED_COMMUNE.value.RPeriod.end }))}`;
  }
  return t('commune.filter.byuuid.placeholder');
});
</script>

<template>
  <div class="rd-text-sm rd-hidden md:rd-block rd-text-base-110">
    {{ $t('commune.menu.selectedOverview') }}
    <span data-test="commune-report-date">
      {{ getSelectedCommuneDate }}
    </span>
  </div>
</template>
<style scoped></style>
