<script lang="ts" setup>
import { IconLocal } from '@/Dashboard';
import { useCommuneStore } from '@/stores/CommuneStore';
import { UseFilterCommuneTimeFrame } from '@/communes/composables';
/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
const { adminStart, adminEnd } = UseFilterCommuneTimeFrame();

const communeStore = useCommuneStore();

const HandleClearTimeFrame = (label: 'START' | 'END') => {
  label === 'START' ? (adminStart.value = null) : null;
  label === 'END' ? (adminEnd.value = null) : null;

  HandleCommuneSearch();
};

const HandleCommuneSearch = async () => {
  try {
    await communeStore.ACTION_GET_ALL_COMMUNES_ADMIN({
      adminStart: adminStart.value ? adminStart.value : '',
      adminEnd: adminEnd.value ? adminEnd.value : '',
    });
  } catch (error) {
    console.log('error');
  }
};

const clear = () => {
  HandleClearTimeFrame('END');
  HandleClearTimeFrame('START');
};
</script>

<template>
  <section class="rd-grid rd-grid-cols-2 dark:rd-bg-base-990 rd-p-5 rd-max-w-sm">
    <header class="rd-col-span-2 rd-text-xl rd-mb-4">
      <IconLocal type="FilterIcon" class="rd-mr-3" />
      <span>{{ $t('global.actions.filter') }}</span>
    </header>
    <header class="rd-col-span-2 rd-text-small-xs rd-mb-2">
      <span>{{ $t('commune.table.header.form.filter.helper.label') }}</span>
    </header>
    <n-form-item :label="$t('commune.table.header.form.filter.dateStart')">
      <n-date-picker
        v-model:formatted-value="adminStart"
        type="date"
        clearable
        value-format="yyyy-MM-dd'T'HH:mm:ss"
        :actions="['clear']"
        required
        @clear="HandleClearTimeFrame('START')"
      />
    </n-form-item>
    <n-form-item :label="$t('commune.table.header.form.filter.dateEnd')">
      <n-date-picker
        v-model:formatted-value="adminEnd"
        type="date"
        clearable
        value-format="yyyy-MM-dd'T'HH:mm:ss"
        :actions="['clear']"
        required
        @clear="HandleClearTimeFrame('END')"
      />
    </n-form-item>
    <section class="rd-text-small-xs rd-col-span-2 rd-opacity-60">
      <IconLocal />
      {{ $t('actions.filter.helper') }}
    </section>
    <footer class="rd-col-span-2 rd-flex rd-justify-between rd-items-baseline rd-mt-4">
      <n-button text size="tiny" class="rd-self-end min-h-[26px]" @click="clear">
        <span
          class="dark:rd-bg-base-100 rd-bg-base-bg rd-rounded-full rd-text-base-100 dark:rd-text-base-990 rd-p-1 rd-pl-2 rd-flex rd-items-center"
        >
          <span class="rd-mx-2">
            {{ $t('filter.actions.FILTERRESET') }}
          </span>
          <IconLocal type="CloseIcon" class="rd-bg-base-920 rd-rounded-full rd-ml-1 rd-text-base-100" size="18" />
        </span>
      </n-button>

      <n-button type="success" @click="HandleCommuneSearch">{{ $t('actions.filter.save') }}</n-button>
    </footer>
  </section>
</template>
<style scoped></style>
