import { useOidcStore } from 'vue3-oidc';
import { DeepRequired } from '@/stores/GlobalStore';
import {
  V1UserParameters,
  UserDto,
  UserUpdateDto,
  UserCreationDto,
  V1UserUuidParameters,
  UserProfileDto,
  UserProfileUpdateDto,
} from '@/typings/api/index';
import { Page } from '@/typings/general/Page';
import axios, { AxiosResponse } from 'axios';

export type UserDetails = Required<UserDto>;
export type CoordinatorParameters = { communeUuid: string; UserUpdateDto: UserUpdateDto; assignCoordinator?: boolean };
export type UpdateUserParams = { data: UserCreationDto; uuid: string };

export type UserValidationParam = { type: 'USERNAME' | 'EMAIL'; value: string };

export abstract class UsersService {
  private static ROAD_REPORT_INSTANCE = axios.create({
    baseURL: `${import.meta.env.VITE_ROAD_REPORT}/api/v1`,
  });

  /*----------  Export Handlers  ----------*/
  /**
   *
   * @param { CommuneUuid  } 'commune uuid '
   * @returns Users list
   */

  static async FetchAllUsers(q: V1UserParameters): Promise<AxiosResponse<Page<UserDetails>>> {
    const urlParams = new URLSearchParams(q as Record<string, string>);
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/user?${urlParams.toString()}`);
  }

  /**
   *
   * @param { communeUuid, userUuid ,assignCoordinator } 'Set user as coordinator to specific commune'
   * @returns user detail
   */
  static async SetUserToCommune({ communeUuid, UserUpdateDto, assignCoordinator = false }: CoordinatorParameters) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(
      `commune/${communeUuid}/users?${assignCoordinator ? 'assignCoordinator=true' : ''}`,
      UserUpdateDto,
    );
  }

  static async GetUserProfile(): Promise<AxiosResponse<DeepRequired<UserProfileDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/user-profile`);
  }
  static async SyncUserProfile({ uuid }: { uuid: string }): Promise<AxiosResponse<DeepRequired<UserProfileDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/user-profile/sync?uuid=${uuid}`);
  }

  static async UpdateUserProfile({ data }: { data: UserProfileUpdateDto }): Promise<AxiosResponse<DeepRequired<UserProfileDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/user-profile`, data);
  }

  static async GetUserByUuid({ uuid }: { uuid: string }): Promise<AxiosResponse<UserDetails>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/user/${uuid}`);
  }

  static async CreateNewUser(data: UserCreationDto): Promise<AxiosResponse<UserDetails>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.post(`/user`, data);
  }

  static async UpdateUser({ data, uuid }: UpdateUserParams): Promise<AxiosResponse<UserDetails>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/user/${uuid}`, data);
  }

  static async DeleteUser({ uuid }: V1UserUuidParameters): Promise<AxiosResponse<UserDetails>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.delete(`/user/${uuid}`);
  }

  static async DeleteCurrentUser(): Promise<AxiosResponse<unknown>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.delete(`/user-profile`);
  }

  static async RegisterUser({ data }: { data: UserCreationDto }) {
    return await this.ROAD_REPORT_INSTANCE.post(`/registration/user`, data);
  }

  static async VerifyEmail({ token, isDelete }: { token: string; isDelete: boolean }) {
    return await this.ROAD_REPORT_INSTANCE.post(`/registration/user/verify?token=${token}${isDelete ? '&delete=true' : ''}`);
  }

  static async SendEmailVerification(email: string) {
    return await this.ROAD_REPORT_INSTANCE.post(`/registration/user/verify-resend?email=${email}`);
  }
  static async SendPasswordResetEmail(email: string) {
    return await this.ROAD_REPORT_INSTANCE.post(`/registration/user/reset-password?email=${email}`);
  }
  static async ResetUserPassword({ password, token }: { password: string; token: string }) {
    return await this.ROAD_REPORT_INSTANCE.post(`/registration/user/reset-password?token=${token}&password=${password}`);
  }
  static async CheckUsernameOrEmail({ type, value }: UserValidationParam) {
    return await this.ROAD_REPORT_INSTANCE.get(`/registration/user/available?type=${type}&value=${value}`);
  }

  private static tokenInterceptor() {
    const { state } = useOidcStore();
    this.ROAD_REPORT_INSTANCE.interceptors.request.use(
      (config) => {
        if (state.value.token) {
          config.headers.Authorization = `Bearer ${state.value.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}
