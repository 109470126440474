import { NIcon } from 'naive-ui';
import { Component, h, type VNodeChild } from 'vue';

const RenderIcon =
  (icon: string, size = 16, extraClasses = 'menu_icon md:rd-mb-2') =>
  (): VNodeChild =>
    h(
      NIcon,
      {
        innerHTML: icon,
        style: 'margin-right:unset',
        size,
        class: extraClasses,
      },
      {},
    );
const RenderRegularIcon = (icon: Component, options: Record<string, unknown>) => (): VNodeChild =>
  h(NIcon, null, { default: () => h(icon as any, { ...options }) });

export const isDefinedGeneric = (x: number | undefined | null): x is number => true;

export { RenderIcon, RenderRegularIcon };
export default RenderIcon;
