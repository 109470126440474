<script lang="ts" setup>
import { computed, toRefs } from 'vue';

/**
 *
 * Render for the table header for the commune
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface Props {
  active: boolean;
  exist: boolean;
  reportingValid: boolean;
  label: string;
  communeUuid: string;
}

const prop = withDefaults(defineProps<Props>(), {
  active: false,
  exist: false,
  reportingValid: false,
  label: '',
  communeUuid: '',
});
const { active, label, reportingValid } = toRefs(prop);

const statusClass = computed((): string => {
  const status =
    active.value && reportingValid.value
      ? 'rd-bg-yellow-300'
      : active.value && !reportingValid.value
        ? 'rd-bg-yellow-500'
        : 'rd-bg-red-700';
  return status;
});
</script>

<template>
  <n-ellipsis class="rd-max-h-60">
    <span class="rd-w-2 rd-h-2 rd-rounded-full rd-inline-block rd-mr-2" :class="statusClass"></span>
    <span>{{ label }}</span>
  </n-ellipsis>
</template>
<style scoped></style>
