<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
const { locale } = useI18n();
const $TheFooterHtml = ref('loading');

const fetchFooter = async () => {
  const res = await fetch(`/base/footer_${locale.value}.html`);
  $TheFooterHtml.value = await res.text();
};

/*----------  Navigation related  ----------*/
watch(
  locale,
  () => {
    fetchFooter();
  },
  { immediate: true },
);
</script>

<template>
  <div class="rd-bg-base-961" v-html="$TheFooterHtml"></div>
</template>
<style scoped lang="scss">
::v-deep(.container) {
  @apply rd-container rd-max-w-7xl rd-mx-auto rd-text-lg  rd-px-2 rd-py-12 md:rd-px-0 dark:rd-text-base-900 rd-text-base-100;

  .row {
    @apply rd-flex rd-flex-col-reverse rd-p-4 md:rd-grid  md:rd-grid-cols-2 rd-gap-4;
  }

  .order-1 {
    @apply rd-flex rd-flex-col xl:rd-justify-center md:rd-order-1 md:rd-justify-end;
  }

  .order-2 {
    @apply rd-text-sm rd-opacity-80;
    h2 {
      @apply rd-mb-1 rd-mt-6 rd-text-lg md:rd-text-xl md:rd-mb-6;
    }

    p {
      margin-bottom: 1rem;
      @apply md:rd-text-sm md:rd-max-w-md rd-text-base-110 rd-font-normal;
    }
  }

  .copyright {
    opacity: 0.8;

    a {
      @apply rd-text-base-910;
    }
  }

  li {
    list-style: none;
  }

  .nav {
    @apply rd-mt-auto rd-mb-5 md:rd-mb-auto md:rd-mt-0 rd-font-light rd-opacity-80;
  }

  .navbar-nav,
  .social-icons {
    @apply rd-flex rd-flex-row rd-justify-center rd-flex-wrap rd-mb-auto md:rd-mb-0 md:rd-justify-end;
  }

  .navbar-nav {
    @apply rd-flex-col rd-text-left md:rd-text-right;
  }

  li {
    @apply rd-inline-block rd-my-1;
  }

  [class*='icon-'] {
    @apply rd-block rd-w-7 rd-h-7 rd-bg-white rd-rounded-full rd-text-base-961 rd-grid rd-place-content-center rd-ml-5;
  }

  .icon-facebook {
    margin-left: unset;
  }
}
</style>
