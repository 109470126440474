<script lang="ts" setup>
/**
 *
 * Component Description:User date picker
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { IconLocal } from '@/Dashboard';
import { FormKitFrameworkContext } from '@formkit/core';
import { watch, ref } from 'vue';
import TableEmpty from './TableEmpty.vue';

interface Props {
  context: FormKitFrameworkContext;
}

const props = defineProps<Props>();
const selectedValue = ref(props.context.attrs.selected);
const defaultvalue = ref(props.context._value);

watch(selectedValue, (newValue) => {
  props.context.node.input(newValue);
});
</script>

<template>
  <n-select
    v-model:value="selectedValue"
    :options="props.context.attrs.options"
    class="rd-w-full"
    :label-field="props.context.attrs.labelField"
    :value-field="props.context.attrs.valueField"
    clearable
    :placeholder="props.context.attrs.placeHolder"
    :filterable="props.context.attrs.filterable"
    :multiple="props.context.attrs.multiple"
    :default-value="defaultvalue"
    :disabled="props.context.attrs.selectDisabled"
  >
    <template #arrow>
      <IconLocal type="DropDownIcon" size="18" class="dark:rd-text-base-110" />
    </template>
    <template #empty>
      <TableEmpty translation-label="global.commune.country.empty" />
    </template>
  </n-select>
</template>
<style scoped></style>
